import React, { useState, Fragment, useCallback } from "react";
import { Header, Form, List, Segment } from "semantic-ui-react";
import { callUserService, useFetchUserService } from "../../hooks/useFetch";

export const UserMetadata = ({ user, fetchUserInfo }) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const { data: keysData, loading: keyLoading } = useFetchUserService(`/user-metadata-keys`);

  const options = keysData?.map((m) => ({ value: m.name, text: m.description })) || [];

  const saveMetadata = useCallback(
    async (values) => {
      await callUserService(`/users/add-user-metadata`, {
        method: "POST",
        body: JSON.stringify(values),
      });
      fetchUserInfo();
    },
    [fetchUserInfo]
  );

  const deleteMetadata = useCallback(
    async ({ userId, metaId }) => {
      await callUserService(`/users/${userId}/delete-user-metadata/${metaId}`, {
        method: "DELETE",
      });
      fetchUserInfo();
    },
    [fetchUserInfo]
  );

  const onKeyChange = (event) => setKey(event.target.value);
  const onValueChanged = (event) => setValue(event.target.value);
  const onSubmit = () => {
    const userId = user?.userId;

    saveMetadata({ userId, value, key });
  };
  const onDeleteMetadata = (id) => {
    deleteMetadata({ userId: user.userId, metaId: id });
  };

  return (
    <>
      <Header as="h3" dividing>
        User metadata
      </Header>
      {user.metadata.map((m) => (
        <Fragment key={m.key}>
          {m.values.length > 0 && (
            <Segment key={m.key} compact>
              <Header as="h5">{m.key}</Header>
              <List bulleted style={{ minWidth: "300px" }}>
                {m.values.map((n) => (
                  <List.Item key={n.info.id} style={{ minWidth: "300px" }}>
                    {n.value}
                    <List.Content floated="right">
                      <List.Icon
                        style={{ cursor: "pointer" }}
                        color="red"
                        onClick={() => onDeleteMetadata(n.info.id)}
                        size="small"
                        name="remove"
                      ></List.Icon>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          )}
        </Fragment>
      ))}
      <Header as="h4">Add metadata</Header>

      <Form onSubmit={onSubmit}>
        <Form.Field value={key} onChange={onKeyChange} loading={keyLoading} control="select" label="Key">
          <option defaultValue hidden>
            Select key
          </option>
          {options.map((m) => (
            <option key={m.value} value={m.value}>
              {m.text}
            </option>
          ))}
        </Form.Field>
        <Form.Field
          onChange={onValueChanged}
          control="input"
          value={value}
          placeholder="..."
          type="text"
          label="Value"
        ></Form.Field>
        <Form.Button color="blue" type="submit">
          Submit
        </Form.Button>
      </Form>
    </>
  );
};

export default UserMetadata;
