import React from "react";
import Search from "components/Search";
import Layout from "components/Layout";
// import SearchIp from 'components/Search/SearchIp'
import { Divider } from "semantic-ui-react";

const SearchUsers = () => {
  return (
    <Layout headerText="Search Users" renderAccountNumber={false} headerSpacing={true}>
      {/*<Menu borderless compact>*/}
      {/*    <Menu.Item onClick={() => setUserSearch(true)} active={userSearch}>*/}
      {/*        User data*/}
      {/*    </Menu.Item>*/}
      {/*    /!* <Menu.Item onClick={() => setUserSearch(false)} active={!userSearch}>*/}
      {/*        Ip number*/}
      {/*    </Menu.Item> *!/*/}
      {/*</Menu>*/}
      <Divider hidden className="small" />
      <Search />
      {/* {!userSearch && <SearchIp />} */}
    </Layout>
  );
};

export default SearchUsers;
