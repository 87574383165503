import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Header, Table, Pagination, Icon, Loader } from "semantic-ui-react";
import { callUserService } from "../../hooks/useFetch";
import { serialize } from "../../utils/query";
import { useSelectedUserContext } from "../../context/SelectedUserContext";

const Changelog = ({ category }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedUserInformation } = useSelectedUserContext();

  const getHistory = useCallback(async (filter) => {
    setLoading(true);
    const query = serialize(filter);
    const { response } = await callUserService(`/user-log?${query}`);
    setData(response);
    setLoading(false);
  }, []);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const filter = { userId: selectedUserInformation.userId, page, limit: 10 };
    if (category) {
      filter.category = category;
    }
    getHistory({ ...filter });
  }, [category, selectedUserInformation.userId, page, getHistory]);

  const historyLog = data?.content || [];
  const pageInfo = data?.pageInfo || {};

  const changePage = (_, data) => {
    const { activePage } = data;
    setPage(activePage);
  };

  if (loading) return <Loader active inline="centered" />;

  return (
    <>
      <Header as="h3" dividing>
        Changelog
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>When</Table.HeaderCell>
            <Table.HeaderCell>What</Table.HeaderCell>
            <Table.HeaderCell>Who</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {historyLog.length > 0 ? (
            historyLog.map((m, i) => (
              <Table.Row key={i}>
                <Table.Cell>{format(new Date(m.created), "yyyy-MM-dd hh:mm")}</Table.Cell>
                <Table.Cell>{m.text}</Table.Cell>
                <Table.Cell>{m.createdBy}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>Empty log</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        floated="right"
        defaultActivePage={page}
        totalPages={pageInfo?.totalPages || 1}
        onPageChange={changePage}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        prevItem={{
          content: <Icon name="angle left" />,
          icon: true,
          disabled: !pageInfo?.hasPrevious,
        }}
        nextItem={{
          content: <Icon name="angle right" />,
          icon: true,
          disabled: !pageInfo?.hasNext,
        }}
      />
    </>
  );
};

export default Changelog;
