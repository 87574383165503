import React from "react";
import { Accordion, Segment } from "semantic-ui-react";
import PackageAccordionContent from "./PackageAccordionContent";
import PackageAccordionTitle from "./PackageAccordionTitle";
import UserPackageAccordionTitle from "./UserPackageAccordionTitle";

const PackageAccordion = ({ userPackage = false, authPackage, hideDelete = false, userId, ...rest }) => {
  const dropdownIcon = authPackage.packageContent.length ? "dropdown" : "minus";

  const _hideDelete = authPackage.authorityPackageKind === "SYSTEM" || hideDelete;

  const panels = [
    {
      key: authPackage.info.id,
      title: {
        icon: dropdownIcon,
        content: userPackage ? (
          <UserPackageAccordionTitle
            authPackage={authPackage}
            userId={userId}
            loading={rest.loading}
            handleDisconnect={rest.handleDisconnect}
          />
        ) : (
          <PackageAccordionTitle authPackage={authPackage} hideDelete={_hideDelete} userId={userId} {...rest} />
        ),
      },
      content: {
        content: <PackageAccordionContent authPackage={authPackage} />,
      },
    },
  ];
  return (
    <Segment>
      <Accordion panels={panels} exclusive={false} fluid />
    </Segment>
  );
};

export default PackageAccordion;
