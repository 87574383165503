import { useCallback, useEffect } from "react";
import Cookies from "js-cookie";
import { callAuthServer } from "../../hooks/useFetch";
import { toLogin } from "ne-ui/components/AuthProvider/AuthProvider";

const Logout = () => {
  const logout = useCallback(async () => {
    await callAuthServer("/logout", {
      method: "POST",
      body: {
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        refresh_token: Cookies.get("refresh_token"),
      },
    });
    Cookies.remove("oauth_nonce");
    Cookies.remove("_jsessionid");
    Cookies.remove("id_token");
    Cookies.remove("access_token");
    Cookies.remove("_jsessionid");
    Cookies.remove("refresh_token");

    await toLogin();
  }, []);

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export default Logout;
