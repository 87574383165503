import React, { useState } from "react";
import { FindWondeSchool } from "./components/FindWondeSchool";
import { ConfirmSelectionModal } from "./components/ConfirmSelectionModal";

export const SelectWondeSchool = ({ onSelectComplete, onCancel, schoolId }) => {
  const [selectedSchool, setSelectedSchool] = useState(null);

  return (
    <>
      {!selectedSchool ? (
        <FindWondeSchool onSelect={setSelectedSchool} onBack={() => onCancel()} />
      ) : (
        <ConfirmSelectionModal
          school={selectedSchool}
          onComplete={onSelectComplete}
          onCancel={() => setSelectedSchool(null)}
          schoolId={schoolId}
        />
      )}
    </>
  );
};
