import React, { useState, useEffect, useCallback } from "react";
import { Form, Pagination, Divider, Container, Header, Icon, Grid, Dropdown } from "semantic-ui-react";
import { limitOptions } from "utils/limitOptions";
import ChildrenTable from "components/ChildrenTable";
import { callUserService } from "../../../hooks/useFetch";

const UserChildren = ({ user, groupId, setGroupId, readOnly }) => {
  const ownerId = user?.info?.id;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectGroup, setSelectGroup] = useState(null);
  const [groupsData, setGroupsData] = useState(null);
  const [groupLoading, setGroupLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(true);

  const fetchGroupStudentsExcluded = useCallback(async () => {
    setLoadingSelect(true);
    const { response } = await callUserService(`/groups/${ownerId}/exclude-student-groups`);
    setSelectGroup({ groupsExcludeStudentGroups: response });
    setLoadingSelect(false);
  }, [ownerId]);

  useEffect(() => {
    fetchGroupStudentsExcluded();
  }, [fetchGroupStudentsExcluded]);

  const getMembers = useCallback(async ({ groupId, page, limit }) => {
    setGroupLoading(true);
    const { response } = await callUserService(
      `/groups/${groupId}/members?clientId=backoffice-app&page=${page}&limit=${limit}`
    );
    setGroupsData({ group: response });
    setGroupLoading(false);
  }, []);

  useEffect(() => {
    if (!groupId) return;

    getMembers({ groupId, page, limit });
  }, [groupId, getMembers, page, limit]);

  const onTypeSelected = (groupId) => {
    setGroupId(groupId);
  };

  const options =
    selectGroup?.groupsExcludeStudentGroups.map((m) => ({
      key: m.groupType,
      text: m.groupType,
      value: m.info.id,
    })) || [];

  const changePage = (event, data) => {
    const { activePage } = data;
    setPage(activePage);
  };

  const onLimitChange = (_, { value }) => {
    setPage(1);
    setLimit(value);
  };

  const groups = groupsData?.group?.content.filter((f) => f.members.length);
  const hasNext = groupsData?.group?.pageInfo?.hasNext;
  const hasPrev = groupsData?.group?.pageInfo?.hasPrevious;
  const totalPages = groupsData?.group?.pageInfo?.totalPages;

  return (
    <Container>
      <Header as="h3" dividing>
        Manage users in group
      </Header>
      <Form>
        <Form.Select
          label="Select group"
          width="4"
          value={groupId}
          loading={loadingSelect}
          options={options}
          placeholder="Select group"
          onChange={(_, event) => onTypeSelected(event.value)}
        ></Form.Select>
      </Form>

      <Divider hidden className="small" />

      {groups?.length > 0 && (
        <Grid>
          <Grid.Row relaxed>
            <Grid.Column floated="left" width="7">
              <Pagination
                defaultActivePage={page}
                totalPages={totalPages}
                onPageChange={changePage}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name="angle left" />,
                  icon: true,
                  disabled: !hasPrev,
                }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: !hasNext,
                }}
              />
            </Grid.Column>
            <Grid.Column width="5"></Grid.Column>
            <Grid.Column textAlign="right" width="3">
              <Dropdown compact selection options={limitOptions} onChange={onLimitChange} value={limit} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <ChildrenTable
        groupId={groupId}
        readOnly={readOnly}
        refetchGroups={() => getMembers({ groupId, page, limit })}
        loading={groupLoading}
        groups={groups ? groups[0] : []}
      />
    </Container>
  );
};

export default UserChildren;
