import React, { useEffect, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import ManagePackage from "components/ManagePackage";
import PackageAccordion from "components/PackageAccordion";
import { useFetchUserService, callUserService } from "../../../hooks/useFetch";
import { useMessage } from "../../../ne-ui";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";

export const SchoolPackages = () => {
  const { schoolInformation } = useSelectedSchoolContext();
  const [showModal, setShowModal] = useState(false);
  const [updatingPackages, setUpdatingPackages] = useState(false);
  // fetch all packages.
  const { data: packages, loading } = useFetchUserService(`/authority-package`);

  // fetch school packages
  const {
    data: schoolPackages,
    setFetchData,
    loading: loadingAuthorityPackages,
  } = useFetchUserService(`/school/${schoolInformation.id}/packages`);
  const dispatchMessage = useMessage();

  useEffect(() => {
    setFetchData(true);
  }, [schoolInformation.id, setFetchData]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const addPackageToSchool = async (selected) => {
    try {
      setUpdatingPackages(true);
      await callUserService(`/school-package`, {
        method: "POST",
        body: JSON.stringify({
          schoolId: schoolInformation.id,
          packageId: selected,
        }),
      });
      toggleModal();
      setFetchData(true);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
        id: schoolInformation.id,
      });
    } finally {
      setUpdatingPackages(false);
    }
  };

  const handleDisconnect = async (packageId) => {
    try {
      setUpdatingPackages(true);
      await callUserService(`/school-package/${packageId}`, {
        method: "DELETE",
      });
      setFetchData(true);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
        id: schoolInformation.id,
      });
    } finally {
      setUpdatingPackages(false);
    }
  };

  return (
    <>
      <Header as="h3" dividing>
        School Packages
      </Header>
      <Button content="Add package" onClick={() => setShowModal(true)} loading={loading} />
      {showModal && !loading ? (
        <ManagePackage
          resetModal={toggleModal}
          packages={packages?.map((x) => ({
            id: x.info.id,
            name: x.name,
          }))}
          loading={loading}
          updatingPackages={updatingPackages}
          connectPackage={addPackageToSchool}
        />
      ) : null}
      {schoolPackages?.map((x) => {
        return (
          <PackageAccordion
            userPackage={true}
            handleDisconnect={handleDisconnect}
            loading={loading || loadingAuthorityPackages}
            key={x.id}
            userId={schoolInformation.id}
            //
            authPackage={{
              name: x.package.name,
              id: x.id,
              packageContent: x.package.authorityPackages.map((y) => ({
                name: y.authority.name,
                id: y.authority.id,
              })),
              info: {
                id: x.id,
              },
            }}
          />
        );
      })}
    </>
  );
};
