import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../../utils";
import { callUserService } from "../../../../hooks/useFetch";
import {
  Button,
  Confirm,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Pagination,
  Segment,
} from "semantic-ui-react";
import ImportUsers from "../../../../components/User/Import/ImportUsers";
import { limitOptions } from "../../../../utils/limitOptions";
import GroupAccordion from "../../../../components/GroupAccordion";

export const EducationGroups = ({ schoolId }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [partOfGroupNameState, setpartOfGroupName] = useState("");
  const partOfGroupName = useDebounce(partOfGroupNameState, 200);
  const [limit, setLimit] = useState(50);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [removingGroups, setRemovingGroups] = useState(false);

  const fetchStudentGroups = useCallback(
    async (ownerId) => {
      setLoading(true);
      const { response } = await callUserService(
        `/classroom/${ownerId}/ownedgroups?page=${page}&limit=${limit}&partOfGroupName=${partOfGroupName}&withParentOwnerValidation=false`
      );
      setLoading(false);
      setData({
        groups: {
          ...response,
          content: response.content.map((a) => ({
            ...a,
            info: { id: a.id },
            membersCount: a.members.length,
          })),
        },
      });
    },
    [page, limit, partOfGroupName]
  );

  useEffect(() => {
    fetchStudentGroups(schoolId);
  }, [fetchStudentGroups, schoolId]);

  const deleteGroups = useCallback(
    async (schoolId) => {
      setRemovingGroups(true);
      await callUserService(`/classroom/delete-classrooms/${schoolId}`, { method: "DELETE" });
      await fetchStudentGroups(schoolId);
      setConfirmOpen(false);
      setRemovingGroups(false);
    },
    [fetchStudentGroups]
  );

  const changePage = (event, data) => {
    setPage(data.activePage);
  };

  const onPartOfSearchGroup = (event, { value }) => {
    if (page) setPage(1);
    setpartOfGroupName(value);
  };

  const onLimitChange = (event, { value }) => {
    setLimit(value);
  };

  const pageInfo = data?.groups?.pageInfo;

  return (
    <>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => deleteGroups(schoolId)}
        confirmButton="Delete"
        content={
          <>
            <Dimmer active={removingGroups} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>Are you sure?</Segment>
          </>
        }
      />
      <Header as="h3" dividing>
        Manage education classrooms
      </Header>
      <div>
        <Button basic active onClick={() => setOpen(true)}>
          Import
        </Button>
        <Button floated="right" negative onClick={() => setConfirmOpen(true)}>
          Delete all
        </Button>
      </div>
      <ImportUsers
        open={open}
        setOpen={setOpen}
        refetch={() => fetchStudentGroups(schoolId)}
        type="students"
        userId={schoolId}
      />
      <Divider hidden className="medium" />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="left" width="6">
            <Form>
              <Form.Input
                fluid
                label="Search classroom"
                loading={loading}
                value={partOfGroupNameState}
                onChange={onPartOfSearchGroup}
                type="text"
                placeholder="..."
              ></Form.Input>
            </Form>
          </Grid.Column>
          <Grid.Column width="7"></Grid.Column>
          <Grid.Column textAlign="right" width="3">
            <Form>
              <Form.Select
                label="Page size"
                compact
                selection
                options={limitOptions}
                onChange={onLimitChange}
                value={limit}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {loading ? (
        <Loader active></Loader>
      ) : (
        <>
          {data?.groups?.content &&
            data?.groups?.content.map((group) => (
              <GroupAccordion
                key={group.info.id}
                group={group}
                refetch={() => fetchStudentGroups(schoolId)}
                schoolId={schoolId}
              />
            ))}
        </>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="left" width="6">
            {pageInfo?.totalElements ? <> {pageInfo?.totalElements} groups total </> : null}
          </Grid.Column>

          <Grid.Column textAlign="right" width="10">
            {!loading && pageInfo?.totalElements > limit && (
              <Pagination
                defaultActivePage={page}
                totalPages={pageInfo?.totalPages || 1}
                onPageChange={changePage}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name="angle left" />,
                  icon: true,
                  disabled: !pageInfo?.hasPrevious,
                }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: !pageInfo?.hasNext,
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
