import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const SubNav = ({ breadcrumbsList = [], columnWidth = 8, rightColumn, Link }) => (
  <div className="sub-nav">
    <Grid columns="equal" container verticalAlign="middle" className="no-margin">
      <Grid.Column floated="left" width={columnWidth}>
        {!!breadcrumbsList.length && <Breadcrumbs breadcrumbsList={breadcrumbsList} Link={Link} />}
      </Grid.Column>
      {rightColumn}
    </Grid>
  </div>
);

SubNav.propTypes = {
  breadcrumbsList: PropTypes.arrayOf(PropTypes.object),
  columnWidth: PropTypes.number,
  RightColumn: PropTypes.elementType,
};

export default SubNav;
