import React from "react";
import { Header, List, Segment } from "semantic-ui-react";
import UserParentGroupList from "./UserParentGroupList";
import { useSelectedUserContext } from "context/SelectedUserContext";

const UserSidebar = () => {
  const { selectedUserInformation, selectedUserLoginMethods, selectedUserSchools } = useSelectedUserContext();

  if (!Object.keys(selectedUserInformation).length || !selectedUserInformation.userId) return null;
  // const { groups, userId } = selectedUserInformation || {};
  return (
    <Segment>
      {selectedUserSchools.length ? <UserParentGroupList /> : null}
      {selectedUserLoginMethods?.length > 0 && (
        <>
          <Header>Authentication</Header>
          <List bulleted>
            {selectedUserLoginMethods.map((m) => (
              <List.Item key={m}>
                <List.Content>{m}</List.Content>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </Segment>
  );
};

export default UserSidebar;
