import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

const ErrorMessage = ({ children, className, dismissTime = 10000, header, id, message, onDismiss }) => {
  useEffect(() => {
    const clearError = setTimeout(() => {
      onDismiss({
        payload: id,
      });
    }, dismissTime);

    return () => clearTimeout(clearError);
  }, [dismissTime, id, onDismiss]);

  return message ? (
    <div style={{ marginTop: "5px" }}>
      <Message error negative className={className} onDismiss={() => onDismiss({ payload: id })} floating>
        <div style={{ paddingRight: "0.5em" }}>
          <Message.Header>{header}</Message.Header>
          <Message.Content>{message}</Message.Content>
          {children ? children : null}
        </div>
      </Message>
    </div>
  ) : null;
};

ErrorMessage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dismissTime: PropTypes.number,
  header: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
};

export default ErrorMessage;
