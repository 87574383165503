import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Grid, Header, Image } from "semantic-ui-react";

const TopNav = () => {
  const { user, isAuthenticated, hasPermissionBO } = useAuthContext();
  const name =
    user?.nameDetails?.firstName && user?.nameDetails?.lastName
      ? `${user.nameDetails.firstName} ${user.nameDetails.lastName}`
      : null;

  if (!user) return null;

  return (
    <div id="top-navigation" className="fill-UIDark20">
      <Grid verticalAlign="middle" container columns="equal" className="no-margin">
        <Grid.Column width="10">
          <div id="top-navigation-header">
            <Image src="/assets/propello-logo.svg" size="small" style={{ marginRight: "20px" }} />
            <Header className="condensed topnav-header" as={Link} to="/">
              Backoffice
            </Header>
          </div>
        </Grid.Column>
        <Grid.Column width="6" floated="right" textAlign="right">
          <Dropdown button basic className="icon circular app-button" icon="user" pointing direction="left">
            <Dropdown.Menu>
              <Dropdown.Header>{name ? name : user.email}</Dropdown.Header>
              {isAuthenticated && hasPermissionBO && (
                <>
                  <Dropdown.Item text="Dashboard" as={Link} to="/" />
                  <Dropdown.Item text="Search" as={Link} to="/search" />
                  <Dropdown.Item text="Authorities" as={Link} to="/authorities" />
                  <Dropdown.Item text="School codes" as={Link} to="/schoolcodes" />
                  <Dropdown.Item text="Role & Permissions" as={Link} to="/rolepermissions" />
                </>
              )}
              <Dropdown.Divider />
              <Dropdown.Item text="Logout" as="a" href="/logout" />
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default TopNav;
