import {
  NAME,
  AUTHORITY_KEY,
  ACTIVE,
  DESCRIPTION,
  PARENT_ID,
  UPDATE_FORM,
  RESET_FORM,
  AUTHORITY_KIND,
} from "./actions";

export const authorityReducer = (state, action) => {
  switch (action.type) {
    case NAME:
      return {
        ...state,
        name: action.payload,
      };
    case DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case ACTIVE:
      return {
        ...state,
        active: action.payload === "false",
      };
    case AUTHORITY_KEY: {
      return { ...state, authorityKey: action.payload };
    }
    case PARENT_ID:
      return {
        ...state,
        parentId: action.payload || -1,
      };
    case AUTHORITY_KIND:
      return {
        ...state,
        authorityKind: action.payload || null,
      };
    case UPDATE_FORM:
      return {
        ...state,
        name: action.payload.name,
        parentId: action.payload.parentId,
        id: action.payload.id,
        description: action.payload.description,
        authorityKind: action.payload.authorityKind,
      };
    case RESET_FORM:
      return {
        name: "",
        active: true,
        description: "",
        parentId: -1,
        id: "",
        authorityKind: "",
      };
    default:
      throw new Error("Could not update state");
  }
};
