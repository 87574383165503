import React, { useState } from "react";
import { Button, List, Confirm, Segment, Loader, Dimmer } from "semantic-ui-react";
import { callInternalApiGateway } from "hooks/useFetch";
import { useMessage } from "ne-ui";
import MDEditor, { commands } from "@uiw/react-md-editor";

const NewsList = ({ id, description, newsPosition, refetchNews }) => {
  const [isEditingRole, setIsEditingRole] = useState(false);
  const dispatchMessage = useMessage();
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updatedNewsDescription, setUpdatedNewsDescription] = useState(description);

  const saveEditedRole = async () => {
    try {
      if (!updatedNewsDescription) throw new Error("Please do not leave news description empty");
      if (description === updatedNewsDescription) throw new Error("Please make some updates in the news description");
      await callInternalApiGateway(
        `/news/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ description: updatedNewsDescription }),
        },
        true
      );

      setIsEditingRole(false);
      refetchNews();
      dispatchMessage({
        title: "News updated",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      console.error(error);
    }
  };

  const handleCancelButton = () => {
    setIsEditingRole(false);
    setUpdatedNewsDescription(description);
  };

  const handleEditButton = () => setIsEditingRole(true);
  const onRequestDeleteNews = () => {
    setConfirmOpen(true);
  };
  const generateButtons = () => {
    if (isEditingRole) {
      return [
        {
          icon: "save",
          basic: true,
          size: "tiny",
          floated: "right",
          onClick: saveEditedRole,
        },
        {
          icon: "cancel",
          basic: true,
          size: "tiny",
          color: "red",
          floated: "right",
          onClick: handleCancelButton,
        },
      ];
    } else {
      return [
        {
          icon: "pencil",
          basic: true,
          size: "tiny",
          floated: "right",
          disabled: false,
          onClick: handleEditButton,
        },
        {
          basic: true,
          icon: "trash",
          size: "tiny",
          color: "red",
          floated: "right",
          onClick: onRequestDeleteNews,
          disabled: false || isEditingRole,
        },
      ];
    }
  };

  const deleteNews = async () => {
    try {
      setLoading(true);
      const { data } = await callInternalApiGateway(`/news/${id}`, {
        method: "DELETE",
      });
      if (data?.statusCode === 500) {
        throw new Error(data.message);
      }
      refetchNews();
      dispatchMessage({
        title: "News deleted",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      setConfirmOpen(false);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deleteNews}
        confirmButton="Delete"
        content={
          <>
            <Dimmer active={loading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>{`Are you sure do you want to delete News number ${newsPosition}? `}</Segment>
          </>
        }
      />
      <List.Item style={{ paddingRight: "1em", display: "flex", justifyContent: "space-between", width: "100%" }}>
        <List.Content floated="left" verticalAlign="middle">
          {isEditingRole ? (
            <>
              <List.Header content={`Edit News number ${newsPosition} description:`} />
              <MDEditor
                commands={[commands.link]}
                value={updatedNewsDescription}
                onChange={setUpdatedNewsDescription}
                width={"100%"}
                visiableDragbar={false}
              />

              <MDEditor.Markdown source={description} style={{ whiteSpace: "pre-wrap" }} />
            </>
          ) : (
            <>
              <List.Header content={`News number ${newsPosition} description:`} />
              <div style={{ border: "1px solid grey", padding: "8px" }}>
                <MDEditor.Markdown source={description} style={{ whiteSpace: "pre-wrap" }} />
              </div>
            </>
          )}
        </List.Content>

        <div>
          <Button.Group>
            {generateButtons().map((button, i) => (
              <Button key={i} {...button} />
            ))}
          </Button.Group>
        </div>
      </List.Item>
    </>
  );
};

export default NewsList;
