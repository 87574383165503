import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Form, Grid, Segment, Header, Confirm, Dimmer, Loader } from "semantic-ui-react";
import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";
import { NAME, ACTIVE, DESCRIPTION, UPDATE_FORM, RESET_FORM, AUTHORITY_KIND } from "../actions";
import { authorityReducer } from "../reducers";
import { useMessage } from "../../../ne-ui";
import AuthorityAccordion from "../AuthorityAccordion";
import { callUserService } from "../../../hooks/useFetch";

const EditAuthority = ({ authorities, getAuthorities }) => {
  const dispatchMessage = useMessage();
  const [formData, setFormData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [updatingAuthority, setUpdatingAuthority] = useState(false);
  const { hasSystemPermission } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const deleteAuthority = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const { response } = await callUserService(`/authorities/${id}`, { method: "DELETE" });
        if (response.status === 400) {
          throw new Error(response.message);
        }
        await getAuthorities();
        dispatchMessage({
          title: "Authority deleted",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        setConfirmOpen(false);
        dispatch({ type: RESET_FORM });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, getAuthorities]
  );

  const updateAuthority = useCallback(
    async (values) => {
      try {
        setUpdatingAuthority(true);
        await callUserService(`/authorities/${values.id}`, {
          method: "PUT",
          body: JSON.stringify(values),
        });
        await getAuthorities();
        dispatchMessage({
          title: "Authority updated",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: "authority_update",
        });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
          id: "authority_error",
        });
      } finally {
        setUpdatingAuthority(false);
      }
    },
    [dispatchMessage, getAuthorities]
  );

  const [state, dispatch] = useReducer(authorityReducer, {
    name: "",
    active: true,
    description: "",
    parentId: -1,
    id: "",
    authorityType: -1,
    allowedContexts: "",
    authorityKind: "",
  });

  useEffect(() => {
    if (formData?.name) {
      const { authorityKey, ...rest } = formData;
      dispatch({
        type: UPDATE_FORM,
        payload: rest,
      });
    }
  }, [formData]);

  const onEditButtonClicked = (node) => () => {
    setFormData({
      name: node.name,
      id: node.info.id,
      authorityKey: node.authorityKey,
      description: node.description || "",
      authorityKind: node.authorityKind || null,
    });
  };

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  const handleSubmit = () => {
    updateAuthority({ ...state, authorityKey: formData.authorityKey });
  };

  const onConfirmDeleteAuthority = () => {
    if (state.id) {
      deleteAuthority(state.id);
    }
  };

  const { name, active, description, authorityKind } = state;

  const selectOptions = [
    /* We should query all of the available keys instead */
    { key: "PRODUCT_OBJECT_AUTHORITY", value: "PRODUCT_OBJECT_AUTHORITY", text: "PRODUCT OBJECT AUTHORITY" },
    { key: "SYSTEM_OBJECT_AUTHORITY", value: "SYSTEM_OBJECT_AUTHORITY", text: "SYSTEM OBJECT AUTHORITY" },
    { key: "None", value: "None", text: "None" },
  ];

  return (
    <Segment style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={onConfirmDeleteAuthority}
        confirmButton="Delete"
        content={
          <>
            <Dimmer active={loading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>Are you sure?</Segment>
          </>
        }
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width="10">
            {authorities?.length > 0 &&
              authorities?.map((authority) => (
                <AuthorityAccordion
                  key={authority.info.id}
                  node={authority}
                  onEditButtonClicked={onEditButtonClicked}
                  hasSystemPermission={hasSystemPermission}
                />
              ))}
          </Grid.Column>
          <Grid.Column width="6">
            {formData && (
              <Form
                style={{ position: "sticky", top: "20px" }}
                onSubmit={handleSubmit}
                loading={loading || updatingAuthority}
              >
                <Header>{formData?.authorityKey}</Header>
                <Form.Input label="Name" placeholder="Name" value={name} onChange={handleFieldsUpdate(NAME)} />
                <Form.Input
                  label="Description"
                  placeholder="Description"
                  value={description}
                  onChange={handleFieldsUpdate(DESCRIPTION)}
                />
                <Form.Select
                  placeholder="Authority Kind"
                  label={"Authority Kind"}
                  value={authorityKind}
                  options={selectOptions}
                  onChange={handleFieldsUpdate(AUTHORITY_KIND)}
                />
                <Form.Checkbox
                  label="Active"
                  value={active.toString()}
                  checked={active}
                  onChange={handleFieldsUpdate(ACTIVE)}
                />
                <Form.Group>
                  <Form.Button type="button" onClick={() => setFormData(null)} content="Cancel" />
                  <Form.Button
                    type="button"
                    onClick={() => setConfirmOpen(true)}
                    content="Delete"
                    secondary
                    disabled={loading}
                  />

                  <Form.Button type="submit" primary content="Submit" disabled={updatingAuthority} />
                </Form.Group>
              </Form>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default EditAuthority;
