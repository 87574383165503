import React, { useReducer, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { useMessage } from "../../../ne-ui";
import { useHistory } from "react-router-dom";
import { callUserService, useFetchUserService } from "../../../hooks/useFetch";
import { StandardSelectionComponent } from "../../../components/StandardSelection/StandardSelectionComponent";
import { standardSelectionMap } from "../../../components/StandardSelection/standardSelectionMap";

const initialState = {
  name: "",
  schoolCode: "",
  schoolTypeId: null,
  stateId: process.env.REACT_APP_REGION === "us" ? "TX" : "UK",
  standardSelection: {
    ngss: true,
    state: true,
    cck12: false,
    txsc682024: false,
  },
};

const createUserReducer = (state, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

export const ModalSchoolCreate = ({ closeModal }) => {
  const [error, setError] = useState(null);
  const dispatchMessage = useMessage();
  const history = useHistory();
  const [state, dispatch] = useReducer(createUserReducer, initialState);
  const [creatingUser, setCreatingUser] = useState(false);
  const { data, loading: loadingSchoolTypes, errorFetch } = useFetchUserService("/school/types");
  const { data: states, loading: loadingStates } = useFetchUserService("/state");

  const createSchool = async (schoolData) => {
    setCreatingUser(true);
    try {
      const { response, errors } = await callUserService("/school", {
        method: "POST",
        body: JSON.stringify(schoolData),
      });
      if (errors) {
        throw new Error(errors);
      }
      dispatchMessage({
        title: "School created",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      history.push(`/school/${response.id}`);
    } catch (error) {
      dispatchMessage({
        title: `${error.message}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      closeModal();
    } finally {
      setCreatingUser(false);
    }
  };

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;

    dispatch({
      type: field,
      payload: value,
    });
  };

  const handleSubmit = () => {
    if (!state.schoolTypeId) return setError({ ...error, schoolTypeId: true });
    if (!state.name) return setError({ ...error, name: true });

    const schoolData = { ...state, standardSelection: standardSelectionMap(state.standardSelection) };
    createSchool(schoolData);
  };

  const { name, schoolCode, schoolTypeId, stateId, standardSelection } = state;
  return (
    <Modal onClose={() => closeModal()} open={true}>
      <Modal.Header>Create School</Modal.Header>
      <Modal.Content>
        <Form loading={loadingSchoolTypes || creatingUser || loadingStates} id="create-school" onSubmit={handleSubmit}>
          <Form.Input
            required={true}
            value={name}
            onChange={handleFieldsUpdate("name")}
            label="School name"
            placeholder="Name"
          />
          <Form.Select
            label="School Type"
            required={true}
            error={error?.schoolTypeId ? { content: " No School type selected" } : null}
            placeholder="Select"
            options={data?.map((x) => ({
              key: x.id,
              value: x.id,
              text: x.name,
            }))}
            value={schoolTypeId}
            onChange={handleFieldsUpdate("schoolTypeId")}
          />

          <Form.Select
            label="School State"
            placeholder="Select"
            options={states?.map((x) => ({
              key: x.stateId,
              value: x.stateId,
              text: x.name,
            }))}
            value={stateId}
            onChange={handleFieldsUpdate("stateId")}
          />

          {process.env.REACT_APP_REGION === "us" && (
            <Form.Group>
              <StandardSelectionComponent
                value={standardSelection}
                onChange={(_, data) => {
                  const { label, checked } = data;
                  dispatch({
                    type: "standardSelection",
                    payload: {
                      ...standardSelection,
                      [label.toLowerCase()]: checked,
                    },
                  });
                }}
              />
            </Form.Group>
          )}

          <Form.Input
            value={schoolCode}
            onChange={handleFieldsUpdate("schoolCode")}
            label="School code"
            placeholder="School code"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} basic primary>
          Cancel
        </Button>
        <Button
          disabled={loadingSchoolTypes || !!errorFetch || creatingUser}
          loading={loadingSchoolTypes || creatingUser}
          primary
          type="submit"
          form="create-school"
        >
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
