import { useSelectedUserContext } from "context/SelectedUserContext";
import { callInternalApiGateway } from "hooks/useFetch";
import { useMessage } from "ne-ui";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Header, Button, Segment, Divider, List, Checkbox, Loader } from "semantic-ui-react";

export const LPSystem = () => {
  const dispatchMessage = useMessage();
  const { selectedUserInformation, selectedSchool } = useSelectedUserContext();
  const [userSelectedDefault, setUserSelectedDefault] = useState([]);
  const [userSelectedLPSystem, setUserSelectedLPSystem] = useState([]);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allLPSystem, setAllLPSystem] = useState([]);

  const getLPSystem = useCallback(async () => {
    setLoading(true);
    try {
      const allLPSystemResponse = await callInternalApiGateway("/learning-path/get-all-by-type?type=system");
      const userAllLPSystems = await callInternalApiGateway(
        `/learning-path/get-all-by-type?userId=${selectedUserInformation.userId}&type=system&${
          selectedSchool.id ? `schoolIds=${selectedSchool.id}` : ""
        }`
      );

      setUserSelectedLPSystem(userAllLPSystems.data.learningPaths.map((x) => x.id));
      setUserSelectedDefault(userAllLPSystems.data.learningPaths);
      setAllLPSystem(allLPSystemResponse.data.learningPaths);
      setLoading(false);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      console.error(error);
      setLoading(false);
    }
  }, [selectedSchool.id, selectedUserInformation.userId]);

  const getUpdateLearningPathIds = () => {
    const toAdd = userSelectedLPSystem.filter((item) => !userSelectedDefault.map((x) => x.id).includes(item));
    const toDelete = userSelectedDefault.filter((item) => !userSelectedLPSystem.includes(item?.id)).map((x) => x.id);

    return { toAdd, toDelete };
  };
  const saveEditedUserLPSystem = async () => {
    const itemsToUpdate = getUpdateLearningPathIds();

    try {
      setLoading(true);
      setIsEditingUser(false);
      if (!itemsToUpdate.toAdd.length && !itemsToUpdate.toDelete.length) {
        setLoading(false);
        return;
      }
      const { response } = await callInternalApiGateway(`/learning-path/assign-lp-system-to-user`, {
        method: "POST",
        body: JSON.stringify({
          userId: `${selectedUserInformation.userId}`,
          ...itemsToUpdate,
        }),
      });
      if ([500, 404, 401].includes(response?.statusCode)) {
        throw Error(response.message);
      }

      await getLPSystem();
      dispatchMessage({
        title: "User updated",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    } catch (error) {
      setLoading(false);
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      console.error(error);
    }
  };

  const handleCancelButton = () => {
    setIsEditingUser(false);
    setUserSelectedLPSystem(userSelectedDefault.map((x) => x.id));
  };

  const handleCheckboxChange = (lpId, checked) => {
    if (checked) {
      setUserSelectedLPSystem([...userSelectedLPSystem, lpId]);
    } else {
      setUserSelectedLPSystem(userSelectedLPSystem.filter((id) => id !== lpId));
    }
  };

  const activeEditUser = () => setIsEditingUser(true);

  useEffect(() => {
    getLPSystem();
  }, [getLPSystem]);

  return (
    <div style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: "20px", marginBottom: "20px" }}>
      <Header as="h3" dividing>
        Learning Path Systems
      </Header>

      <Segment>
        <>
          {!loading ? (
            <>
              <List relaxed className="striped">
                {allLPSystem.map((lp) => {
                  return (
                    <List.Item key={lp.id} style={{ paddingRight: "1em" }}>
                      <List.Content floated="left" verticalAlign="middle">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Checkbox
                            checked={userSelectedLPSystem.includes(lp.id)}
                            disabled={!isEditingUser}
                            style={{ marginRight: "0.5em" }}
                            onChange={(e, data) => {
                              handleCheckboxChange(lp.id, data.checked);
                            }}
                          />
                          <List.Header content={lp.title} />
                        </div>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
              <Divider />
              <div style={{ display: "flex", justifyContent: "end" }}>
                {isEditingUser ? (
                  <Button.Group>
                    <Button icon="save" basic size="tiny" onClick={saveEditedUserLPSystem} />
                    <Button icon="cancel" basic color="red" size="tiny" onClick={handleCancelButton} />
                  </Button.Group>
                ) : (
                  <Button basic size="tiny" onClick={activeEditUser}>
                    Edit User LP Systems
                  </Button>
                )}
              </div>
            </>
          ) : (
            <Loader active inline="centered" />
          )}
        </>
      </Segment>
    </div>
  );
};
