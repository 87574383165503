import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Confirm, Loader, Dimmer, Header, Segment, Message, Icon } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { callInternalApiGateway } from "../../hooks/useFetch";

// status = 0 => active
// status = -1 => inactive

const UserStatus = ({ user: { status, userId, stripeId, externalSyncId }, fetchUserInfo }) => {
  const history = useHistory();
  const dispatchMessage = useMessage();
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [confirmDeactivation, setConfirmDeactivation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [data] = useState(null);
  const getSubscriptions = useCallback(() => {}, []);
  // const [getSubscriptions, { data }] = useLazyQuery(STRIPE_SUBSCRIPTIONS)
  const [readOnly, setReadOnly] = useState(() => (externalSyncId ? true : false));

  const deleteUser = useCallback(async () => {
    setDeletingUser(true);
    try {
      await callInternalApiGateway(`/users/${userId}`, { method: "DELETE" });
      dispatchMessage({
        title: `Successfully removed user ${userId}`,
        level: "success",
        showIcon: "check",
        type: "toast",
        id: userId,
      });

      history.push("/");
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
    } finally {
      setDeletingUser(false);
    }
  }, [dispatchMessage, history, userId]);

  const setUserStatus = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await callInternalApiGateway(`/users/set-user-status`, {
          method: "PUT",
          body: JSON.stringify(values),
        });
        await fetchUserInfo();
        dispatchMessage({
          title: "Success",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: userId,
        });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, fetchUserInfo, userId]
  );

  useEffect(() => {
    if (!stripeId) return;

    getSubscriptions({ variables: { stripeId } });
  }, [stripeId, getSubscriptions]);

  const updateUserStatus = () => {
    const userStatus = status === 0 ? -1 : 0;

    setUserStatus({ id: userId, status: userStatus });

    setConfirmDeactivation(false);
  };

  const onConfirmDeleteUser = () => {
    deleteUser({ variables: { id: userId } });
  };

  const onConfirmDeactivateUser = () => {
    updateUserStatus();
  };

  const isActivated = status === 0;
  const hasStripeSubscription = data?.stripeSubscriptions?.length > 0 || false;

  return (
    <>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={onConfirmDeleteUser}
        content={
          <>
            <Dimmer active={deletingUser} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>Are you sure?</Segment>
          </>
        }
      />
      <Confirm
        open={confirmDeactivation}
        onCancel={() => setConfirmDeactivation(false)}
        onConfirm={onConfirmDeactivateUser}
        content={
          <>
            <Dimmer active={loading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>Are you sure?</Segment>
          </>
        }
      />
      <Segment color="grey" inverted>
        <Header>{isActivated ? "Deactivate user account" : "Activate user account"}</Header>

        <p>Any sub-account will also be {isActivated ? "deactivated" : "activated"}</p>
        <Button
          size="tiny"
          disabled={loading}
          loading={loading}
          color={isActivated ? "red" : "primary"}
          onClick={() => setConfirmDeactivation(true)}
        >
          {isActivated ? "Deactivate user" : "Activate user"}
        </Button>
      </Segment>
      <Segment color="grey" inverted>
        <Header>Delete user</Header>
        {hasStripeSubscription && (
          <Message negative>Cant delete a user account with active stripe subscription</Message>
        )}
        <Button
          size="tiny"
          disabled={hasStripeSubscription || deletingUser || readOnly}
          loading={deletingUser}
          color="red"
          onClick={() => setConfirmOpen(true)}
        >
          Delete user account
        </Button>
        <Button icon onClick={() => setReadOnly(!readOnly)}>
          <Icon name={readOnly ? "lock" : "unlock"} />
        </Button>
      </Segment>
    </>
  );
};

export default UserStatus;
