import TopNav from "components/TopNav";
import React from "react";
import { Container } from "semantic-ui-react";

const AuthError = () => {
  return (
    <div>
      <TopNav />
      <Container textAlign="center" style={{ fontSize: "2em", marginTop: "8%" }}>
        This user does not have authorization to use this service
      </Container>
    </div>
  );
};

export default AuthError;
