import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";

const PackageAccordionTitle = ({ authPackage, hideDelete, userId, ...rest }) => {
  const history = useHistory();

  const onDelete = (e, id) => {
    e.stopPropagation();
    rest.setOpen(true);
    rest.setSelectedId(id);
  };

  const updatePackage = (e, id) => {
    e.stopPropagation();

    history.push(`/authorities/create/${id}`);
  };

  return (
    <>
      <Grid stretched verticalAlign="middle" columns="equal" className="accordion-title-grid" style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width="8">
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width="1" className="no-padding">
                  {/* dummy */}
                </Grid.Column>
                <Grid.Column width="15">
                  <div className="group-name">{authPackage.name}</div>
                  <div className={`ui text normal tiny ${authPackage.packageContent.length > 0 ? "muted" : "red"}`}>
                    {authPackage.packageContent.length ? `${authPackage.packageContent.length} authorities` : null}
                  </div>
                  {rest?.validUntil ? (
                    <div className="ui text normal tiny">
                      Valid until <b>{rest.validUntil}</b>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width="8">
            <Grid textAlign="right" verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={userId ? "14" : "16"}>
                  {hideDelete ? null : (
                    <Button
                      floated="right"
                      content="Delete"
                      basic
                      color="red"
                      onClick={(e) => onDelete(e, authPackage.info.id)}
                    />
                  )}
                  <Button
                    floated="right"
                    content="Update"
                    basic
                    color="blue"
                    onClick={(e) => updatePackage(e, authPackage.info.id)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PackageAccordionTitle;
