export const rolesReducer = (state, action) => {
  switch (action.type) {
    case "NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "PERMISSIONS":
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      throw new Error("Could not update state");
  }
};

export const initialStateRolesReducer = {
  name: "",
  permissions: [],
};
