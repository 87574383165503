export const uploadInstructions = {
  students: {
    header: "To import students, follow format in example file (.xls).",
  },
  teachers: {
    header: "To import teachers, follow format in example file (.xls).",
  },
  organisation: {
    header:
      "Accepts comma separated files containing email (mandatory), first name, last name. Remove header if present.",
  },
  library: {
    header:
      "Accepts comma separated files containing email (mandatory), first name, last name. Remove header if present.",
  },
};
