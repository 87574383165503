import { Button, Icon, Modal, Form } from "semantic-ui-react";
import React, { useState, useReducer } from "react";
import { rolesReducer, initialStateRolesReducer } from "../../../utils/rolesReducer";

export const AddRoles = ({ allPermissions, onAddRole, loading, title }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const resetModalState = () => {
    setModalOpen(false);
  };

  const [state, dispatch] = useReducer(rolesReducer, initialStateRolesReducer);

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  const onSubmit = async () => {
    if (state) {
      const nameUpperCase = state.name.toUpperCase();

      const replaceSpaces = nameUpperCase.replace(/\s+/g, "_");
      onAddRole({ ...state, slug: replaceSpaces }, () => setModalOpen(false));
    }
  };

  return (
    <>
      <Button role="add-student" basic primary size="tiny" onClick={() => setModalOpen(true)}>
        <Icon name="add" />
        {title}
      </Button>

      <Modal onClose={resetModalState} open={modalOpen}>
        <Modal.Header>Create School Role</Modal.Header>
        <Modal.Content>
          <Form id="create-role" onSubmit={onSubmit} loading={loading}>
            <Form.Input
              label={"Role name"}
              required
              placeholder="Name"
              value={state.name}
              onChange={handleFieldsUpdate("NAME")}
            />

            <Form.Dropdown
              style={{ minWidth: "200px" }}
              placeholder="Select permissions"
              disabled={!allPermissions.length}
              defaultValue={null}
              fluid
              required
              multiple
              selection
              label={"Role permissions"}
              options={allPermissions}
              onChange={handleFieldsUpdate("PERMISSIONS")}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary form="create-role">
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
