import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PackageForm from "components/PackageForm";
import Layout from "components/Layout";
import { useFetchUserService } from "../../hooks/useFetch";
import { Loader } from "semantic-ui-react";
import { filterAuthoritiesArray } from "utils/filterAuthoritiesArray";

const ManagePackages = () => {
  const { id } = useParams();
  const { data, loading } = useFetchUserService("/authorities");
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredData = filterAuthoritiesArray(data);
      setTreeData(filteredData);
    }
  }, [data]);

  if (loading) {
    return (
      <Loader active inline="centered" style={{ marginTop: "10px" }}>
        Loading authorities
      </Loader>
    );
  }

  return (
    <Layout headerText={id ? "Edit package" : "Create package"} headerSpacing={true}>
      <PackageForm allAuthorities={treeData} />
    </Layout>
  );
};

export default ManagePackages;
