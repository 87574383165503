export const limitOptions = [
  {
    value: 10,
    text: "10",
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "50",
    value: 50,
  },
  {
    text: "75",
    value: 75,
  },
  {
    text: "100",
    value: 100,
  },
];
