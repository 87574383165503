import React, { useCallback, useReducer, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { NAME, ACTIVE, DESCRIPTION, AUTHORITY_KEY, AUTHORITY_KIND } from "../actions";
import { authorityReducer } from "../reducers";
import { useMessage } from "../../../ne-ui";
import { callUserService } from "../../../hooks/useFetch";

const CreateAuthority = ({ resetModal, getAuthorities }) => {
  const dispatchMessage = useMessage();
  const [loading, setloading] = useState(false);
  const createAuthority = useCallback(
    async (values) => {
      setloading(true);
      try {
        const { response } = await callUserService("/authorities", {
          method: "POST",
          body: JSON.stringify(values),
        });
        if (response.message) {
          throw new Error(response.message);
        }
        await getAuthorities();
        dispatchMessage({
          title: "Authority created",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        resetModal();
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setloading(false);
      }
      setloading(false);
    },
    [dispatchMessage, resetModal, getAuthorities]
  );
  const [state, dispatch] = useReducer(authorityReducer, {
    name: "",
    active: true,
    description: "",
    parentId: null,
    authorityKey: "",
    authorityKind: null,
  });

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  const handleSubmit = () => {
    // createAuthority({ variables: { input: { ...state } } }).then(() => resetModal())
    createAuthority({ ...state });
  };

  const { name, active, description, authorityKey, authorityKind } = state;

  const selectOptions = [
    /* We should query all of the available keys instead */
    { key: "PRODUCT_OBJECT_AUTHORITY", value: "PRODUCT_OBJECT_AUTHORITY", text: "PRODUCT OBJECT AUTHORITY" },
    { key: "SYSTEM_OBJECT_AUTHORITY", value: "SYSTEM_OBJECT_AUTHORITY", text: "SYSTEM OBJECT AUTHORITY" },
    { key: "none", value: "None", text: "None" },
  ];

  return (
    <Modal onClose={() => resetModal()} open={true}>
      <Modal.Header>Create Authority</Modal.Header>
      <Modal.Content>
        <Form id="create-authority" onSubmit={handleSubmit} loading={loading}>
          <Form.Input
            required
            placeholder="Authority key (not editable after creation)"
            value={authorityKey}
            onChange={handleFieldsUpdate(AUTHORITY_KEY)}
          />
          <Form.Input placeholder="Name" value={name} onChange={handleFieldsUpdate(NAME)} />
          <Form.Input placeholder="Description" value={description} onChange={handleFieldsUpdate(DESCRIPTION)} />
          <Form.Select
            placeholder="Authority Kind"
            options={selectOptions}
            defaultValue={null}
            value={authorityKind}
            onChange={handleFieldsUpdate(AUTHORITY_KIND)}
          />
          <Form.Checkbox
            label="Active"
            value={active.toString()}
            checked={active}
            onChange={handleFieldsUpdate(ACTIVE)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary form="create-authority">
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateAuthority;
