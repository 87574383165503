import React, { useState } from "react";
import { Button, Header } from "semantic-ui-react";
import { ActiveIntegration } from "./components/ActiveIntegration";
import { SelectWondeSchool } from "./components/SelectWondeSchool";
import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";

export const UserWonde = ({ schoolId }) => {
  const [linkWonde, setLinkWonde] = useState(false);

  const { hasWondeRosteringPermission } = useAuthContext();
  if (!hasWondeRosteringPermission) {
    return null;
  }

  return (
    <>
      <Header as="h3" dividing>
        Rostering
      </Header>
      {!linkWonde ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ActiveIntegration schoolId={schoolId} />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button primary onClick={() => setLinkWonde(true)}>
              Connect to Wonde
            </Button>
          </div>
        </>
      ) : (
        <SelectWondeSchool
          schoolId={schoolId}
          onSelectComplete={() => setLinkWonde(false)}
          onCancel={() => setLinkWonde(false)}
        />
      )}
    </>
  );
};
