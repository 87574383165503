import React from "react";
import { EdlinkIntegration } from "./EdlinkIntegration";
import { EdlinkSchool } from "./EdlinkSchool";
import { useApiCall } from "../../../../hooks/useApiCall";
import { Dimmer, Header, Loader } from "semantic-ui-react";

export const ActiveIntegration = ({ schoolId }) => {
  const { data, loading } = useApiCall("get", `/user-edlink/${schoolId}`, { autoCall: true });

  let activeRender = (
    <Header size="large" style={{ margin: 32 }}>
      Not Edlink School connected
    </Header>
  );

  if (data?.integration && data?.school) {
    activeRender = (
      <div style={{ display: "flex" }}>
        <div style={{ padding: 16 }}>
          <EdlinkIntegration integration={data?.integration} />
        </div>
        <div style={{ padding: 16 }}>
          <EdlinkSchool school={data?.school} />
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader active inline="centered" />
        </Dimmer>
      ) : (
        activeRender
      )}
    </>
  );
};
