import React, { useEffect } from "react";
import { Icon, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";

const Toast = ({ toast, dismissTime = 10000, onDismiss }) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1, marginTop: "5px" },
  });

  useEffect(() => {
    if (typeof dismissTime === "number") {
      let clearInterval;

      if (Boolean(toast)) {
        clearInterval = setTimeout(() => {
          onDismiss({
            payload: toast.id,
          });
        }, dismissTime);
      }

      return () => clearTimeout(clearInterval);
    }
  }, [toast, onDismiss, dismissTime]);

  if (!toast) {
    return null;
  }

  return (
    <animated.div style={props}>
      <Message
        onDismiss={() =>
          onDismiss({
            payload: toast.id,
          })
        }
        floating
        className={toast.level}
      >
        {toast.showIcon ? <Icon name={toast.showIcon} size="small" fitted /> : null}
        <Message.Header
          style={{ display: "inline-block", padding: toast.showIcon ? "0 1em" : " padding: 0 0 0 0.5em" }}
        >
          {toast.title}
        </Message.Header>
      </Message>
    </animated.div>
  );
};

Toast.propTypes = {
  toast: PropTypes.shape({
    title: PropTypes.string.isRequired,
    showIcon: PropTypes.string,
    id: PropTypes.any.isRequired,
    level: PropTypes.string.isRequired,
  }),
  onDismiss: PropTypes.func.isRequired,
  dismissTime: PropTypes.number,
};

export default Toast;
