import React from "react";
import { Container, Divider, Header, Segment } from "semantic-ui-react";

const Layout = ({ headerText, headerSpacing, MenuComponent, callToAction, children }) => {
  return (
    <>
      <Segment vertical inverted className="no-padding">
        <Container>
          <Segment inverted vertical clearing>
            <Divider hidden />
            {headerText && (
              <>
                <Header size="huge" inverted>
                  {headerText}
                  {headerSpacing ? <Divider hidden className="small" /> : null}
                </Header>
                {callToAction ? callToAction : null}
              </>
            )}
          </Segment>
          {MenuComponent ? MenuComponent : null}
        </Container>
      </Segment>
      <Divider hidden className="medium" />
      <Container>{children}</Container>
      <Divider hidden className="medium" />
    </>
  );
};

export default Layout;
