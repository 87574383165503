import React, { useState } from "react";
import { useNavItems } from "../hooks/useNavItems";

export const NEUIContext = React.createContext({});

export function NEUIProvider({ config, children }) {
  const [schoolId, setSchoolId] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const { navItems, userContext, tenant } = useNavItems(config);

  return (
    <NEUIContext.Provider
      value={{
        schoolId,
        setSchoolId,
        config,
        navItems,
        userContext,
        tenant,
        selectedSchool,
        setSelectedSchool,
      }}
    >
      {children}
    </NEUIContext.Provider>
  );
}

export default NEUIProvider;
