import React, { useCallback, useEffect, useState } from "react";
import {
  Header,
  Button,
  Icon,
  Form,
  Divider,
  Confirm,
  Dimmer,
  Loader,
  Segment,
  List,
  ListItem,
} from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { callInternalApiGateway, callUserService, useFetchUserService } from "../../hooks/useFetch";
import { useSelectedUserContext } from "context/SelectedUserContext";
import { UserTypeIds } from "../../constants/userTypes";
import { SchoolAutocomplete } from "../SchoolAutocomplete/SchoolAutocomplete";
import { Link } from "react-router-dom";

const ParentGroupList = () => {
  const {
    selectedUserInformation,
    selectedUserSchools,
    userTypes,
    selectedSchool,
    fetchUserSelected,
    fetchUserSchools,
    setSelectedSchool,
  } = useSelectedUserContext();
  const dispatchMessage = useMessage();
  const { data: schoolData, schoolLoading } = useFetchUserService("/school?limit=10000");

  const [parentId, setParentId] = useState("");

  const [selectedNewType, setSelectedNewType] = useState({
    name: selectedSchool.schoolUserType.name,
    id: selectedSchool.schoolUserType.id,
  });

  useEffect(() => {
    setSelectedNewType({
      name: selectedSchool.schoolUserType.name,
      id: selectedSchool.schoolUserType.id,
    });
  }, [selectedSchool]);

  const [confirmRemove, setConfirmRemove] = useState(null);
  const [toggleAdd, setToggleAdd] = useState(false);
  const [selectedAddSchoolRolesOptions, setSelectedAddSchoolRolesOptions] = useState([]);
  const [selectedAddSchoolRole, setSelectedAddSchoolRole] = useState("");
  const [selectedAddSchoolUserType, setSelectedAddSchoolUserType] = useState("");

  const searchAddSchoolRoles = useCallback(
    async (id) => {
      setSelectedAddSchoolRolesOptions([]);
      setSelectedAddSchoolUserType("");
      if (id) {
        const { response } = await callUserService(`/school/${id}/roles`);
        setSelectedAddSchoolRolesOptions(response);
      }
    },
    [setSelectedAddSchoolRolesOptions, setSelectedAddSchoolUserType]
  );

  const [toggleUserType, setToggleUserType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  const handleAddUserToSchool = () => {
    setToggleAdd(!toggleAdd);
    setSelectedAddSchoolRolesOptions([]);
    setSelectedAddSchoolRole("");
  };

  const handleParentInput = (_, { value }) => setParentId(value);

  const addParent = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const body = [
          {
            userId: selectedUserInformation.userId,
            userTypeId: selectedAddSchoolUserType.id,
            schoolRoleIds: [selectedAddSchoolRole],
          },
        ];

        const { response } = await callUserService(`/school/${values.parentId}/user/add`, {
          method: "POST",
          body: JSON.stringify(body),
        });
        if (response.error) {
          throw new Error(response.message);
        }
        await fetchUserSchools(selectedUserInformation.userId);
        await fetchUserSelected(selectedUserInformation.userId);
        dispatchMessage({
          title: "Parent added",
          level: "success",
          showIcon: "check",
          type: "toast",
        });

        setToggleAdd(false);
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setSelectedAddSchoolUserType("");
        setSelectedAddSchoolRole("");
        setLoading(false);
      }
    },
    [dispatchMessage, fetchUserSchools, fetchUserSelected, selectedUserInformation.userId]
  );
  const disconnectMembers = useCallback(
    async (values) => {
      setDisconnectLoading(true);
      try {
        await callUserService(`/groups/disconnect-members`, {
          method: "POST",
          body: JSON.stringify(values),
        });
        await callInternalApiGateway(`/user-selected-school/check-to-delete`, {
          method: "DELETE",
          body: JSON.stringify({ userId: values.userIds[0], schoolId: confirmRemove }),
        });
        await fetchUserSchools(selectedUserInformation.userId);
        await fetchUserSelected(selectedUserInformation.userId);
        dispatchMessage({
          title: "Removed from parent",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        setConfirmRemove(null);
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
        setConfirmRemove(null);
      } finally {
        setDisconnectLoading(false);
      }
    },
    [confirmRemove, dispatchMessage, fetchUserSchools, fetchUserSelected, selectedUserInformation.userId]
  );

  const onAddParent = () => {
    addParent({ userId: selectedUserInformation.userId, parentId });
  };

  const closeConfirm = () => setConfirmRemove(null);

  const removeParent = (id) => {
    const currentGroup = selectedUserInformation.groups.find((f) => f.owner.userId === confirmRemove);

    if (selectedUserInformation.groups.length === 1) {
      disconnectMembers({ userIds: [selectedUserInformation.userId], groupIds: [currentGroup.groupId] });
    } else if (currentGroup) {
      disconnectMembers({ userIds: [selectedUserInformation.userId], groupIds: [currentGroup.groupId] });
    }
  };

  const cancelUpdateUserType = () => {
    setToggleUserType(!toggleUserType);
    setSelectedNewType({
      name: selectedSchool.schoolUserType.name,
      id: selectedSchool.schoolUserType.id,
    });
  };

  const onUpdateUserType = async () => {
    setLoading(true);
    setToggleUserType(!toggleUserType);
    await callUserService(`/school/${selectedSchool.id}/user/${selectedUserInformation.userId}`, {
      body: JSON.stringify({ userTypeId: selectedNewType.id }),
      method: "PUT",
    });
    let prevSelectedSchool = selectedSchool;
    await fetchUserSchools(selectedUserInformation.userId);
    setSelectedSchool({
      ...selectedUserSchools.find((a) => a.id === prevSelectedSchool.id),
      schoolUserType: selectedNewType,
    });
    setLoading(false);
  };
  return (
    <>
      <Confirm
        open={confirmRemove}
        onCancel={closeConfirm}
        onClose={closeConfirm}
        onConfirm={removeParent}
        content={
          <>
            <Dimmer active={disconnectLoading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>
              {selectedUserInformation.groups?.length === 1
                ? "Do you want to remove the parent and make the user as an orphan?"
                : "Do you want to remove the parent?"}
            </Segment>
          </>
        }
      />
      <Header>Schools</Header>

      <List bulleted>
        {selectedUserSchools?.map((school) => (
          <ListItem key={school.id}>
            <Link to={`/school/${school.id}`}>{school.name}</Link>
          </ListItem>
        ))}
      </List>

      <Form.Select
        label="School Selected"
        placeholder="Select a school"
        value={selectedSchool.id}
        onChange={(_e, data) => {
          data?.value &&
            setSelectedSchool(selectedUserSchools.find((school) => school.id.toString() === data.value.toString()));
        }}
        options={
          selectedUserSchools?.map((school) => {
            return {
              text: school.name,
              value: school.id,
            };
          }) || []
        }
      />
      {!toggleAdd ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px" }}>
          <Button circular active={toggleAdd} onClick={handleAddUserToSchool} compact size="tiny" basic color="black">
            <Icon name="add circle" />
            Add User to a School
          </Button>
        </div>
      ) : (
        <>
          <Divider hidden className="mini" />
          <Form>
            <div className="field">
              <label>School</label>
              <SchoolAutocomplete
                source={schoolData?.content || []}
                setSelectedSchool={(value) => setParentId(value)}
                onSelectComplete={searchAddSchoolRoles}
              />
            </div>
            <Form.Select
              label="Role Selected"
              placeholder="Select a role for the school"
              value={selectedAddSchoolRole?.id || ""}
              onChange={(_e, data) => {
                data?.value &&
                  setSelectedAddSchoolRole(
                    selectedAddSchoolRolesOptions.find((role) => role.id.toString() === data.value.toString())
                  );
              }}
              options={
                selectedAddSchoolRolesOptions?.map((role) => {
                  return {
                    text: role.name,
                    value: role.id,
                  };
                }) || []
              }
            />
            <Form.Select
              label="User Type selected"
              placeholder="Select User Type in new school"
              value={selectedAddSchoolUserType?.id || ""}
              onChange={(e, data) =>
                setSelectedAddSchoolUserType({ id: data.value, name: userTypes.find((x) => x.id === data.value)?.name })
              }
              options={
                userTypes?.map((type) => {
                  return {
                    text: type.name,
                    value: type.id,
                  };
                }) || []
              }
            />
            <Form.Group style={{ display: "flex", justifyContent: "center" }}>
              <Form.Button
                loading={loading}
                onClick={onAddParent}
                size="small"
                disabled={!parentId || !selectedAddSchoolRole || !selectedAddSchoolUserType}
              >
                Add
              </Form.Button>
              <Form.Button color="red" onClick={handleAddUserToSchool} size="small">
                Cancel
              </Form.Button>
            </Form.Group>
          </Form>
        </>
      )}

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px" }}>
        <Form.Select
          label="User Type in selected school"
          placeholder="Select type"
          disabled={!toggleUserType}
          value={selectedNewType.id}
          onChange={(e, data) =>
            setSelectedNewType({ id: data.value, name: userTypes.find((x) => x.id === data.value)?.name })
          }
          options={
            userTypes?.map((type) => {
              return {
                text: type.name,
                value: type.id,
              };
            }) || []
          }
        />
      </div>
      {!toggleUserType ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px" }}>
          <Button
            circular
            active={toggleAdd}
            onClick={() => setToggleUserType(!toggleUserType)}
            compact
            size="tiny"
            basic
            color="black"
          >
            <Icon name="add circle" />
            Change User Type in selected school
          </Button>
        </div>
      ) : (
        <>
          <Divider hidden className="mini" />

          <Form>
            <Form.Group style={{ display: "flex", justifyContent: "center" }}>
              <Form.Button loading={loading} onClick={onUpdateUserType} size="small">
                Update
              </Form.Button>
              <Form.Button color="red" onClick={cancelUpdateUserType} size="small">
                Cancel
              </Form.Button>
            </Form.Group>
          </Form>
        </>
      )}
    </>
  );
};

export default ParentGroupList;
