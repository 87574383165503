import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { Form, Header } from "semantic-ui-react";
import { useMessage } from "../../../../ne-ui";
import { callUserService } from "../../../../hooks/useFetch";

const initialState = {
  groupId: "",
  email: "",
};

const SHOW_ORGANISATION_FIELD_TYPES = ["schools", "libraries", "organisations"];

const addChildReducer = (state, action) => {
  if (action.type === "reset")
    return {
      ...initialState,
    };

  return {
    ...state,
    [action.type]: action.payload,
  };
};

export const AddChildren = ({ schoolId }) => {
  const dispatchMessage = useMessage();
  const [data, setData] = useState(null);
  const [schoolRolesLoading, setschoolRolesLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);

  const fetchSchoolRoles = useCallback(async () => {
    setschoolRolesLoading(true);
    const { response } = await callUserService(`/school/${schoolId}/roles`);
    setData(response);
    setschoolRolesLoading(false);
  }, [schoolId]);

  const fetchUserTypes = useCallback(async () => {
    const { response } = await callUserService(`/user-types`);
    setUserTypes(response);
  }, []);

  useEffect(() => {
    fetchSchoolRoles();
    fetchUserTypes();
  }, [fetchSchoolRoles, fetchUserTypes]);

  const addSchoolRoleToUser = async (userId, schoolRoleNames) => {
    try {
      const response = await Promise.all(
        schoolRoleNames.map((name) =>
          callUserService(`/users/${userId}/add-school-role`, {
            method: "POST",
            body: JSON.stringify({ schoolId, schoolRole: name }),
          })
        )
      );
      if (response.message) {
        throw new Error(response.message);
      }
      dispatchMessage({
        title: `Role added!`,
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      dispatch({
        type: "reset",
      });
    } catch (e) {
      dispatchMessage({
        title: `${e}`,
        level: "error",
        showIcon: "warning circle",
        type: "toast",
      });
    } finally {
      setLoading(false);
    }
  };

  const createSubUser = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { groupId } = values;

        const role = data?.find(({ id }) => id === groupId);

        const schoolRoleIds = [];
        let userTypeId;
        if (role.slug === "SCHOOL_ADMIN") {
          schoolRoleIds.push(data.find(({ slug }) => slug === "SCHOOL_ADMIN")?.id);
          userTypeId = userTypes.find(({ name }) => name.toLowerCase() === "admin")?.id;
        } else {
          userTypeId = userTypes.find(({ name }) => name.toLowerCase() === role.slug.toLowerCase())?.id;
          schoolRoleIds.push(role.id);
        }
        const body = [
          {
            email: values.email,
            schoolRoleIds,
            userTypeId,
          },
        ];
        const { response } = await callUserService(`/school/${schoolId}/user/add`, {
          method: "POST",
          body: JSON.stringify(body),
        });
        if (response.message) {
          if (response.message.includes("Users already added in this school")) {
            const schoolRoleSlugs = data.filter((a) => schoolRoleIds.includes(a.id)).map((a) => a.slug);
            const userIds = extractIDs(response.message);
            return addSchoolRoleToUser(userIds[0], schoolRoleSlugs);
          }
          throw new Error(response.message);
        }
        dispatchMessage({
          title: `Child added!`,
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        dispatch({
          type: "reset",
        });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          level: "error",
          showIcon: "warning circle",
          type: "toast",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage /* fetchUserInfo */, data]
  );

  const [state, dispatch] = useReducer(addChildReducer, initialState);

  const options =
    data?.map((m) => ({
      key: m.name,
      text: m.name,
      value: m.id,
    })) || [];

  const onSubmit = () => {
    createSubUser({ email: state.email, groupId: state.groupId });
  };

  const onInputChange = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const onRoleSelected = (groupId) => {
    dispatch({
      type: "groupId",
      payload: groupId,
    });
  };

  const organisationFieldAllowed = useMemo(() => {
    if (!state.groupId || !data?.groupsExcludeStudentGroups?.length) return false;

    const filtered = data?.groupsExcludeStudentGroups
      .filter((m) => SHOW_ORGANISATION_FIELD_TYPES.includes(m.groupType))
      .map((m) => m.info.id);
    return filtered.includes(state.groupId);
  }, [state.groupId, data?.groupsExcludeStudentGroups]);

  useEffect(() => {
    if (organisationFieldAllowed) return;

    dispatch({
      type: "organisation",
      payload: "",
    });
  }, [organisationFieldAllowed]);

  return (
    <>
      <Header as="h3" dividing>
        Add role to user
      </Header>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <Form.Select
            loading={schoolRolesLoading}
            onChange={(_, { value }) => onRoleSelected(value)}
            label="Role"
            value={state.groupId}
            placeholder="Select role"
            options={options}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            onChange={(_, { value }) => onInputChange("email", value)}
            label="Email"
            required
            value={state.email}
            placeholder="Email"
          ></Form.Input>
        </Form.Field>
        <Form.Field>
          <Form.Button positive disabled={loading || !state.email || !state.groupId} type="submit">
            Submit
          </Form.Button>
        </Form.Field>
      </Form>
    </>
  );
};

const extractIDs = (text) => {
  const regex = /\((\d+)\)/g;
  const ids = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    ids.push(match[1]);
  }

  return ids;
};
