import React from "react";
// import { Changelog } from "./components/Changelog";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";
import { Note } from "./components/Note";

export const About = () => {
  const { schoolInformation } = useSelectedSchoolContext();

  return (
    <>
      {/* <Changelog schoolId={schoolInformation.id} /> */}
      <Note schoolId={schoolInformation.id} />
    </>
  );
};
