import React, { useState } from "react";
import { Form, Divider } from "semantic-ui-react";
// import { callUserService } from "../../../../../hooks/useFetch";

export const Note = ({ schoolId }) => {
  const [loading, setLoading] = useState(false);
  const [userNote, setNote] = useState("");

  const saveNote = async (values) => {
    setLoading(true);
    //   await callUserService(`/school-note`, { method: "POST", body: JSON.stringify(values) });
    setLoading(false);
  };

  const onNoteChange = (_, { value }) => setNote(value);

  const onSaveNote = () => saveNote({ schoolId, note: userNote });

  return (
    <>
      {/* <Divider hidden className="massive" /> */}
      <Form>
        <Form.TextArea onChange={onNoteChange} value={userNote} placeholder="Add note..." />
        <Form.Group>
          <Form.Button disabled={loading} loading={loading} onClick={onSaveNote} color="blue" type="submit">
            Save
          </Form.Button>
        </Form.Group>
      </Form>
    </>
  );
};
