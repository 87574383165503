import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button, Confirm, Loader } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { useDisclosure } from "hooks/useDisclosure";
import { callUserService } from "../../hooks/useFetch";

const DeleteMember = ({ groupId, userId, groupOwnerId, fetchMembers, refetch }) => {
  const dispatchMessage = useMessage();
  const [isOpen, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const disconnectMember = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { userId, groupId } = values;
        await callUserService(`/classroom/${groupId}/members/${userId}`, {
          method: "DELETE",
        });
        await fetchMembers(groupId);
        dispatchMessage({
          id: "disconnect-member",
          title: "User removed from group",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        close();
        refetch(groupOwnerId);
      } catch (error) {
        dispatchMessage({
          id: "disconnect-member",
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, close, fetchMembers, groupOwnerId, groupId, refetch]
  );

  const confirmDelete = () => {
    disconnectMember({ userId, groupId });
  };

  if (loading) {
    return <Loader active inline size="small" />;
  }

  return (
    <>
      <Confirm
        open={isOpen}
        title="Remove user from group"
        content="Are you sure you want to remove this user from the group?"
        onCancel={close}
        onConfirm={confirmDelete}
      />

      <Button role="delete" data-testid="delete" basic negative size="tiny" onClick={open}>
        Remove
      </Button>
    </>
  );
};

DeleteMember.propTypes = {
  groupOwnerId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default DeleteMember;
