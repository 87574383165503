import React, { useState } from "react";
import Layout from "components/Layout";
import { callInternalApiGateway, useFetchInternalApiGateway } from "hooks/useFetch";
import { Button, Segment, List, Loader } from "semantic-ui-react";
import NewsList from "components/NewsList/NewsList";
import { useMessage } from "ne-ui";
import MDEditor, { commands } from "@uiw/react-md-editor";

const News = () => {
  const [isCreatingNews, setIsCreatingNews] = useState(false);
  const [news, setNews] = useState("");
  const { data, loading, setFetchData } = useFetchInternalApiGateway("/news");

  const dispatchMessage = useMessage();

  const activeCreateNews = () => setIsCreatingNews(true);

  const refetchNews = () => setFetchData(true);

  const createNews = async () => {
    try {
      const { data } = await callInternalApiGateway(
        `/news`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ description: news }),
        },
        true
      );

      if (data?.status === 400) {
        throw new Error(data.message);
      }

      setIsCreatingNews(false);
      setNews("");
      refetchNews();
      dispatchMessage({
        title: "News created",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      console.error(error);
    }
  };
  const handleCancelCreateButton = () => {
    setIsCreatingNews(false);
    setNews("");
  };

  return (
    <Layout headerSpacing={true} headerText="Propello News">
      <Segment>
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <React.Fragment>
            {!isCreatingNews ? (
              <Button content="Add News" onClick={activeCreateNews} />
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button.Group className="right floated">
                  <Button icon="save" basic size="tiny" floated="right" type="submit" onClick={createNews} />
                  <Button
                    icon="cancel"
                    basic
                    color="red"
                    size="tiny"
                    floated="right"
                    onClick={handleCancelCreateButton}
                  />
                </Button.Group>
                <MDEditor
                  commands={[commands.link]}
                  value={news}
                  onChange={setNews}
                  width={"100%"}
                  visiableDragbar={false}
                />
              </div>
            )}
            <List relaxed className="striped">
              {(data || [])
                ?.sort((a, b) => +a?.id - +b?.id)
                .map((news, index) => (
                  <NewsList
                    id={news.id}
                    refetchNews={refetchNews}
                    key={news.id}
                    description={news.description}
                    createdBy={news.createdBy}
                    newsPosition={index + 1}
                  />
                ))}
            </List>
          </React.Fragment>
        )}
      </Segment>
    </Layout>
  );
};

export default News;
