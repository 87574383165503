import React, { useCallback } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { EdlinkSchool } from "../../EdlinkSchool";

export const ConfirmSelectionModal = ({ integration, school, onComplete, onCancel, schoolId }) => {
  const { call, loading } = useApiCall("post", "/user-edlink", { autoCall: false });

  const onYesClick = useCallback(async () => {
    await call({ body: { integrationId: integration.id, schoolId: school.id, userId: schoolId } });
    onComplete();
  }, [call, integration.id, onComplete, school.id, schoolId]);
  return (
    <Modal open onClose={onCancel}>
      <Modal.Header>Are you sure to connect to this school?</Modal.Header>
      <Modal.Content>
        <EdlinkSchool school={school} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} disabled={loading}>
          No
        </Button>
        <Button onClick={onYesClick} primary disabled={loading} loading={loading}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
