import { getCookieValue } from "utils";

const postAsyncRecursive = async (users, url, n) => {
  const access_token = getCookieValue("access_token");
  const chunk = users.slice(n, n + 25);
  const importUsers = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(chunk),
  });

  if (!importUsers.ok) return Promise.reject();

  if (chunk.length < 25) return Promise.resolve();

  await postAsyncRecursive(users, url, n + 25);
};

const importUsers = async (users, userId, type, sendEmail = false, userType, groupId) => {
  const url = `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/import/${userId}/${type}?sendEmail=${sendEmail}`;

  return postAsyncRecursive(users, url, 0);
};

export default importUsers;
