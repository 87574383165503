import React from "react";
import { Segment, Accordion } from "semantic-ui-react";

import AuthorityAccordionContent from "./AuthorityAccordionContent";

const AuthorityAccordion = ({ node, onEditButtonClicked, hasSystemPermission }) => {
  const panels = [
    {
      key: node.info.id,
      title: {
        content: node.name,
      },
      content: {
        content: (
          <AuthorityAccordionContent
            node={node}
            onEditButtonClicked={onEditButtonClicked}
            hasSystemPermission={hasSystemPermission}
          />
        ),
      },
    },
  ];

  return (
    <Segment>
      <Accordion panels={panels} exclusive={false} fluid />
    </Segment>
  );
};

export default AuthorityAccordion;
