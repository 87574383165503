export const generateAuthoritiesInDropdowns = (authoritiesArray) => {
  if (!authoritiesArray) return [];

  const result = [
    { id: 1, name: "Teaching Materials", authorities: [] },
    { id: 2, name: "System Authorities", authorities: [] },
  ];

  authoritiesArray.forEach((item) => {
    // Teaching Materials
    if (item.authorityKind === "PRODUCT_OBJECT_AUTHORITY" || item.authorityKind === "None") {
      result[0].authorities.push(item);
      // Type System Authorities.
    } else if (item.authorityKind === "SYSTEM_OBJECT_AUTHORITY" || item.authorityKind === "SYSTEM") {
      result[1].authorities.push(item);
    }
  });

  return result;
};

export const formatAuthorities = (allAuthorities, selectedAuthorities, forSchools) => {
  const authoritiesAssigned = selectedAuthorities.filter((x) => !x.packageId);
  const authoritiesFromPackageAssigned = selectedAuthorities.filter((x) => !!x.packageId);

  const mapped = allAuthorities.reduce((prev, curr) => {
    const findFromAuthoritiesAssigned = authoritiesAssigned.find((y) => y.id === curr?.authorityId);
    const findFromPackagesAssigned = authoritiesFromPackageAssigned.find((y) => y.id === curr?.authorityId);

    const allPackagesNames = authoritiesFromPackageAssigned
      .filter((y) => y.id === curr?.authorityId)
      .map((x) => x.packageName);

    if (findFromPackagesAssigned) {
      return [
        ...prev,
        {
          id: forSchools
            ? findFromPackagesAssigned?.authorityId || curr.authorityId
            : findFromPackagesAssigned?.schoolAuthorityId || curr.schoolAuthorityId,
          name: curr.name,
          authorityKind: curr.authorityKind,
          packageId: findFromPackagesAssigned?.packageId || null,
          packageName: allPackagesNames || [],
          disabled: !!findFromPackagesAssigned?.packageId || null,
          relationId:
            (forSchools
              ? findFromPackagesAssigned?.schoolAuthorityId
              : findFromPackagesAssigned?.schoolUserAuthorityId) || null,
          selected: true,
        },
      ];
    }

    if (findFromAuthoritiesAssigned) {
      return [
        ...prev,
        {
          id: forSchools
            ? findFromAuthoritiesAssigned?.authorityId || curr.authorityId
            : findFromAuthoritiesAssigned?.schoolAuthorityId || curr.schoolAuthorityId,
          name: curr.name,
          authorityKind: curr.authorityKind,
          packageId: null,
          packageName: null,
          disabled: null,
          relationId:
            (forSchools
              ? findFromAuthoritiesAssigned?.schoolAuthorityId
              : findFromAuthoritiesAssigned?.schoolUserAuthorityId) || null,
          selected: true,
        },
      ];
    }

    const mappedAuthority = {
      id: forSchools
        ? findFromAuthoritiesAssigned?.authorityId || curr.authorityId
        : findFromAuthoritiesAssigned?.schoolAuthorityId || curr.schoolAuthorityId,
      name: curr.name,
      authorityKind: curr.authorityKind,
      packageId: findFromAuthoritiesAssigned?.packageId || null,
      packageName: findFromAuthoritiesAssigned?.packageName ? [findFromAuthoritiesAssigned?.packageName] : [],
      disabled: !!findFromAuthoritiesAssigned?.packageId || null,
      relationId:
        (forSchools
          ? findFromAuthoritiesAssigned?.schoolAuthorityId
          : findFromAuthoritiesAssigned?.schoolUserAuthorityId) || null,
      selected: !!findFromAuthoritiesAssigned?.packageId || !!findFromAuthoritiesAssigned,
    };
    return [...prev, mappedAuthority];
  }, []);
  return mapped;
};
