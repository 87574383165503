import { Search } from "semantic-ui-react";
import Fuse from "fuse.js";
import { useReducer } from "react";
import debounce from "lodash/debounce";

const RESULTS = "RESULTS";
const VALUE = "VALUE";
const LOADING = "LOADING";
const SET_MULTIPLE = "SET_MULTIPLE";
const RESET_STATE = "RESET_STATE";
const INITIAL_STATE = {
  results: [],
  value: "",
  loading: false,
};

const searchReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESULTS:
      return {
        ...state,
        results: [...payload],
      };
    case VALUE:
      return {
        ...state,
        value: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_MULTIPLE:
      return {
        ...state,
        ...payload,
      };
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const SchoolAutocomplete = ({ source, setSelectedSchool, onSelectComplete }) => {
  const [state, dispatch] = useReducer(searchReducer, INITIAL_STATE);
  const { results, value, loading } = state;
  const schoolList = source.map((school) => ({
    id: school.info.id,
    title: school.name,
  }));

  const handleResultSelect = (e, { result }) => {
    dispatch({ type: VALUE, payload: result.title });
    if (setSelectedSchool) setSelectedSchool(result.id);
  };

  const onResultSelect = (e, { result }) => {
    handleResultSelect(e, { result });
    if (onSelectComplete) onSelectComplete(result.id);
  };

  const fuse = new Fuse(schoolList, {
    keys: ["title"],
  });

  const handleSearchChange = (e, { value }) => {
    dispatch({ type: SET_MULTIPLE, payload: { loading: true, value } });

    setTimeout(() => {
      if (value.length < 1) {
        dispatch({ type: RESET_STATE, payload: INITIAL_STATE });
      }

      dispatch({ type: LOADING, payload: false });
      dispatch({ type: RESULTS, payload: fuse.search(value)?.map((item) => item?.item) || [] });

      setSelectedSchool(value);
    }, 300);
  };

  return (
    <Search
      input={{
        icon: "search",
        iconPosition: "left",
        style: { width: "100%" },
      }}
      className="autocomplete"
      results={results}
      fluid
      placeholder={"Select School"}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
      onResultSelect={onResultSelect}
      loading={loading}
      value={value}
      noResultsMessage={"No school found"}
      // showNoResults={data.isReadOnly}
      autoFocus
    />
  );
};
