import { NAME, DESCRIPTION, AUTHORITIES, UPDATE_STATE, ALL_AUTHORITIES, EXTERNAL_ID } from "./actions";

const findChildren = (data, itemId, nestingKey = "authorities") => {
  return data.reduce((acc, curr) => {
    if (acc) return acc;
    if (curr.info.id === itemId) return curr;
    if (curr[nestingKey]) return findChildren(curr[nestingKey], itemId, nestingKey);

    return acc;
  }, null);
};

const storeChildrensIdInArray = (data) => {
  const ids = [];
  const recursivePushId = (node) => {
    ids.push(node.info.id);
    if (node.authorities) node.authorities.forEach((f) => recursivePushId(f));
  };

  recursivePushId(data);

  return ids;
};

export const createPackageReducer = (state, action) => {
  switch (action.type) {
    case NAME:
      return {
        ...state,
        name: action.payload,
      };
    case DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case EXTERNAL_ID:
      return {
        ...state,
        externalId: action.payload,
      };

    case AUTHORITIES:
      const authChildrens = findChildren(state.allAuthorities, action.payload);
      const listIds = storeChildrensIdInArray(authChildrens);

      if (state.authorities.includes(action.payload)) {
        return {
          ...state,
          authorities: [...state.authorities.filter((id) => !listIds.includes(id))],
        };
      } else {
        return {
          ...state,
          authorities: [...state.authorities, ...listIds],
        };
      }
    case ALL_AUTHORITIES:
      return {
        ...state,
        allAuthorities: action.payload,
      };
    case UPDATE_STATE:
      return {
        ...state,
        authorities: action.payload.packageContent.map((val) => val.info.id),
        name: action.payload.name,
        description: action.payload.description,
        externalId: action.payload.externalId,
      };
    default:
      return {
        ...state,
      };
  }
};
