import React, { useState } from "react";
import { Button, Form, Modal, Segment } from "semantic-ui-react";

const ManagePackage = ({ resetModal, packages, loading, connectPackage, updatingPackages }) => {
  const [selected, setSelected] = useState(null);

  const onSubmit = async () => {
    if (selected) {
      connectPackage(selected);
    }
  };

  return (
    // Should use the shoudModal prop instead of conditionally render the managepackage
    <Modal open={true} onClose={() => resetModal()}>
      <Modal.Header>{"Add package"}</Modal.Header>
      <Modal.Content>
        <Segment basic clearing>
          <Form loading={loading} onSubmit={onSubmit} id="add-package">
            {packages && (
              <Form.Dropdown
                placeholder="Select a package"
                defaultValue={null}
                selection
                label="Packages"
                onChange={(e, { value }) => setSelected(value)}
                options={packages.map((schoolPackage) => {
                  return {
                    text: schoolPackage.name,
                    value: schoolPackage.id,
                    key: schoolPackage.id,
                  };
                })}
              />
            )}
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => resetModal()} disabled={updatingPackages}>
          Cancel
        </Button>
        <Button primary type="submit" form="add-package" loading={updatingPackages} disabled={updatingPackages}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ManagePackage;
