import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { Search } from "semantic-ui-react";
import escapeRegExp from "lodash/escapeRegExp";
import filter from "lodash/filter";
import debounce from "lodash/debounce";
import { useMemo } from "react";

const RESULTS = "RESULTS";
const VALUE = "VALUE";
const LOADING = "LOADING";
const SET_MULTIPLE = "SET_MULTIPLE";
const RESET_STATE = "RESET_STATE";
const INITIAL_STATE = {
  results: [],
  value: "",
  loading: false,
};

const searchReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESULTS:
      return {
        ...state,
        results: [...payload],
      };
    case VALUE:
      return {
        ...state,
        value: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_MULTIPLE:
      return {
        ...state,
        ...payload,
      };
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

// This is just so the search component works with the data we have
const createListOfSearchObjects = (members) => {
  return members.map((member) => {
    return {
      id: member.id,
      title: member.email,
    };
  });
};

const SearchBarStudents = ({ members, setSelectedStudent, setErrorMessage }) => {
  const [state, dispatch] = useReducer(searchReducer, INITIAL_STATE);
  const { results, value, loading } = state;
  const studentsList = useMemo(() => createListOfSearchObjects(members), [members]);

  const handleResultSelect = (e, { result }) => {
    dispatch({ type: VALUE, payload: result.title });
    setSelectedStudent({ id: result.id, email: result.title });
  };

  const handleSearchChange = (e, { value }) => {
    setErrorMessage(null);
    setSelectedStudent(null);
    dispatch({ type: SET_MULTIPLE, payload: { loading: true, value } });

    setTimeout(() => {
      if (value.length < 1) {
        dispatch({ type: RESET_STATE, payload: INITIAL_STATE });
      }
      const re = new RegExp(escapeRegExp(value), "i");
      const isMatch = (result) => re.test(result.title);

      dispatch({ type: LOADING, payload: false });
      dispatch({ type: RESULTS, payload: filter(studentsList, isMatch) });
    }, 300);
  };

  return (
    <Search
      input={{
        icon: "search",
        iconPosition: "left",
        style: { width: "100%" },
      }}
      className="autocomplete"
      results={results}
      fluid
      placeholder={"Search students by email"}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
      onResultSelect={handleResultSelect}
      loading={loading}
      value={value}
      noResultsMessage={"No students found"}
      autoFocus
    />
  );
};

SearchBarStudents.propTypes = {
  members: PropTypes.array.isRequired,
  setSelectedStudent: PropTypes.func,
};

export default SearchBarStudents;
