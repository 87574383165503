export const FREE_TEXT = "FREE_TEXT";
export const USER_ID = "USER_ID";
export const TYPE = "TYPE";
export const SCHOOL = "SCHOOL";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const EMAIL = "EMAIL";
export const USERNAME = "USERNAME";
export const ORGANIZATION = "ORGANIZATION";
export const ACCOUNT_NUMBER = "ACCOUNT_NUMBER";
export const SHCOOL_CODE = "SCHOOL_CODE";
export const COUNTRY = "COUNTRY";
export const ACCOUNT_STATUS = "ACCOUNT_STATUS";
export const MICROSOFT_ID = "MICROSOFT_ID";
export const DOMAIN = "DOMAIN";
export const SCIM_ID = "SCIM_ID";
export const MARKET = "MARKET";
export const CREATED_BEFORE = "CREATED_BEFORE";
export const CREATED_AFTER = "CREATED_AFTER";
export const SCHOOL_STATE = "SCHOOL_STATE";
export const STANDARD_SELECTION = "STANDARD_SELECTION";
