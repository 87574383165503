import React, { useCallback, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Header, Segment, Divider } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { TYPE, FIRST_NAME, LAST_NAME, EMAIL, USERNAME, COUNTRY } from "./UserConstants";
import { callUserService } from "../../hooks/useFetch";
import { useSelectedUserContext } from "context/SelectedUserContext";

const setInitialState = (user) => {
  return {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    type: user.userType || "",
    email: user.email || "",
    username: user.username || "",
    country: user.country || "",
  };
};

const editUserInformation = (state, action) => {
  switch (action.type) {
    case TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const UserEditInformation = () => {
  const { selectedUserInformation, fetchUserSelected } = useSelectedUserContext();
  const dispatchMessage = useMessage();
  const [loading, setLoading] = useState(false);
  const updateUser = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { response } = await callUserService(`/users/${selectedUserInformation.userId}`, {
          method: "PUT",
          body: JSON.stringify(values),
        });
        if (response?.message) {
          throw new Error(response.message);
        }
        await fetchUserSelected(selectedUserInformation.userId);
        dispatchMessage({
          title: "User updated",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: selectedUserInformation.userId,
        });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
          id: selectedUserInformation.userId,
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, fetchUserSelected, selectedUserInformation.userId]
  );

  const [state, dispatch] = useReducer(editUserInformation, setInitialState(selectedUserInformation));

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({ type: field, payload: value });
  };

  const handleSubmit = () => {
    updateUser({ userId: selectedUserInformation.userId, ...state });
  };

  const { username, firstName, lastName, email } = state || {};

  return (
    <>
      <Header as="h3" dividing>
        Edit information
      </Header>
      <Segment color="grey" inverted>
        <Form loading={loading} onSubmit={handleSubmit}>
          <Form.Input
            placeholder="First name"
            label="First name"
            value={firstName}
            onChange={handleFieldsUpdate(FIRST_NAME)}
          />
          <Form.Input
            placeholder="Last name"
            label="Last name"
            value={lastName}
            onChange={handleFieldsUpdate(LAST_NAME)}
          />
          <Form.Input
            placeholder="Email"
            label="Email"
            type="email"
            value={email}
            onChange={handleFieldsUpdate(EMAIL)}
            required
          />
          <Form.Input
            placeholder="Username"
            label="Username"
            value={username}
            onChange={handleFieldsUpdate(USERNAME)}
          />
          {/*<Form.Select*/}
          {/*    label='Country'*/}
          {/*    placeholder='Select country'*/}
          {/*    value={country}*/}
          {/*    onChange={handleFieldsUpdate(COUNTRY)}*/}
          {/*    options={*/}
          {/*        countriesData?.map(country => {*/}
          {/*            return {*/}
          {/*                text: country.name,*/}
          {/*                value: country.code,*/}
          {/*            }*/}
          {/*        }) || []*/}
          {/*    }*/}
          {/*/>*/}
          <Button as={Link} type="button" to="#">
            Cancel
          </Button>
          <Button primary type="submit">
            Submit
          </Button>
        </Form>
        <Divider />
      </Segment>
    </>
  );
};

export default UserEditInformation;
