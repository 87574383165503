import React, { useCallback, useContext, useState } from "react";
import { callUserService } from "../../hooks/useFetch";

export const SelectedSchoolContext = React.createContext({});

export function SelectedSchoolContextProvider({ children }) {
  // School Selected:
  const [schoolInformation, setSchoolInformation] = useState({});

  const fetchSchoolInformation = useCallback(async (schoolId) => {
    const { response } = await callUserService(`/school/${schoolId}`);
    setSchoolInformation(response);
  }, []);

  return (
    <SelectedSchoolContext.Provider
      value={{
        //
        schoolInformation,
        //
        fetchSchoolInformation,
      }}
    >
      {children}
    </SelectedSchoolContext.Provider>
  );
}
export const useSelectedSchoolContext = () => useContext(SelectedSchoolContext);
