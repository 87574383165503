import React from "react";
import { List, Form, Header } from "semantic-ui-react";

const TreeNode = ({ node, getChildNode, onToggle, onChange, selectedAuthorities }) => {
  const renderCaret = (node) => {
    if (!node.authorities) {
      return null;
    }
    return node.isOpen ? "caret down" : "caret right";
  };

  const renderIcon = (node) => {
    if (!node.authorities) {
      return "folder";
    }
    return node.isOpen ? "folder open" : "folder";
  };

  return (
    <List>
      <List.Item>
        <List.Icon name={renderCaret(node)} onClick={() => onToggle(node)} />
        <List.Icon name={renderIcon(node)} onClick={() => onToggle(node)} />
        <List.Content>
          {node?.authorities?.length > 0 ? (
            <Header as="h4">{node?.name}</Header>
          ) : (
            <Form.Checkbox
              label={node.name}
              value={node.info?.id}
              onChange={onChange}
              checked={selectedAuthorities.includes(node.info.id)}
            />
          )}
          {getChildNode(node) &&
            node.isOpen &&
            node?.authorities?.map((m) => (
              <TreeNode
                key={m.info.id}
                node={m}
                getChildNode={getChildNode}
                onToggle={onToggle}
                selectedAuthorities={selectedAuthorities}
                onChange={onChange}
              />
            ))}
        </List.Content>
      </List.Item>
    </List>
  );
};

export default TreeNode;
