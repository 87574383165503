import React, { useEffect, useState } from "react";
import { Button, Divider } from "semantic-ui-react";
import TreeNode from "./TreeNode";

const toggleFolders = (data, toggle = true) => {
  for (let val of data) {
    val.isOpen = toggle;
    if (val.authorities) {
      toggleFolders(val.authorities, toggle);
    }
  }

  return data;
};

const Tree = ({ data, selectedAuthorities, handleFieldsUpdate }) => {
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    setNodes(data);
  }, [data]);

  const getChildNode = (node) => {
    if (!node.authorities) return null;
    return node.authorities;
  };

  const onToggle = (node) => {
    const findNode = (stateNodes, node) => {
      for (let val of stateNodes) {
        if (val.info.id === node.info.id) {
          node.isOpen = !node.isOpen;
        }
        if (val.authorities) {
          findNode(val.authorities, node);
        }
      }
      return stateNodes;
    };

    const newNodes = findNode(nodes, node);
    setNodes([...newNodes]);
  };

  const onChange = (event, data) => {
    handleFieldsUpdate(event, data);
  };

  const openAllFolders = () => {
    setNodes([...toggleFolders(nodes, true)]);
  };

  const closeAllFolders = () => {
    setNodes([...toggleFolders(nodes, false)]);
  };

  return (
    <>
      <Button basic type="button" onClick={openAllFolders}>
        Open all folders
      </Button>
      <Button basic type="button" onClick={closeAllFolders}>
        Close all folders
      </Button>
      <Divider hidden className="small" />
      {nodes.map((node) => {
        return (
          <TreeNode
            node={node}
            getChildNode={getChildNode}
            onToggle={onToggle}
            selectedAuthorities={selectedAuthorities}
            onChange={onChange}
            key={node.name}
          />
        );
      })}
    </>
  );
};

export default Tree;
