import React, { useState } from "react";
import { Button, List, Dropdown } from "semantic-ui-react";

export const RolesList = ({
  name,
  permissionOptions,
  allPermissions,
  slug,
  disablePermission,
  displayDeleteButton,
  onDelete,
  id,
  displayEditButton = false,
  onSave,
}) => {
  const [selectedRolePermissions, setSelectedRolePermissions] = useState(permissionOptions.map((x) => x.id));
  const onChange = (_e, target) => {
    setSelectedRolePermissions(target.value);
  };
  const [localLoading, setLocalLoading] = useState(false);
  const [enabledEdit, setEnabledEdit] = useState(false);
  return (
    <List.Item style={{ paddingRight: "1em" }}>
      <List.Content verticalAlign="middle">
        <List.Header content={name} />
        <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Dropdown
            style={{ minWidth: "200px" }}
            placeholder="Select permissions"
            disabled={disablePermission || localLoading || !enabledEdit}
            fluid
            multiple
            search
            selection
            clearable
            options={allPermissions}
            value={selectedRolePermissions}
            onChange={onChange}
          />
          {displayEditButton && (
            <Button
              icon={localLoading ? "circle notch" : enabledEdit ? "save" : "pencil"}
              basic
              color={"blue"}
              size="tiny"
              floated="right"
              onClick={() => {
                enabledEdit && onSave(id, selectedRolePermissions, () => setLocalLoading(false));
                setEnabledEdit(!enabledEdit);
              }}
            />
          )}
          {displayDeleteButton && (
            <Button
              icon={localLoading ? "circle notch" : "trash"}
              basic
              color={localLoading ? "blue" : "red"}
              size="tiny"
              floated="right"
              onClick={() => {
                setLocalLoading(true);
                onDelete(id, () => setLocalLoading(false));
              }}
            />
          )}
        </div>
      </List.Content>
    </List.Item>
  );
};
