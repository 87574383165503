import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import StudentList from "components/StudentList";
import { useDisclosure } from "hooks/useDisclosure";

const AddStudent = ({ groupId, groupOwnerId, fetchMembers, refetch, schoolId }) => {
  const [isOpen, { open, close }] = useDisclosure();

  return (
    <>
      <Button role="add-student" basic primary size="tiny" onClick={open}>
        <Icon name="add" />
        Add student
      </Button>
      <StudentList
        isOpen={isOpen}
        closeModal={close}
        groupId={groupId}
        groupOwnerId={groupOwnerId}
        fetchMembers={fetchMembers}
        refetch={refetch}
        schoolId={schoolId}
      />
    </>
  );
};

AddStudent.propTypes = {
  groupId: PropTypes.number,
  groupOwnerId: PropTypes.number,
};

export default AddStudent;
