import { useMessageContext } from "./useMessageContext";
import { ADD_NOTIFICATION } from "../contexts/MessageContext/actions";

export const useMessage = () => {
  const { dispatch } = useMessageContext();

  return (props) => {
    dispatch({ type: ADD_NOTIFICATION, payload: { ...props } });
  };
};

export default useMessage;
