import React from "react";
import { Image, Header, Container, Icon } from "semantic-ui-react";

export const EdlinkSchool = ({ school }) => (
  <div>
    <div style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}>
      {!school?.picture_url ? (
        <div style={{ height: 150, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Icon name="university" size="huge" />
        </div>
      ) : (
        <Image src={school?.picture_url} size="small" centered fluid />
      )}
    </div>
    <Container textAlign="center" fluid>
      <Header size="large">{school?.name}</Header>
    </Container>
  </div>
);
