import React, { useState, useEffect } from "react";
import { Loader, Header, Segment, List } from "semantic-ui-react";
import { callUserService, callAuthServer } from "../../../../hooks/useFetch";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";
import { RolesList } from "./components/RolesList";
import { AddRoles } from "./components/AddRoles";

export const SchoolRoles = () => {
  const { schoolInformation } = useSelectedSchoolContext();

  const [loading, setLoading] = useState(false);
  const [schoolRoles, setSchoolRoles] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    const getSchoolRoles = async () => {
      if (!schoolInformation.id) return;
      try {
        setLoading(true);
        const { response } = await callUserService(`/school/${schoolInformation.id}/roles`);
        const permissionsResponse = await callAuthServer("/permissions");
        const permissionsFromSchool = await callUserService(`/school/${schoolInformation.id}/permissions`);
        setSchoolRoles(response);
        const authIdSet = new Set(permissionsFromSchool.response.map((x) => x.permissionAuthId));
        setAuthPermissions(permissionsResponse.data.filter((permission) => authIdSet.has(permission.id)));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getSchoolRoles();
  }, [schoolInformation.id]);

  if (loading)
    return (
      <div>
        <Loader active />
      </div>
    );

  const permissionOptions =
    schoolRoles.map((x) => {
      // get All permissions items.
      const items = x.permissions.flatMap((y) => y.allowPermission);
      // match all permissions items with school role

      const matchItemsWithRoles = items.reduce((prev, curr) => {
        //maybe just all ids, and filter
        const findPermission = authPermissions.find((z) => {
          return z.id === curr.permissionAuthId;
        });

        if (!findPermission) return prev;
        return [
          ...prev,
          {
            id: findPermission.id,
            text: findPermission.name.toUpperCase(),
            value: findPermission.id,
          },
        ];
      }, []);
      return { id: x.id, name: x.name, permissions: matchItemsWithRoles, slug: x.slug };
    }) || [];

  const allPermissions = authPermissions.map((x) => ({
    id: x.id,
    text: x.name,
    value: x.id,
  }));

  const onSubmit = async (data, callBack) => {
    try {
      await callUserService(`/school/${schoolInformation.id}/roles`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      callBack();
      setLoading(true);
      const { response } = await callUserService(`/school/${schoolInformation.id}/roles`);
      setSchoolRoles(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteSchoolUserRole = async (id, callBack) => {
    try {
      await callUserService(`/school/${schoolInformation.id}/roles/${id}`, {
        method: "DELETE",
      });
      callBack();
      setLoading(true);
      const { response } = await callUserService(`/school/${schoolInformation.id}/roles`);
      setSchoolRoles(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      callBack();
    }
  };

  const onSave = async (id, permissions, callBack) => {
    try {
      // console.log({id, permissions});return;
      await callUserService(`/school/${schoolInformation.id}/roles/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          //add name
          permissions,
        }),
      });
      callBack();
      setLoading(true);
      const { response } = await callUserService(`/school/${schoolInformation.id}/roles`);
      setSchoolRoles(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      callBack();
    }
  };

  return (
    <>
      <Header as="h3" dividing>
        School Roles
      </Header>
      {loading ? (
        <div>
          <Loader active />
        </div>
      ) : (
        <Segment>
          <AddRoles
            allPermissions={allPermissions}
            onAddRole={onSubmit}
            loading={loading}
            title="Create a Role to School"
          />
          <List relaxed className="striped">
            {permissionOptions.map((role) => (
              <RolesList
                key={role.id}
                slug={role.slug}
                name={role.name}
                permissionOptions={role.permissions}
                allPermissions={allPermissions}
                disablePermission={ROLES_SLUG.includes(role.slug?.toUpperCase())}
                displayDeleteButton={!ROLES_SLUG.includes(role.slug?.toUpperCase())}
                onDelete={deleteSchoolUserRole}
                id={role.id}
                displayEditButton={!ROLES_SLUG.includes(role.slug?.toUpperCase())}
                onSave={onSave}
              />
            ))}
          </List>
        </Segment>
      )}
    </>
  );
};

const ROLES_SLUG = ["SCHOOL_ADMIN", "STUDENT", "TEACHER"];
