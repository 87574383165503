import React from "react";
import { Dropdown, Grid, Loader } from "semantic-ui-react";

const UserPackageAccordionTitle = ({ userId, authPackage, loading, handleDisconnect }) => {
  // const onDelete = (e) => {
  //   e.stopPropagation();
  //   setOpen(true);
  // };

  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      <Grid stretched verticalAlign="middle" columns="equal" className="accordion-title-grid" style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width="8">
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width="1" className="no-padding">
                  {/* dummy */}
                </Grid.Column>
                <Grid.Column width="15">
                  <div className="group-name">{authPackage.name}</div>
                  <div className={`ui text normal tiny ${authPackage.packageContent.length > 0 ? "muted" : "red"}`}>
                    {authPackage.packageContent.length ? `${authPackage.packageContent.length} authorities` : null}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width="8">
            <Grid textAlign="right" verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={userId ? "11" : "13"} verticalAlign="middle">
                  {/* {hideDelete ? null : (
                    <Button
                      floated="right"
                      content="Delete"
                      basic
                      color="red"
                      onClick={(e) => onDelete(e, authPackage.id)}
                    />
                  )} */}
                </Grid.Column>
                {userId ? (
                  <Grid.Column width="2">
                    <Dropdown icon="ellipsis horizontal" pointing direction="left">
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleDisconnect(authPackage.id)}>
                          Disconnect package
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Column>
                ) : null}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UserPackageAccordionTitle;
