import { useCallback, useMemo, useState } from "react";

export function useDisclosure(initialIsOpen = false) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((p) => !p), []);

  const setters = useMemo(
    () => ({
      toggle,
      open,
      close,
    }),
    [close, open, toggle]
  );

  return [isOpen, setters];
}
