import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Form, Segment } from "semantic-ui-react";
import { createPackageReducer } from "./reducer";
import { NAME, DESCRIPTION, UPDATE_STATE, ALL_AUTHORITIES, AUTHORITIES, EXTERNAL_ID } from "./actions";
import Tree from "components/Tree/Tree";
import { useMessage } from "../../ne-ui";
import { callUserService } from "../../hooks/useFetch";

const PackageForm = ({ allAuthorities }) => {
  const dispatchMessage = useMessage();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [fetchingAuthorityPackage, setFetchingAuthorityPackage] = useState(false);

  const createPackage = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await callUserService("/authority-package", {
          method: "POST",
          body: JSON.stringify(values),
        });
        dispatchMessage({
          title: "Package updated",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        history.replace("/authorities#packages");
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, history]
  );

  const updatePackage = useCallback(
    async (values) => {
      setUpdating(true);
      try {
        await callUserService(`/authority-package/${values.id}`, {
          method: "PATCH",
          body: JSON.stringify(values),
        });
        dispatchMessage({
          title: "Package updated",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        history.replace("/authorities#packages");
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setUpdating(false);
      }
    },
    [dispatchMessage, history]
  );
  const authorityPackage = useCallback(async (id) => {
    setFetchingAuthorityPackage(true);
    const { response } = await callUserService(`/authority-package/${id}`, { method: "GET" });
    dispatch({
      type: UPDATE_STATE,
      payload: response,
    });
    setFetchingAuthorityPackage(false);
  }, []);

  const [state, dispatch] = useReducer(createPackageReducer, {
    name: "",
    description: "",
    authorities: [],
    allAuthorities: [],
    externalId: "",
  });

  useEffect(() => {
    if (id) {
      authorityPackage(id);
    }
  }, [id, authorityPackage]);

  useEffect(() => {
    dispatch({
      type: ALL_AUTHORITIES,
      payload: allAuthorities,
    });
  }, [allAuthorities]);

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  const handleSubmit = () => {
    if (id) {
      updatePackage({ ...state, id });
    } else {
      createPackage({
        ...state,
      });
    }
  };

  return (
    <Segment>
      <Form id="create-package" onSubmit={handleSubmit} loading={loading || fetchingAuthorityPackage}>
        <Form.Input label="Name" placeholder="name" onChange={handleFieldsUpdate(NAME)} value={state.name} />
        <Form.Input
          label="Description"
          placeholder="description"
          onChange={handleFieldsUpdate(DESCRIPTION)}
          value={state.description}
        />
        <Form.Input
          label="External Id (Stripe)"
          placeholder="External Id"
          onChange={handleFieldsUpdate(EXTERNAL_ID)}
          value={state.externalId}
          required
        />
        <Divider className="small" hidden />
        <Tree
          data={allAuthorities}
          selectedAuthorities={state.authorities}
          handleFieldsUpdate={handleFieldsUpdate(AUTHORITIES)}
        />
        <Divider />
        <Form.Button type="submit" content="Submit" primary loading={updating} />
      </Form>
    </Segment>
  );
};

export default PackageForm;
