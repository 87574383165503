import {
  FREE_TEXT,
  USERNAME,
  USER_ID,
  TYPE,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  CREATED_AFTER,
  CREATED_BEFORE,
  SCHOOL,
  SHCOOL_CODE,
} from "components/User/UserConstants";
import moment from "moment";

const PAGE = "PAGE";
export const CLEAR_FIELDS = "CLEAR_FIELDS";
export const SEARCH_HISTORY = "SEARCH_HISTORY";

export const initialState = {
  userId: "",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  type: "",
  createdAfter: "",
  createdBefore: "",
  createdAfterInput: "",
  createdBeforeInput: "",
  schoolId: "",
  schoolCode: "",
  schoolCounter: 1, // this is to force the complete render in the school form when clear field is clicked
};

export const searchReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case SEARCH_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    case USERNAME:
      return {
        ...state,
        username: payload,
      };
    case USER_ID:
      return {
        ...state,
        userId: parseInt(payload),
      };
    case FREE_TEXT:
      return {
        ...state,
        freeText: payload,
      };
    case FIRST_NAME:
      return {
        ...state,
        firstName: payload,
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: payload,
      };
    case EMAIL:
      return {
        ...state,
        email: payload,
      };
    case TYPE:
      return {
        ...state,
        type: payload,
      };
    case PAGE: {
      return {
        ...state,
        page: payload,
      };
    }
    case CREATED_AFTER:
      return {
        ...state,
        createdAfter: payload ? moment(payload).format("YYYY-MM-DD") : "",
        createdAfterInput: payload ? moment(payload).format("YYYY-MM-DD") : "",
      };
    case CREATED_BEFORE:
      return {
        ...state,
        createdBefore: payload ? moment(payload).format("YYYY-MM-DD") : "",
        createdBeforeInput: payload ? moment(payload).format("YYYY-MM-DD") : "",
      };
    case SCHOOL:
      return {
        ...state,
        schoolId: payload,
      };
    case SHCOOL_CODE:
      return {
        ...state,
        schoolCode: payload,
      };
    case CLEAR_FIELDS:
      return {
        initialState,
        schoolCounter: state.schoolCounter + 1,
      };
    default:
      throw new Error("Could not search");
  }
};
