import React, { useState, useEffect } from "react";
import { Icon, Loader, Header, Segment, List, Form, Button } from "semantic-ui-react";
import { callUserService, callAuthServer } from "hooks/useFetch";
import { useSelectedUserContext } from "context/SelectedUserContext";
import { RolesList } from "routes/School/components/Tabs/components/RolesList";

export const UserRoles = () => {
  const { selectedSchool, selectedUserInformation } = useSelectedUserContext();

  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [selectedRolesValues, setSelectedRolesValues] = useState(null);
  const [addingRoleLoading, setAddingRoleLoading] = useState(false);
  const [schoolRoles, setSchoolRoles] = useState([]);

  useEffect(() => {
    const getSchoolRoles = async () => {
      if (!selectedSchool.schoolUserId) return;
      try {
        setLoading(true);

        const { response: schoolRolesRaw } = await callUserService(`/school/${selectedSchool.id}/roles`);
        const { response } = await callUserService(`/school/user-roles?schoolUserId=${selectedSchool.schoolUserId}`);
        const permissionsResponse = await callAuthServer("/permissions");
        setUserRoles(response);
        setSchoolRoles(schoolRolesRaw);
        setAuthPermissions(permissionsResponse.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getSchoolRoles();
  }, [selectedSchool.schoolUserId]);

  if (loading)
    return (
      <div>
        <Loader active />
      </div>
    );

  const permissionOptions =
    userRoles.map((x) => {
      // get All permissions items.
      const items = x.schoolRole.permissions.flatMap((x) => x.allowPermission);
      // match all permissions items with school role
      const matchItemsWithRoles = items.reduce((prev, curr) => {
        //maybe just all ids, and filter
        const findPermission = authPermissions.find((z) => {
          return z.id === curr.permissionAuthId;
        });

        if (!findPermission) return prev;
        return [
          ...prev,
          {
            id: findPermission.id,
            text: findPermission.name.toUpperCase(),
            value: findPermission.id,
          },
        ];
      }, []);
      return { id: x.id, name: x.schoolRole.name, permissions: matchItemsWithRoles, slug: x.slug };
    }) || [];

  const allPermissions = authPermissions.map((x) => ({
    id: x.id,
    text: x.name,
    value: x.id,
  }));
  const onSubmit = async (e) => {
    try {
      setDisplayDropdown(false);
      setAddingRoleLoading(true);
      await callUserService(`/school/${selectedSchool.id}/user/${selectedUserInformation.userId}/add-role`, {
        method: "POST",
        body: JSON.stringify({
          schoolUserId: selectedSchool.schoolUserId,
          schoolRolId: selectedRolesValues,
        }),
      });
      const { response } = await callUserService(`/school/user-roles?schoolUserId=${selectedSchool.schoolUserId}`);
      setUserRoles(response);
    } catch (e) {
      console.log(e);
    } finally {
      setAddingRoleLoading(false);
    }
  };

  const deleteSchoolUserRole = async (id, callBack) => {
    try {
      await callUserService(`/school/${selectedSchool.id}/user/${selectedUserInformation.userId}/delete-role/${id}`, {
        method: "DELETE",
      });
      const { response } = await callUserService(`/school/user-roles?schoolUserId=${selectedSchool.schoolUserId}`);
      setUserRoles(response);
    } catch (e) {
      console.log(e);
    } finally {
      callBack();
    }
  };
  return (
    <>
      <Header as="h3" dividing>
        User Roles
      </Header>
      {loading ? (
        <div>
          <Loader active />
        </div>
      ) : (
        <Segment>
          {!displayDropdown ? (
            <Button
              role="add-student"
              loading={addingRoleLoading}
              basic
              primary
              size="tiny"
              onClick={() => setDisplayDropdown(true)}
            >
              <Icon name="add" />
              Add Role
            </Button>
          ) : (
            <Form id="create-role" onSubmit={onSubmit} loading={addingRoleLoading || loading}>
              <Form.Dropdown
                style={{ minWidth: "200px" }}
                disabled={!schoolRoles.length}
                required
                selection
                value={selectedRolesValues}
                label={"Select a role"}
                options={schoolRoles.map((x) => ({
                  id: x.id,
                  text: x.name,
                  value: x.id,
                }))}
                onChange={(e, data) => {
                  data.value && setSelectedRolesValues(data.value);
                }}
              />
              <Button.Group className="right">
                <Button icon="save" basic size="tiny" type="submit" />
                <Button
                  icon="cancel"
                  basic
                  color="red"
                  size="tiny"
                  onClick={() => {
                    setSelectedRolesValues([]);
                    setDisplayDropdown(false);
                  }}
                />
              </Button.Group>
            </Form>
          )}

          <List relaxed className="striped">
            {permissionOptions.map((role) => (
              <RolesList
                key={role.id}
                name={role.name}
                permissionOptions={role.permissions}
                allPermissions={allPermissions}
                disablePermission
                onDelete={deleteSchoolUserRole}
                displayDeleteButton
                id={role.id}
              />
            ))}
          </List>
        </Segment>
      )}
    </>
  );
};
