import React, { useReducer, useState, useContext, useEffect, useCallback } from "react";
import { Button, Loader } from "semantic-ui-react";
import { searchReducer, initialState, SEARCH_HISTORY } from "./reducer";
import { SearchContext } from "context/SearchProvider";
import SearchResult from "./SearchResult";
import SearchForm from "./SearchForm";
import { serialize } from "../../utils/query";
import { callUserService } from "../../hooks/useFetch";

const Search = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const { searchHistory, setSearchHistory } = useContext(SearchContext);
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { createdAfterInput, createdBeforeInput, ...rest } = state;
  const filterEmpty = Object.keys(rest)
    .filter((k) => !!rest[k])
    .reduce((a, k) => ({ ...a, [k]: rest[k] }), {});

  const findUsers = useCallback(async (filter) => {
    setLoading(true);
    const query = serialize(filter);
    const { response } = await callUserService(`/users/find-users?${query}`);
    setData({ findUsers: response });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!Object.keys(searchHistory).length) return;

    dispatch({
      type: SEARCH_HISTORY,
      payload: searchHistory,
    });

    findUsers({ ...searchHistory, page: 1, limit });

    setSearchHistory({});
  }, [searchHistory, findUsers, setSearchHistory, limit]);

  const saveRecent = () => {
    if (Object.keys(filterEmpty).length) setSearchHistory(filterEmpty);
  };

  const goToNextPage = () => {
    const {
      findUsers: { pageInfo },
    } = data;
    if (pageInfo.hasNext) {
      findUsers({ ...filterEmpty, page: currentPage + 1, limit });
      setCurrentPage((page) => page + 1);
    }
  };

  const goToPrevPage = () => {
    const {
      findUsers: { pageInfo },
    } = data;

    if (pageInfo.hasPrevious) {
      findUsers({ ...filterEmpty, page: currentPage - 1, limit });
      setCurrentPage((page) => page - 1);
    }
  };

  const onSubmit = () => {
    setCurrentPage(1);
    findUsers({ ...filterEmpty, page: currentPage, limit });
  };

  const changeLimit = (event, data) => {
    const { value } = data;
    setLimit(value);

    findUsers({ ...filterEmpty, page: currentPage, limit: value });
  };

  return (
    <>
      <SearchForm
        state={state}
        closed={Object.keys(searchHistory).length ? 1 : 0}
        onSubmit={onSubmit}
        dispatch={dispatch}
        findUsers={findUsers}
        loading={loading}
      />
      {loading ? <Loader active>Loading users</Loader> : null}
      {data?.findUsers?.content ? (
        <>
          <SearchResult
            page={currentPage}
            saveRecent={saveRecent}
            entries={data?.findUsers?.pageInfo?.totalElements}
            result={data?.findUsers?.content}
            changeLimit={changeLimit}
            limit={limit}
          />
          <Button
            content="Prev page"
            onClick={goToPrevPage}
            disabled={!data?.findUsers?.pageInfo.hasPrevious || loading}
          />
          <Button
            content="Next page"
            primary
            onClick={goToNextPage}
            disabled={!data?.findUsers?.pageInfo.hasNext || loading}
          />
        </>
      ) : null}
    </>
  );
};

export default Search;
