import React from "react";
import { Button, Divider, List } from "semantic-ui-react";
const AuthorityAccordionContent = ({ node, onEditButtonClicked, hasSystemPermission }) => (
  <>
    {node.authorities.length > 0 ? (
      <React.Fragment>
        <Divider />
        <List relaxed className="striped">
          {node.authorities.map((authority) => (
            <List.Item key={authority.info.id} style={{ paddingRight: "1em", display: "flex", alignItems: "center" }}>
              <List.Content style={{ flex: 1 }}>
                <List.Header content={authority.name} />
              </List.Content>
              <div>
                <span style={{ marginRight: "10px" }}>{authority.info.id}</span>
                <Button
                  disabled={authority.authorityKind === "SYSTEM_OBJECT_AUTHORITY" && !hasSystemPermission}
                  style={{ padding: ".5em 1em .5em" }}
                  basic
                  onClick={onEditButtonClicked(authority)}
                >
                  Edit
                </Button>
              </div>
            </List.Item>
          ))}
        </List>
      </React.Fragment>
    ) : null}
  </>
);

export default AuthorityAccordionContent;
