import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";

export const SchoolMenu = ({ handleMenuChange }) => {
  const { hasEdlinkRosteringPermission, hasWondeRosteringPermission } = useAuthContext();

  return (
    <Menu pointing secondary inverted>
      <Dropdown item text="Profile">
        <Dropdown.Menu>
          <Dropdown.Item data-hash="information" onClick={handleMenuChange}>
            Edit information
          </Dropdown.Item>
          <Dropdown.Item data-hash="about" onClick={handleMenuChange}>
            Changelog
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="Users">
        <Dropdown.Menu>
          <Dropdown.Item data-hash="add-children" onClick={handleMenuChange}>
            Add users
          </Dropdown.Item>
          <Dropdown.Item data-hash="children" onClick={handleMenuChange}>
            Manage user roles
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="Education">
        <Dropdown.Menu>
          <Dropdown.Item data-hash="education-groups" onClick={handleMenuChange}>
            Classrooms
          </Dropdown.Item>
          <Dropdown.Item data-hash="education-teachers" onClick={handleMenuChange}>
            Teachers
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Menu.Item name="Authorities" data-hash="authorities" onClick={handleMenuChange} />
      <Menu.Item name="Packages" data-hash="packages" onClick={handleMenuChange} />
      {/*<Menu.Item name="School code" data-hash="schoolcode" onClick={handleMenuChange} />*/}

      <Menu.Item name="Roles" data-hash="school-roles" onClick={handleMenuChange} />

      {/*<Menu.Item name="LP System" data-hash="lp-system" onClick={handleMenuChange} />*/}
      {process.env.REACT_APP_REGION === "uk" ? (
        <Menu.Item name="Rostering" data-hash="school-wonde" onClick={handleMenuChange} />
      ) : (
        <Menu.Item name="Rostering" data-hash="school-edlink" onClick={handleMenuChange} />
      )}
    </Menu>
  );
};
