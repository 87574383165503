import React, { useState, useRef } from "react";
import { Button, Checkbox, Message, Modal, Icon, Grid, Divider, Header } from "semantic-ui-react";
import importUsers from "api/import";
import { uploadInstructions } from "./uploadInstructions";
import { getCookieValue } from "utils";

const types = ["teachers", "students", "organisation", "library"];

const ImportUsers = ({ refetch, userId, type, open, setOpen, userType, groupId }) => {
  const fileInputRef = useRef(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [importedUsers, setImportedUsers] = useState(null);
  const [postError, setPostError] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(null);

  if (types.indexOf(type) < 0) {
    console.error("No type set (teachers or students");

    return null;
  }

  const upload = async (file) => {
    const formdata = new FormData();
    formdata.append("file", file);

    setUploadLoading(true);
    setImportedUsers(null);
    setPostError(null);

    try {
      const access_token = getCookieValue("access_token");
      const url = userType
        ? `ENV_REACT_APP_IDM_API_URL/upload/${userId}/${groupId}`
        : `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/upload/${userId}/${type}`;
      const postCSV = await fetch(url, {
        headers: { Authorization: `Bearer ${access_token}` },
        method: "POST",
        body: formdata,
      });

      if (!postCSV.ok) throw new Error();

      const data = await postCSV.json();

      setImportedUsers(data);
    } catch (err) {
      setPostError("Failed to upload file");
    } finally {
      setUploadLoading(false);
    }
  };

  const onImport = () => {
    setImportLoading(true);
    const importPromise = importUsers(importedUsers.importRecords, userId, type, sendEmail, userType, groupId);

    importPromise
      .then(() => {
        refetch();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        setPostError("Failed to import users");
      })
      .finally(() => {
        setImportedUsers(null);
        setImportLoading(false);
      });
  };

  const closeModal = () => {
    setPostError(null);
    setImportedUsers(null);
    setOpen(false);
  };

  const onChange = (event) => {
    const file = event?.target?.files[0];
    if (file) upload(file);
  };

  const disabledImport = !importedUsers || importedUsers?.hasErrors;
  const filename = fileInputRef?.current?.files[0]?.name;
  const errors = importedUsers?.importRecords.filter((f) => Boolean(f.error));
  const downloadUrl =
    type === "organisation" || type === "library"
      ? `${process.env.PUBLIC_URL}/xls/${userType}.xlsx`
      : `${process.env.PUBLIC_URL}/xls/${type}.xlsx`;

  return (
    <>
      <Modal open={open} closeIcon onClose={closeModal}>
        <Header>Import {type}</Header>
        <Modal.Content>
          <Message>
            <Message.Content>{uploadInstructions[type].header}</Message.Content>
            <Message.Content>
              <Divider hidden className="mini" />
              <div>
                <a download href={downloadUrl} alt="download xls">
                  <Icon name="file"></Icon> Example file
                </a>
              </div>
            </Message.Content>
          </Message>
          <Divider hidden className="mini" />
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column stretched={false} width="4">
                <Button
                  content={"Select file"}
                  loading={uploadLoading}
                  labelPosition="left"
                  fluid
                  icon="file"
                  size="mini"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                />
                <input
                  accept=".xlsx, .xls, .csv"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  className="hidden"
                  onChange={onChange}
                  type="file"
                ></input>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden className="micro" />
          {importedUsers && importedUsers.hasErrors === false && (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Icon color="green" name="checkmark"></Icon>
                  {filename}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {importedUsers?.hasErrors && (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Icon color="red" name="warning"></Icon> {filename}
                  <Message negative>
                    <Message.Header>{importedUsers.message}</Message.Header>
                    <Divider hidden className="micro" />
                    <Message.Content>
                      <Message.List>
                        {errors.map((m) => (
                          <Message.Item>
                            Line {m.lineNumber}: {m.error}
                          </Message.Item>
                        ))}
                      </Message.List>
                    </Message.Content>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {postError && (
            <Grid.Row>
              <Grid.Column>
                <Message negative>
                  <Message.Header>Error</Message.Header>
                  <Message.Content>{postError}</Message.Content>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          <Divider hidden className="micro" />
          {importedUsers?.hasErrors === false && (
            <Checkbox
              disabled={disabledImport}
              onChange={() => setSendEmail(!sendEmail)}
              label={`Notify new ${type === "teachers" ? "administrators" : "students"} on email`}
            ></Checkbox>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={closeModal}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            loading={importLoading}
            color="green"
            disabled={disabledImport || importLoading}
            onClick={onImport}
            positive
          >
            <Icon name="checkmark" /> Import
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ImportUsers;
