import React, { useCallback, useState } from "react";
import { Button, Form, Header, Segment, Divider } from "semantic-ui-react";
import Changelog from "components/Changelog";
import { PASSWORD_CATEGORY } from "../historyCategories";
import { callAuthServer } from "../../../hooks/useFetch";
import { useMessage } from "../../../ne-ui";

const UserPassword = ({ userId, userEmail }) => {
  const dispatchMessage = useMessage();
  const [loading, setLoading] = useState(false);
  const [changeLogKey, setChangeLogKey] = useState(0);

  const sendResetPasswordEmail = useCallback(async () => {
    setLoading(true);
    try {
      await callAuthServer(`/${userEmail}/execute-actions-email`, {
        method: "POST",
        body: { action: "UPDATE_PASSWORD" },
      });
      dispatchMessage({
        title: "Reset password email sent",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      setChangeLogKey((prev) => prev + 1);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
    } finally {
      setLoading(false);
    }
  }, [dispatchMessage, userEmail]);

  const submit = () => {
    sendResetPasswordEmail();
  };
  return (
    <>
      <Segment inverted color="grey" clearing>
        <Header as="h3" dividing>
          Send Reset Password Email
        </Header>
        <Divider hidden className="medium" />

        <Form onSubmit={submit}>
          <Form.Input value={userEmail} onChange={() => {}} required label="Email" />
          <Button disabled={loading} loading={loading} type="submit" color="blue">
            Submit
          </Button>
        </Form>
      </Segment>
      <Changelog key={changeLogKey} userId={userId} category={PASSWORD_CATEGORY} />
    </>
  );
};

export default UserPassword;
