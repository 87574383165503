import React, { useCallback, useEffect, useState } from "react";
import { Confirm, Loader } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import PackageAccordion from "components/PackageAccordion";
import { callUserService } from "../../hooks/useFetch";

const Packages = () => {
  const dispatchMessage = useMessage();
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deletingAuthorityPackage, setDeletingAuthorityPackage] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAuthorityPackages = useCallback(async () => {
    setLoading(true);
    const { response } = await callUserService("/authority-package", { method: "GET" });
    setData(response);
    setLoading(false);
  }, []);

  const deleteAuthorityPackage = useCallback(
    async (id) => {
      setDeletingAuthorityPackage(true);
      try {
        await callUserService(`/authority-package/${id}`, { method: "DELETE" });
        await fetchAuthorityPackages();
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
          id: "error package",
        });
      } finally {
        setDeletingAuthorityPackage(false);
      }
    },
    [dispatchMessage, fetchAuthorityPackages]
  );

  useEffect(() => {
    fetchAuthorityPackages();
  }, [fetchAuthorityPackages]);

  if (loading || deletingAuthorityPackage) {
    return <Loader active />;
  }

  const onConfirm = async () => {
    await deleteAuthorityPackage(selectedId);
    setOpen(false);
  };

  return (
    <>
      <Confirm open={open} onCancel={() => setOpen(!open)} onConfirm={onConfirm} />
      {data?.map((authPackage) => (
        <PackageAccordion authPackage={authPackage} setOpen={setOpen} setSelectedId={setSelectedId} />
      ))}
    </>
  );
};

export default Packages;
