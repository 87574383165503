import React, { useState, useCallback } from "react";
import { Button, List, Input, Dropdown, Confirm, Segment, Loader, Dimmer } from "semantic-ui-react";
import { callAuthServer } from "hooks/useFetch";
import { useMessage } from "ne-ui";

const RolePermissionRow = ({ role, getRolesPermissions, allPermissions, editDisabled, deleteDisabled }) => {
  const defaultRolePermissions = role.rolePermission?.map((rp) => rp.permission.id);
  const [selectedRolePermissions, setSelectedRolePermissions] = useState(defaultRolePermissions);
  const dispatchMessage = useMessage();
  const [permissionsChanged, setPermissionsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [updatedRoleName, setUpdatedRoleName] = useState(role.name);
  const [isEditingRole, setIsEditingRole] = useState(false);

  const deleteRole = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await callAuthServer(`/roles/${role?.id}`, {
        method: "DELETE",
      });
      if (data?.statusCode === 500) {
        throw new Error(data.message);
      }
      await getRolesPermissions();
      dispatchMessage({
        title: "Role deleted",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      setConfirmOpen(false);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
    } finally {
      setLoading(false);
    }
  }, [dispatchMessage, getRolesPermissions, role.id]);

  const saveEditedRole = async () => {
    try {
      if (!updatedRoleName) throw new Error("Please do not leave role name empty");
      if (role.name !== updatedRoleName) {
        const { data: updateRoleResponse } = await callAuthServer(
          `/roles/${role.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: { name: updatedRoleName },
          },
          true
        );
        if (updateRoleResponse.message) {
          throw new Error(updateRoleResponse.message);
        }
      }

      if (permissionsChanged) {
        const { data: updatePermissionResponse } = await callAuthServer(
          `/roles/${role.id}/add-role-permission`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: { permissionIds: selectedRolePermissions },
          },
          true
        );
        if (updatePermissionResponse.message) {
          throw new Error(updatePermissionResponse.message);
        }
        setPermissionsChanged(false);
      }

      setIsEditingRole(false);
      await getRolesPermissions();
      dispatchMessage({
        title: "Role updated",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
      });
      console.error(error);
    }
  };

  const handleEditButton = () => {
    setIsEditingRole(true);
  };

  const editRoleName = (event) => {
    setUpdatedRoleName(event.target.value);
  };

  const permissionOptions = allPermissions.map((permission) => ({
    key: permission.id,
    value: permission.id,
    text: permission.name,
  }));

  const setPermissionIds = (event, data) => {
    setSelectedRolePermissions(data.value);
    setPermissionsChanged(true);
  };

  const generateButtons = ({ editDisabled, deleteDisabled }) => {
    if (isEditingRole) {
      return [
        {
          icon: "save",
          basic: true,
          size: "tiny",
          floated: "right",
          onClick: saveEditedRole,
        },
        {
          icon: "cancel",
          basic: true,
          size: "tiny",
          color: "red",
          floated: "right",
          onClick: handleCancelButton,
        },
      ];
    } else {
      return [
        {
          icon: "pencil",
          basic: true,
          size: "tiny",
          floated: "right",
          disabled: editDisabled,
          onClick: handleEditButton,
        },
        {
          basic: true,
          icon: "trash",
          size: "tiny",
          color: "red",
          floated: "right",
          onClick: onRequestDeleteRole,
          disabled: deleteDisabled || isEditingRole,
        },
      ];
    }
  };

  const handleCancelButton = () => {
    setIsEditingRole(false);
    setPermissionsChanged(false);
    setSelectedRolePermissions(defaultRolePermissions);
  };
  const onRequestDeleteRole = () => {
    setConfirmOpen(true);
  };
  const onCancelDeleteRole = () => {
    setConfirmOpen(false);
  };
  return (
    <>
      <Confirm
        open={confirmOpen}
        onCancel={onCancelDeleteRole}
        onConfirm={deleteRole}
        confirmButton="Delete"
        content={
          <>
            <Dimmer active={loading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>{`Are you sure do you want to delete Role ${role?.name}? `}</Segment>
          </>
        }
      />
      <List.Item style={{ paddingRight: "1em" }}>
        <List.Content floated="left" verticalAlign="middle">
          {isEditingRole ? (
            <>
              <List.Header content="Edit role name:" />
              <Input type="text" value={updatedRoleName} onChange={editRoleName} />
            </>
          ) : (
            <List.Header content={role.name} />
          )}
          <div style={{ marginTop: "10px" }}>
            <Dropdown
              style={{ minWidth: "200px" }}
              placeholder="Select permissions"
              disabled={!isEditingRole}
              fluid
              multiple
              search
              selection
              options={permissionOptions}
              value={selectedRolePermissions}
              onChange={setPermissionIds}
            />
          </div>
        </List.Content>
        <Button.Group className="right floated">
          {generateButtons({ editDisabled, deleteDisabled }).map((button, i) => (
            <Button key={i} {...button} />
          ))}
        </Button.Group>
      </List.Item>
    </>
  );
};

export default RolePermissionRow;
