import React, { useCallback, useEffect, useRef, useState } from "react";
import { callUserService } from "../../../../hooks/useFetch";
import { Button, Divider, Grid, Header, Icon, Loader, Pagination } from "semantic-ui-react";
import AddTeacher from "../../../../components/User/AddTeacher";
import ImportUsers from "../../../../components/User/Import/ImportUsers";
import TeacherTable from "../../../../components/TeacherTable";

export const EducationTeachers = ({ schoolId }) => {
  const [open, setOpen] = useState(false);
  const [add, toggleAdd] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingGroupMembers, setLoadingGroupMembers] = useState(false);
  const [groupMembers, setGroupMembers] = useState(null);

  const fetchMembers = useCallback(
    async (groupId) => {
      setLoadingGroupMembers(true);
      const { response } = await callUserService(`/school/${schoolId}/user/teacher?page=${page}&limit=50`);
      setGroupMembers({ group: response });
      setLoadingGroupMembers(false);
    },
    [page]
  );

  useEffect(() => {
    fetchMembers(schoolId);
  }, [fetchMembers, page, schoolId]);

  if (loadingGroupMembers) return <Loader active></Loader>;

  const changePage = (event, data) => {
    const { activePage } = data;
    setPage(activePage);
  };

  const hasNext = groupMembers?.group?.pageInfo?.hasNext;
  const hasPrev = groupMembers?.group?.pageInfo?.hasPrevious;
  const totalPages = groupMembers?.group?.pageInfo?.totalPages;

  const refetch = async () => {
    await fetchMembers(schoolId);
  };

  return (
    <>
      <Header as="h3" dividing>
        Manage teachers
      </Header>
      <div>
        <Button active={open} onClick={() => setOpen(true)}>
          Import
        </Button>
        <Button active={add} onClick={() => toggleAdd(!add)}>
          Add
        </Button>
      </div>
      {add && (
        <>
          <Divider className="small" />
          <AddTeacher refetch={refetch} toggleShow={toggleAdd} ownerId={schoolId} show={add} />
        </>
      )}
      <ImportUsers open={open} setOpen={setOpen} refetch={refetch} type="teachers" userId={schoolId} />
      <Divider hidden className="small" />

      {groupMembers?.group?.content.length > 0 && (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Pagination
                floated="right"
                defaultActivePage={page}
                totalPages={totalPages}
                onPageChange={changePage}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name="angle left" />,
                  icon: true,
                  disabled: !hasPrev,
                }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: !hasNext,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <TeacherTable
        ownerId={schoolId}
        teachers={groupMembers?.group?.content}
        paging={{ page, limit: 25 }}
        fetchMembers={fetchMembers}
      />
    </>
  );
};
