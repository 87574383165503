import React, { useState, useEffect, useCallback } from "react";
import { Form, Pagination, Divider, Container, Header, Icon, Grid, Dropdown } from "semantic-ui-react";
import { limitOptions } from "utils/limitOptions";
import ChildrenTable from "components/ChildrenTable";
import { callUserService } from "../../../../hooks/useFetch";

export const Children = ({ schoolId }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectRoles, setSelectRoles] = useState(null);
  const [groupsData, setGroupsData] = useState(null);
  const [groupLoading, setGroupLoading] = useState(false);
  const [schoolRolesLoading, setSchoolRolesLoading] = useState(true);
  const [schoolRoleId, setSchoolRoleId] = useState();

  const fetchSchoolRoles = useCallback(async () => {
    setSchoolRolesLoading(true);
    const { response } = await callUserService(`/school/${schoolId}/roles`);
    setSelectRoles(response);
    setSchoolRolesLoading(false);
  }, [schoolId]);

  useEffect(() => {
    fetchSchoolRoles();
  }, [fetchSchoolRoles]);

  const getMembers = useCallback(async ({ schoolRoleId, page, limit }) => {
    setGroupLoading(true);
    const { response } = await callUserService(`/school/${schoolId}/users/${schoolRoleId}?page=${page}&limit=${limit}`);
    setGroupsData({ group: response });
    setGroupLoading(false);
  }, []);

  useEffect(() => {
    if (!schoolRoleId) return;

    getMembers({ schoolRoleId, page, limit });
  }, [schoolRoleId, getMembers, page, limit]);

  const onSchoolRoleSelected = (id) => {
    setSchoolRoleId(id);
  };

  const options =
    selectRoles?.map((m) => ({
      key: m.name,
      text: m.name,
      value: m.id,
    })) || [];

  const changePage = (event, data) => {
    const { activePage } = data;
    setPage(activePage);
  };

  const onLimitChange = (_, { value }) => {
    setPage(1);
    setLimit(value);
  };

  const members = groupsData?.group?.content;
  const hasNext = groupsData?.group?.pageInfo?.hasNext;
  const hasPrev = groupsData?.group?.pageInfo?.hasPrevious;
  const totalPages = groupsData?.group?.pageInfo?.totalPages;

  return (
    <Container>
      <Header as="h3" dividing>
        Manage roles
      </Header>
      <Form>
        <Form.Select
          label="Select role"
          width="4"
          value={schoolRoleId}
          loading={schoolRolesLoading}
          options={options}
          placeholder="Select role"
          onChange={(_, event) => onSchoolRoleSelected(event.value)}
        ></Form.Select>
      </Form>

      <Divider hidden className="small" />

      {!!members?.length && (
        <Grid>
          <Grid.Row relaxed>
            <Grid.Column floated="left" width="7">
              <Pagination
                defaultActivePage={page}
                totalPages={totalPages}
                onPageChange={changePage}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name="angle left" />,
                  icon: true,
                  disabled: !hasPrev,
                }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: !hasNext,
                }}
              />
            </Grid.Column>
            <Grid.Column width="5"></Grid.Column>
            <Grid.Column textAlign="right" width="3">
              <Dropdown compact selection options={limitOptions} onChange={onLimitChange} value={limit} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <ChildrenTable
        schoolRoleId={schoolRoleId}
        readOnly={false}
        refetchGroups={() => getMembers({ schoolRoleId, page, limit })}
        loading={groupLoading}
        members={members}
        schoolId={schoolId}
        schoolRoles={selectRoles}
      />
    </Container>
  );
};
