import React, { useState } from "react";
import { Icon, Grid, Label, List, Header } from "semantic-ui-react";
import { AuthorityListItem } from "./AuthorityListItem";

export const AuthorityListItems = ({ node, onCheckboxToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderCaret = () => {
    if (!node.authorities) {
      return "";
    }
    return isOpen ? "caret down" : "caret right";
  };

  const hasActiveChild = node?.authorities?.filter((x) => x.selected);

  const onToggleRow = () => {
    if (node?.authorities?.length > 0) setIsOpen(!isOpen);
  };

  return (
    <List.Item>
      <Grid>
        <Grid.Row onClick={onToggleRow} verticalAlign="middle">
          <Grid.Column>
            <div style={{ paddingLeft: 0 }}>
              <Grid padded>
                <Grid.Row verticalAlign="middle">
                  <Icon name={renderCaret()} />
                  <Header as="h4">{node?.name}</Header>

                  {hasActiveChild.length > 0 && (
                    <Label size="small" style={{ marginLeft: "10px" }}>
                      {hasActiveChild.length}
                    </Label>
                  )}
                </Grid.Row>
              </Grid>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isOpen &&
        node.authorities?.map((item, index) => (
          <AuthorityListItem
            key={index}
            value={item.id}
            name={item.name}
            disabled={item.disabled}
            relationId={item.relationId}
            onCheckboxToggle={onCheckboxToggle}
            checked={item.selected}
            fromPackage={!!item.packageId}
            packageName={item.packageName}
          />
        ))}
    </List.Item>
  );
};
