import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { callAuthServer } from "../../hooks/useFetch";

export const AuthCallback = () => {
  const location = useLocation();
  const history = useHistory();

  const deleteCookies = useCallback(() => {
    Cookies.remove("oauth_state");
    Cookies.remove("oauth_nonce");
    Cookies.remove("oauth_code_verifier");
  }, []);

  const checkParams = useCallback(async () => {
    const urlParams = new URLSearchParams(location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const oauth_code_verifier = Cookies.get("oauth_code_verifier");

    if (!oauth_code_verifier) {
      return history.push("/");
    }
    if (state !== Cookies.get("oauth_state") || !code) {
      deleteCookies();
      return history.push("/");
    }

    try {
      const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
      const redirectURI = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;
      const { data } = await callAuthServer(`/auth-token`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: `code=${code}&grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectURI}&code_verifier=${oauth_code_verifier}`,
      });

      if (data.error) {
        deleteCookies();
        return console.log("we failed");
      }
      Cookies.remove("oauth_state");
      Cookies.remove("oauth_code_verifier");

      Cookies.set("access_token", data.access_token);
      Cookies.set("refresh_token", data.refresh_token);
      Cookies.set("id_token", data.id_token);

      window.location.href = "/";
    } catch (e) {
      console.log("e", e);
    }
  }, [location.search, deleteCookies, history]);

  useEffect(() => {
    checkParams();
  }, [checkParams]);

  return <></>;
};
