import { useState } from "react";
// import { fetchMethods } from '../utils/fetchMethods/fetchMethods'

export const useNavItems = (config) => {
  const [navItemsState /* setNavItemsState */] = useState({
    navItems: [],
    userContext: null,
    tenant: null,
  });
  // useEffect(() => {
  //     fetchMethods.get(config.entrypoint, { credentials: 'include' }).then(data => {
  //         const { userContext } = data

  //         setNavItemsState({
  //             navItems: [],
  //             userContext,
  //             tenant: 'propello',
  //         })
  //     })
  // }, [config])

  return { ...navItemsState };
};

export default useNavItems;
