import React, { useCallback, useEffect, useState } from "react";
import Layout from "components/Layout";
import { useDebounce, isNumber } from "utils";
import { limitOptions } from "utils/limitOptions";
import { Button, Pagination, Loader, Table, Icon, Segment, Dropdown, Input, Divider, Header } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { serialize } from "../../utils/query";
import { callUserService } from "../../hooks/useFetch";
import { ModalSchoolCreate } from "./components/ModalSchoolCreate";

export const Schools = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [result, setResult] = useState([]);
  const [filterSearch, setFilterSearch] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const debounceSearch = useDebounce(filterSearch, 250);
  const [showModal, setShowModal] = useState(false);

  const getSchoolCodes = useCallback(
    async (filter) => {
      setLoading(true);
      const query = serialize(filter);
      const { response } = await callUserService(`/school-code?${query}`);
      setData({ schoolCodes: { ...response } });
      setLoading(false);
    },
    [setData]
  );

  useEffect(() => {
    if (!debounceSearch?.length) return;
    // search with debounced search value
    const filter = isNumber(parseInt(debounceSearch))
      ? { schoolCodePart: debounceSearch }
      : { namePart: debounceSearch };

    getSchoolCodes({ ...filter, page: currentPage, limit });
  }, [debounceSearch, getSchoolCodes, currentPage, limit]);

  useEffect(() => {
    // on mount and when search input is empy
    if (debounceSearch) return;

    getSchoolCodes({ page: currentPage, limit });
  }, [getSchoolCodes, debounceSearch, currentPage, limit]);

  useEffect(() => {
    if (data?.schoolCodes) {
      setResult(data?.schoolCodes?.content);
    }
  }, [data]);

  const changePage = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };

  const changeLimit = (event, data) => {
    const { value } = data;
    setLimit(value);
  };

  const filterResult = (event) => {
    const { value } = event.target;

    setFilterSearch(value);
  };

  const sortSchoolCode = () => {
    const sortedSchoolCode = [...result].sort((a, b) => a.schoolCode - b.schoolCode);

    setResult(sortedSchoolCode);
  };

  const sortSchoolName = () => {
    const sortedSchoolName = [...result].sort((a, b) => a.name.localeCompare(b.name));

    setResult(sortedSchoolName);
  };

  const sortUserId = () => {
    const sortedUserId = [...result].sort((a, b) => a.user.info.id - b.user.info.id);

    setResult(sortedUserId);
  };

  return (
    <Layout headerSpacing={true} headerText="Schools">
      {showModal ? <ModalSchoolCreate closeModal={() => setShowModal(false)} /> : null}
      <Divider hidden clearing />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          <Header>Page size</Header>
          <Dropdown search selection options={limitOptions} onChange={changeLimit} value={limit} />
        </div>
        <div>
          <Header />
          <Button onClick={() => setShowModal(true)}>Create School</Button>
        </div>
        <div>
          <Header>Filter</Header>
          <Input loading={loading} icon="search" placeholder="School code or name" onChange={filterResult} />
        </div>
      </div>
      <Divider hidden clearing className="large" />
      {loading ? (
        <Segment basic textAlign="center" className="no-padding">
          <Loader active>Loading schools</Loader>
        </Segment>
      ) : (
        <>
          <Table sortable striped selectable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell onClick={sortSchoolCode}>School code</Table.HeaderCell>
                <Table.HeaderCell onClick={sortSchoolName}>Name</Table.HeaderCell>
                <Table.HeaderCell onClick={sortUserId}>School ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {result?.map((schoolCode) => {
                return (
                  <Table.Row
                    key={schoolCode.info.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/school/${schoolCode.info.id}`);
                    }}
                  >
                    <Table.Cell>{schoolCode.schoolCode}</Table.Cell>
                    <Table.Cell>{schoolCode.name}</Table.Cell>
                    <Table.Cell>{schoolCode.info.id}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Divider hidden clearing className="medium" />

          <Segment basic floated="left" className="no-padding">
            <div>{`${data?.schoolCodes?.pageInfo?.totalElements} entries`}</div>
          </Segment>
          <Segment basic floated="right" className="no-padding">
            <Pagination
              defaultActivePage={currentPage}
              totalPages={data?.schoolCodes?.pageInfo?.totalPages}
              onPageChange={changePage}
              ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: <Icon name="angle left" />,
                icon: true,
                disabled: !data?.schoolCodes?.pageInfo?.hasPrevious,
              }}
              nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
                disabled: !data?.schoolCodes?.pageInfo?.hasNext,
              }}
            />
          </Segment>
        </>
      )}
    </Layout>
  );
};
