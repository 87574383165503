import React, { useCallback, useState } from "react";
import { Dropdown, Grid, Icon, Loader } from "semantic-ui-react";
import AddStudent from "components/AddStudent";
import { callUserService } from "../../hooks/useFetch";

const GroupAccordionTitle = ({ group, refetch, fetchMembers, schoolId }) => {
  const [loading, setLoading] = useState(false);

  const deleteGroup = useCallback(
    async (id) => {
      setLoading(true);
      await callUserService(`/classroom/${id}`, { method: "DELETE" });
      setLoading(false);
      refetch();
    },
    [refetch]
  );

  const { id: groupId } = group;

  if (loading) return <Loader active />;

  return (
    <>
      <Grid stretched verticalAlign="middle" columns="equal" className="accordion-title-grid" style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width="8">
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width="1" className="no-padding">
                  {/* dummy */}
                </Grid.Column>
                <Grid.Column width="15">
                  <div className="group-name" data-testid="group-name">
                    {group.name}
                  </div>
                  <div className={`ui text normal tiny ${group.membersCount > 0 ? "muted" : "red"}`}>
                    {group.membersCount > 0 ? `${group.membersCount} students` : null}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width="8">
            <Grid textAlign="right" verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width="14">
                  <AddStudent
                    groupOwnerId={Number(group.owner.id)}
                    groupId={Number(groupId)}
                    fetchMembers={fetchMembers}
                    refetch={refetch}
                    schoolId={schoolId}
                  />
                </Grid.Column>
                <Grid.Column width="2">
                  <Dropdown icon="ellipsis horizontal" pointing direction="left">
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => deleteGroup(groupId)}>
                        <Icon name="minus circle" color="red" /> Delete group
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default GroupAccordionTitle;
