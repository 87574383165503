import React, { useCallback } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { WondeSchool } from "../../WondeSchool";

export const ConfirmSelectionModal = ({ school, onComplete, onCancel, schoolId }) => {
  // TODO: change url when the backend change the url
  const { call, loading } = useApiCall("post", "/user-edlink", { autoCall: false });

  const onYesClick = useCallback(async () => {
    await call({ body: { schoolId: school.id, userId: schoolId } });
    onComplete();
  }, [call, onComplete, school.id, schoolId]);
  return (
    <Modal open onClose={onCancel}>
      <Modal.Header>Are you sure to connect to this school?</Modal.Header>
      <Modal.Content>
        <WondeSchool school={school} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} disabled={loading}>
          No
        </Button>
        <Button onClick={onYesClick} primary disabled={loading} loading={loading}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
