import React from "react";
import { Container, Grid } from "semantic-ui-react";
import ErrorMessage from "../ErrorMessage";
import Toast from "../Toast";
import { useMessageContext } from "../../hooks/useMessageContext";

const Messages = () => {
  const { clearNotification, state } = useMessageContext();
  const errors = state?.notifications.filter(({ type, level }) => `${level}${type}` === "errormessage");
  const toasts = state?.notifications.filter(({ type }) => type === "toast");

  return (
    <Grid verticalAlign="middle" container columns="equal" className="no-margin">
      <Grid.Column
        computer="5"
        tablet="8"
        mobile="16"
        stretched
        floated="right"
        style={{ padding: 0, marginRight: "1em" }}
      >
        <Container
          style={{
            position: "absolute",
            zIndex: 10002,
          }}
        >
          {errors &&
            errors.map((error) => (
              <ErrorMessage
                key={error.id}
                id={error.id}
                header={error.title}
                message={error.message}
                onDismiss={clearNotification}
              />
            ))}
          {toasts && toasts.map((toast) => <Toast key={toast.id} toast={toast} onDismiss={clearNotification} />)}
        </Container>
      </Grid.Column>
    </Grid>
  );
};

export default Messages;
