import { ADD_NOTIFICATION, CLEAR_NOTIFICATION } from "./actions";
import { uniqueBy } from "../../utils/uniqueBy/uniqueBy";

export const INITIAL_STATE = {
  notifications: [],
};

export const messageReducer = (state, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        notifications: uniqueBy([...state.notifications, action.payload], "id"),
      };
    case CLEAR_NOTIFICATION:
      return {
        notifications: [...state.notifications.filter(({ id }) => id !== action.payload)],
      };
    default:
      return state;
  }
};
