import Changelog from "components/Changelog";
import React from "react";
import UserNote from "./UserNote";

const UserAbout = ({ user, readOnly, setReadOnly, fetchUserInfo }) => {
  return (
    <>
      <Changelog userId={user.userId} />
      <UserNote
        note={user.note}
        userId={user.userId}
        readOnly={readOnly}
        setReadOnly={setReadOnly}
        fetchUserInfo={fetchUserInfo}
      />
    </>
  );
};

export default UserAbout;
