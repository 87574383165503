export const filterAuthoritiesArray = (authoritiesArray, userType, schoolAuthorityId) => {
  if (!authoritiesArray) return [];

  const result = [
    { info: { id: 1 }, name: "Teaching Materials", authorities: [] },
    { info: { id: 2 }, name: "System Authorities", authorities: [] },
  ];

  authoritiesArray.forEach((item) => {
    if (item.authorityKind === "PRODUCT_OBJECT_AUTHORITY" || item.authorityKind === "None") {
      result[0].authorities.push(item);
      if (schoolAuthorityId && item.schoolAuthorityId === schoolAuthorityId) result[0].isOpen = true;
    } else if (item.authorityKind === "SYSTEM_OBJECT_AUTHORITY" || item.authorityKind === "SYSTEM") {
      result[1].authorities.push(item);
      if (schoolAuthorityId && item.schoolAuthorityId === schoolAuthorityId) result[1].isOpen = true;
    }
  });

  if (!(userType === "teacher" || !userType)) result.pop();

  return result;
};
