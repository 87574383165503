import { callAuthServer } from "hooks/useFetch";
import React, { useCallback, useEffect, useState } from "react";
import { Segment, Divider, List, Loader } from "semantic-ui-react";
import Layout from "components/Layout";
import RolePermissionRow from "components/RolePermissions/RolePermissionRow";
import { RoleTypes } from "../../constants/roleTypes";

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);

  const getRolesPermissions = useCallback(async () => {
    try {
      const rolesResponse = await callAuthServer("/roles");
      const permissionsResponse = await callAuthServer("/permissions");
      setRoles(rolesResponse.data.filter((a) => a.name === "BACKOFFICE_ADMIN"));
      setAllPermissions(permissionsResponse.data.filter((a) => a.slug === "BACKOFFICE"));
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => getRolesPermissions(), [getRolesPermissions]);

  return (
    <>
      <Layout headerSpacing={true} headerText="Roles and Permissions">
        <Divider hidden clearing />

        <Segment>
          {roles?.length > 0 ? (
            <React.Fragment>
              <List relaxed className="striped">
                {roles.map((role, index) => (
                  <RolePermissionRow
                    key={role.id}
                    getRolesPermissions={getRolesPermissions}
                    role={role}
                    index={index}
                    allPermissions={allPermissions}
                    editDisabled={role.type === RoleTypes.SYSTEM}
                    deleteDisabled={role.type === RoleTypes.SYSTEM}
                  />
                ))}
              </List>
            </React.Fragment>
          ) : (
            <Loader active inline="centered" />
          )}
        </Segment>
      </Layout>
    </>
  );
};

export default RolePermissions;
