import React, { useState, useEffect, useCallback } from "react";
import { Loader, Header } from "semantic-ui-react";
import { callUserService } from "../../../../hooks/useFetch";
import { useMessage } from "ne-ui";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";
import { AuthorityList } from "./components/AuthorityList";
import { generateAuthoritiesInDropdowns, formatAuthorities } from "routes/School/utils/formatAuthorities";

export const SchoolAuthorities = () => {
  const { schoolInformation } = useSelectedSchoolContext();

  const [allAuthorities, setAllAuthorities] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatchMessage = useMessage();
  const getAuthorities = useCallback(
    async (updateLoading) => {
      try {
        setLoading(updateLoading);
        const { response } = await callUserService(
          `/school/${schoolInformation.id}/authorities?withAllAuthorities=true`
        );
        setAllAuthorities(response);
      } catch (error) {
        dispatchMessage({
          title: `Error getting authorities`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setLoading(false);
      }
    },
    [schoolInformation.id]
  );

  useEffect(() => {
    getAuthorities(true);
  }, [getAuthorities]);

  if (loading)
    return (
      <div>
        <Loader active />
      </div>
    );

  const connectAuthority = async (authorityId) => {
    try {
      await callUserService(`/school/connectAuthority`, {
        method: "POST",
        body: JSON.stringify({
          authorityId,
          schoolId: schoolInformation.id,
        }),
      });
      dispatchMessage({
        title: "Authority added, getting updated data",
        level: "success",
        showIcon: "check",
        type: "toast",
      });

      await getAuthorities(false);
    } catch (error) {
      dispatchMessage({
        title: "Error adding authority",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    }
  };

  const disconnectAuthority = async (schoolAuthorityId) => {
    try {
      await callUserService(`/school/disconnectAuthority/${schoolAuthorityId}`, { method: "DELETE" });

      dispatchMessage({
        title: "Authority disconnected, getting updated data",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
      await getAuthorities(false);
    } catch (error) {
      dispatchMessage({
        title: "Error disconnecting authority",
        level: "success",
        showIcon: "check",
        type: "toast",
      });
    }
  };

  const onCheckboxToggle = async (_event, value, callBack) => {
    if (value.relationId) {
      await disconnectAuthority(value.relationId);
      callBack();
      return;
    }
    await connectAuthority(value.id);
    callBack();
  };

  const formatedAuthorities = formatAuthorities(allAuthorities.authorities, allAuthorities?.schoolAuthorities, true);

  return (
    <>
      <Header as="h3" dividing>
        School authorities
      </Header>
      {loading ? (
        <div>
          <Loader active />
        </div>
      ) : (
        <AuthorityList data={generateAuthoritiesInDropdowns(formatedAuthorities)} onCheckboxToggle={onCheckboxToggle} />
      )}
    </>
  );
};
