import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary, MessageProvider, NEUIProvider } from "ne-ui";
import SearchProvider from "context/SearchProvider/SearchProvider";
import App from "components/App/App";
import "./semantic.min.css";
import AuthProvider from "ne-ui/components/AuthProvider";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Router basename={"/"}>
        <ErrorBoundary>
          <NEUIProvider config={{}}>
            <MessageProvider>
              <SearchProvider>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </SearchProvider>
            </MessageProvider>
          </NEUIProvider>
        </ErrorBoundary>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
