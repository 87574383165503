import React from "react";
import { Breadcrumb as SemanticBreadcrumb } from "semantic-ui-react";
import { useDevice } from "../../hooks/useDevice";

const Breadcrumbs = ({ breadcrumbsList, Link }) => {
  const { isMOBILE } = useDevice();

  if (!breadcrumbsList || breadcrumbsList.length < 1) {
    return null;
  }

  const [firstBreadcrumb, ...rest] = breadcrumbsList;
  const breadcrumbLink = Link ? Link : "a";

  if (!rest.length) {
    return (
      <SemanticBreadcrumb>
        <SemanticBreadcrumb.Section active>{firstBreadcrumb.name}</SemanticBreadcrumb.Section>
      </SemanticBreadcrumb>
    );
  }

  const renderFirstBreadcrumb = () => {
    if (firstBreadcrumb?.overrideLink) {
      return <SemanticBreadcrumb.Section href={firstBreadcrumb.url}>{firstBreadcrumb.name}</SemanticBreadcrumb.Section>;
    }

    return (
      <SemanticBreadcrumb.Section
        as={!firstBreadcrumb.active ? breadcrumbLink : null}
        to={firstBreadcrumb.url ? firstBreadcrumb.url : null}
        active={firstBreadcrumb.active}
      >
        {firstBreadcrumb.name}
      </SemanticBreadcrumb.Section>
    );
  };

  if (isMOBILE) return null;

  return (
    <SemanticBreadcrumb>
      {firstBreadcrumb && renderFirstBreadcrumb()}
      {rest.map((breadcrumb, index) => {
        return (
          <React.Fragment key={`${breadcrumb.url}${index}`}>
            <SemanticBreadcrumb.Divider />
            <SemanticBreadcrumb.Section
              as={!breadcrumb.active ? breadcrumbLink : null}
              to={breadcrumb.url ? breadcrumb.url : null}
              active={breadcrumb.active}
            >
              {breadcrumb.name}
            </SemanticBreadcrumb.Section>
          </React.Fragment>
        );
      })}
    </SemanticBreadcrumb>
  );
};

export default Breadcrumbs;
