import * as ReactUseDevice from "react-use-device";
import { breakpoints } from "../utils/breakpoint/breakpoints";

export function useDevice() {
  const { isMOBILE, isTABLET, isLAPTOP, isDESKTOP } = ReactUseDevice.useDevice(breakpoints);

  return {
    isMOBILE,
    isTABLET,
    isLAPTOP,
    isDESKTOP,
  };
}
