import React, { useEffect, useState } from "react";
import { Accordion, Form, Icon, Search } from "semantic-ui-react";
import { format } from "date-fns";
import {
  FIRST_NAME,
  LAST_NAME,
  USERNAME,
  USER_ID,
  FREE_TEXT,
  EMAIL,
  TYPE,
  CREATED_AFTER,
  CREATED_BEFORE,
  SCHOOL,
  SHCOOL_CODE,
} from "components/User/UserConstants";
import { CLEAR_FIELDS } from "./reducer";
import { useFetchUserService } from "../../hooks/useFetch";
import { SchoolAutocomplete } from "../SchoolAutocomplete/SchoolAutocomplete";

const SearchForm = ({ state, dispatch, loading, onSubmit, closed }) => {
  const [activeIndex, setActiveIndex] = useState(closed);
  const { data: dataTypes, loading: loadingTypes } = useFetchUserService("/user-types");
  const { data: schoolData } = useFetchUserService("/school?limit=10000");
  const {
    username,
    userId,
    freeText,
    firstName,
    lastName,
    email,
    type,
    createdAfterInput,
    createdBeforeInput,
    schoolCode,
    schoolCounter,
  } = state;

  useEffect(() => {
    if (loading) {
      setActiveIndex(-1);
    }
  }, [loading]);

  const handleClickAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  return (
    <Accordion styled fluid>
      <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClickAccordion}>
        <Icon name="dropdown" />
        Search
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <Form onSubmit={onSubmit} loading={loadingTypes}>
          <Form.Group widths="equal">
            <Form.Input
              label={"User id"}
              placeholder={"User id"}
              onChange={handleFieldsUpdate(USER_ID)}
              value={userId}
              type="number"
            />
            <Form.Input
              label={"Free text"}
              placeholder={"Free text"}
              onChange={handleFieldsUpdate(FREE_TEXT)}
              value={freeText}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={"First name"}
              placeholder={"First name"}
              onChange={handleFieldsUpdate(FIRST_NAME)}
              value={firstName}
            />
            <Form.Input
              label={"Last name"}
              placeholder={"Last name"}
              onChange={handleFieldsUpdate(LAST_NAME)}
              value={lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={"Username"}
              placeholder={"Username"}
              onChange={handleFieldsUpdate(USERNAME)}
              value={username}
            />
            <Form.Input label={"Email"} placeholder={"Email"} onChange={handleFieldsUpdate(EMAIL)} value={email} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={"Created after"}
              placeholder={"Created after"}
              type="date"
              onChange={handleFieldsUpdate(CREATED_AFTER)}
              value={createdAfterInput}
              max={createdBeforeInput || format(new Date(), "yyyy-MM-dd")}
            />
            <Form.Input
              label={"Created before"}
              placeholder={"Created before"}
              type="date"
              onChange={handleFieldsUpdate(CREATED_BEFORE)}
              value={createdBeforeInput}
              min={createdAfterInput}
              max={format(new Date(), "yyyy-MM-dd")}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              key={schoolCounter}
              label={"Type"}
              clearable
              placeholder="Type"
              value={type}
              onChange={handleFieldsUpdate(TYPE)}
              options={
                dataTypes?.map((type) => {
                  return {
                    text: type.name,
                    value: type.name,
                  };
                }) || []
              }
            />
            <div className="field">
              <label>School</label>
              <SchoolAutocomplete
                key={schoolCounter}
                source={schoolData?.content || []}
                setSelectedSchool={(value) => {
                  dispatch({
                    type: SCHOOL,
                    payload: value,
                  });
                }}
              />
            </div>
            <Form.Input
              label={"School code"}
              placeholder={"School code"}
              onChange={handleFieldsUpdate(SHCOOL_CODE)}
              value={schoolCode}
            />
          </Form.Group>
          <Form.Group>
            <Form.Button type="reset" content="Clear fields" onClick={() => dispatch({ type: CLEAR_FIELDS })} />
            <Form.Button type="submit" content="Search" primary />
          </Form.Group>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default SearchForm;
