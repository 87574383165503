import React from "react";
import { Divider, Grid, List, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMember from "components/DeleteMember";

const GroupAccordionContent = ({ group, members, loading, refetch, fetchMembers }) => {
  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      {group.membersCount > 0 ? (
        <React.Fragment>
          <Divider />
          <List relaxed className="admin-group-accordion-list striped">
            {members?.map(({ email, info, id }, index) => (
              <List.Item key={index}>
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={13}>
                      <List.Content>
                        <Link to={`/user/${info.id}`}>{email}</Link>
                      </List.Content>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <List.Content>
                        <DeleteMember
                          userId={Number(id)}
                          groupId={Number(group.id)}
                          groupOwnerId={Number(group.owner.id)}
                          refetch={refetch}
                          fetchMembers={fetchMembers}
                        />
                      </List.Content>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            ))}
          </List>
        </React.Fragment>
      ) : null}
    </>
  );
};

export default GroupAccordionContent;
