import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { Form, Header } from "semantic-ui-react";
import { useMessage } from "../../../ne-ui";
import { callUserService } from "../../../hooks/useFetch";

const initialState = {
  groupId: "",
  email: "",
};

const SHOW_ORGANISATION_FIELD_TYPES = ["schools", "libraries", "organisations"];

const addChildReducer = (state, action) => {
  if (action.type === "reset")
    return {
      ...initialState,
    };

  return {
    ...state,
    [action.type]: action.payload,
  };
};

const UserChildrenAdd = ({ user, setGroupId, groupId, fetchUserInfo }) => {
  const dispatchMessage = useMessage();
  const [data, setData] = useState(null);
  const [userTypesLoading, setUserTypesLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchGroupStudentsExcluded = useCallback(async () => {
    setUserTypesLoading(true);
    const { response } = await callUserService(`/groups/${user.userId}/exclude-student-groups`);
    setData({ groupsExcludeStudentGroups: response });
    setUserTypesLoading(false);
  }, [user.userId]);

  useEffect(() => {
    fetchGroupStudentsExcluded();
  }, [fetchGroupStudentsExcluded]);

  const createSubUser = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { groupId } = values;
        const { groupsExcludeStudentGroups } = data;
        const group = groupsExcludeStudentGroups.find(({ info: { id } }) => id === groupId);

        // if user is being added to administrators group, he will be added to teacher group as well
        if (group?.groupType === "administrators") {
          const groupTeachers = groupsExcludeStudentGroups.find(({ groupType }) => groupType === "teachers");
          await callUserService(`/groups/add-sub-user`, {
            method: "POST",
            body: JSON.stringify({ ...values, groupId: groupTeachers.info.id }),
          });
        }
        const { response } = await callUserService(`/groups/add-sub-user`, {
          method: "POST",
          body: JSON.stringify(values),
        });
        if (response.message) {
          throw new Error(response.message);
        }
        fetchUserInfo();
        dispatchMessage({
          title: `Child added!`,
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        dispatch({
          type: "reset",
        });
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          level: "error",
          showIcon: "warning circle",
          type: "toast",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, fetchUserInfo, data]
  );

  const [state, dispatch] = useReducer(addChildReducer, initialState);

  const options =
    data?.groupsExcludeStudentGroups.map((m) => ({
      key: m.groupType,
      text: m.groupType,
      value: m.info.id,
    })) || [];

  const onSubmit = () => {
    createSubUser({ email: state.email, groupId });
  };

  const onInputChange = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const onTypeSelected = (groupId) => {
    dispatch({
      type: "groupId",
      payload: groupId,
    });
    setGroupId(groupId);
  };

  const organisationFieldAllowed = useMemo(() => {
    if (!state.groupId || !data?.groupsExcludeStudentGroups?.length) return false;

    const filtered = data?.groupsExcludeStudentGroups
      .filter((m) => SHOW_ORGANISATION_FIELD_TYPES.includes(m.groupType))
      .map((m) => m.info.id);
    return filtered.includes(state.groupId);
  }, [state.groupId, data?.groupsExcludeStudentGroups]);

  useEffect(() => {
    if (organisationFieldAllowed) return;

    dispatch({
      type: "organisation",
      payload: "",
    });
  }, [organisationFieldAllowed]);

  return (
    <>
      <Header as="h3" dividing>
        Add user to group
      </Header>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <Form.Select
            loading={userTypesLoading}
            onChange={(_, { value }) => onTypeSelected(value)}
            label="Group"
            value={state.groupId}
            placeholder="Select group"
            options={options}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            onChange={(_, { value }) => onInputChange("email", value)}
            label="Email"
            required
            value={state.email}
            placeholder="Email"
          ></Form.Input>
        </Form.Field>
        <Form.Field>
          <Form.Button positive disabled={loading || !state.email || !state.groupId} type="submit">
            Submit
          </Form.Button>
        </Form.Field>
      </Form>
    </>
  );
};

export default UserChildrenAdd;
