import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, List, Segment, Button } from "semantic-ui-react";
import { useSelectedUserContext } from "context/SelectedUserContext";

const UserCard = () => {
  const history = useHistory();
  const { selectedUserInformation } = useSelectedUserContext();
  const { pathname } = history.location;

  const onEditUser = () => {
    history.replace(`${pathname}#information`);
  };

  return (
    <Segment clearing>
      <Divider hidden />
      <Button
        onClick={onEditUser}
        style={{ marginTop: "-1.2em" }}
        size="mini"
        floated="right"
        circular
        icon="pencil"
      ></Button>

      <List divided size="medium">
        <List.Item>
          <List.Icon name="user circle" size="large" verticalAlign="middle" />
          <List.Content style={{ wordBreak: "break-word" }}>
            <List.Header>Username</List.Header>
            {selectedUserInformation.username}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="mail" size="large" verticalAlign="middle" />
          <List.Content style={{ wordBreak: "break-word" }}>
            <List.Header>Email</List.Header>
            {selectedUserInformation.email}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="id card" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>User id</List.Header>
            {selectedUserInformation.userId}
          </List.Content>
        </List.Item>
        {selectedUserInformation.descendantCount > 0 && (
          <List.Item>
            <List.Icon name="users" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Header>Number of sub-users</List.Header>
              {selectedUserInformation.descendantCount}
            </List.Content>
          </List.Item>
        )}
      </List>
    </Segment>
  );
};

export default UserCard;
