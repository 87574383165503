import React from "react";
import { Link } from "react-router-dom";
import { Segment, Table, Divider, Dropdown } from "semantic-ui-react";
import { limitOptions } from "utils/limitOptions";

const SearchResult = ({ page, saveRecent, entries, result = false, changeLimit, limit }) => {
  const returnStatusName = (statusCode) => (statusCode === 0 ? "Active" : "Inactive");

  if (!result.length) return <Segment>No user found</Segment>;

  return (
    <>
      <Divider hidden className="small" />
      <Segment basic floated="left" clearing>
        {entries} users found
      </Segment>
      {page === 1 && (
        <Segment basic floated="right" className="no-padding" clearing>
          <Dropdown search selection options={limitOptions} onChange={changeLimit} value={limit} />
        </Segment>
      )}
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User id</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Account status</Table.HeaderCell>
            <Table.HeaderCell>User Type</Table.HeaderCell>
            <Table.HeaderCell>School</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {result.map((user) => {
            return (
              <Table.Row key={user.userId + user.school.id}>
                <Table.Cell>
                  <Link onClick={saveRecent} to={`/user/${user?.userId}?selectedSchool=${user?.school?.id}`}>
                    {user?.userId}
                  </Link>
                </Table.Cell>
                <Table.Cell>{user?.email}</Table.Cell>
                <Table.Cell>{user?.username}</Table.Cell>
                <Table.Cell>{returnStatusName(user?.status)}</Table.Cell>
                <Table.Cell>{user?.userType?.toUpperCase()}</Table.Cell>
                <Table.Cell>
                  <Link onClick={saveRecent} to={`/school/${user?.school?.id}`}>
                    {user?.school?.name}
                  </Link>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default SearchResult;
