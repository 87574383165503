import React, { useState } from "react";
import { Button, Grid, Confirm, Divider } from "semantic-ui-react";

const DisconnectGroupMembers = ({ group, members }) => {
  const [confirm, setConfirm] = useState(false);

  const onDisconnect = () => {};

  if (group.membersCount === 0 || !members?.length) return null;

  return (
    <>
      <Divider className="small" />
      <Confirm onConfirm={onDisconnect} open={confirm} onCancel={() => setConfirm(false)} />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button onClick={() => setConfirm(true)} size="small" negative disabled={true}>
              Clear group members
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default DisconnectGroupMembers;
