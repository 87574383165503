import { useSelectedSchoolContext } from "context/SelectedSchoolContext";
import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, List, Segment, Button } from "semantic-ui-react";

export const Sidebar = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const { schoolInformation } = useSelectedSchoolContext();

  const onEditSchool = () => {
    history.replace(`${pathname}#information`);
  };

  const items =
    schoolInformation.name && schoolInformation.id
      ? [
          { label: "School Name", value: schoolInformation.name },
          { label: "School ID", value: schoolInformation.id },
          { label: "School State", value: schoolInformation.state?.name },
          // { label: "Number of sub-users", value: "" },
        ]
      : [];

  return (
    <Segment clearing>
      <Divider hidden />
      <Button
        onClick={onEditSchool}
        style={{ marginTop: "-1.2em" }}
        size="mini"
        floated="right"
        circular
        icon="pencil"
      />

      <List divided size="medium">
        {items.map((item, index) => (
          <List.Item key={index}>
            <List.Icon name="user circle" size="large" verticalAlign="middle" />
            <List.Content style={{ wordBreak: "break-word" }}>
              <List.Header>{item.label}</List.Header>
              {item.value}
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  );
};
