import { Route, Switch } from "react-router-dom";
import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";
import { Messages } from "../../ne-ui";
import TopNav from "../TopNav";
import SubNav from "../SubNav";
import Home from "routes/Home";
import User from "routes/User";
import SearchUsers from "routes/SearchUsers";
import { Schools } from "routes/Schools";
import Authorities from "routes/Authorities";
import ManagePackages from "routes/ManagePackages";
import News from "routes/News";
import RolePermissions from "routes/RolePermissions/RolePermissions";
import { AuthCallback } from "../../routes/AuthCallback";
import Logout from "../../routes/Logout";
import AuthError from "ne-ui/components/AuthError";
import { Loader } from "semantic-ui-react";
import { School } from "routes/School";
import { SelectedUserContextProvider } from "context/SelectedUserContext";
import { SelectedSchoolContextProvider } from "context/SelectedSchoolContext";

const App = () => {
  const { loading, isAuthenticated, hasPermissionBO } = useAuthContext();
  if (loading) {
    return (
      <Loader active size="big">
        Loading
      </Loader>
    );
  }

  if (isAuthenticated && !hasPermissionBO) {
    return <AuthError />;
  }
  return (
    <>
      {isAuthenticated && hasPermissionBO && (
        <>
          <TopNav />
          <SubNav />
          <div style={{ position: "fixed", top: "10px", right: "15px", zIndex: "9999" }}>
            <Messages />
          </div>
        </>
      )}
      <Switch>
        {isAuthenticated && hasPermissionBO && (
          <>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/user/:userId">
              <SelectedUserContextProvider>
                <User />
              </SelectedUserContextProvider>
            </Route>
            <Route exact path="/school/:schoolId">
              <SelectedSchoolContextProvider>
                <School />
              </SelectedSchoolContextProvider>
            </Route>
            <Route exact path="/schools">
              <Schools />
            </Route>
            <Route exact path="/search">
              <SearchUsers />
            </Route>
            <Route exact path="/authorities">
              <Authorities />
            </Route>
            <Route exact path="/authorities/create">
              <ManagePackages />
            </Route>
            <Route exact path="/authorities/create/:id">
              <ManagePackages />
            </Route>
            <Route exact path="/rolepermissions">
              <RolePermissions />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
          </>
        )}
        <Route exact path="/auth-callback">
          <AuthCallback />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
      </Switch>
    </>
  );
};

export default App;
