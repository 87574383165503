import { StandardSelection } from "../../constants/standardSelection";

export const standardSelectionMap = (standardSelection) => {
  const standardsSelected = [];

  for (const [key, value] of Object.entries(standardSelection)) {
    if (value) {
      standardsSelected.push(key);
    }
  }

  const selectedCount = standardsSelected.length;

  if (selectedCount === Object.keys(StandardSelection).length - 1) {
    return StandardSelection.ALl;
  }

  if (selectedCount > 1) {
    return standardsSelected.map((item) => item.toUpperCase()).join(", ");
  }

  if (selectedCount === 1) {
    return standardsSelected[0].toUpperCase();
  }

  return null;
};
