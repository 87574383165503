import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { UserTypes } from "../../constants/userTypes";
import { useSelectedUserContext } from "context/SelectedUserContext";

const UserMenu = ({ handleMenuChange }) => {
  const { selectedUserInformation } = useSelectedUserContext();

  return (
    <Menu pointing secondary inverted>
      <Dropdown item text="User">
        <Dropdown.Menu>
          <Dropdown.Item data-hash="information" onClick={handleMenuChange}>
            Edit information
          </Dropdown.Item>
          <Dropdown.Item data-hash="about" onClick={handleMenuChange}>
            Changelog
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="Auth methods">
        <Dropdown.Menu>
          <Dropdown.Item data-hash="password" onClick={handleMenuChange}>
            Password
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Menu.Item name="Authorities" data-hash="authorities" onClick={handleMenuChange} />
      <Menu.Item name="Packages" data-hash="packages" onClick={handleMenuChange} />

      <Menu.Item name="Roles" data-hash="user-roles" onClick={handleMenuChange} />

      {selectedUserInformation.userType !== UserTypes.STUDENT && (
        <Menu.Item name="LP System" data-hash="lp-system" onClick={handleMenuChange} />
      )}
    </Menu>
  );
};

export default UserMenu;
