import React, { useState } from "react";
import { FindEdlinkIntegration } from "./components/FindEdlinkIntegration";
import { FindEdlinkSchool } from "./components/FindEdlinkSchool";
import { ConfirmSelectionModal } from "./components/ConfirmSelectionModal";

export const SelectEdlinkSchool = ({ onSelectComplete, onCancel, schoolId }) => {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  return (
    <>
      {!selectedIntegration ? (
        <FindEdlinkIntegration onSelect={setSelectedIntegration} onBack={() => onCancel()} />
      ) : (
        <FindEdlinkSchool
          onSelect={setSelectedSchool}
          onBack={() => setSelectedIntegration(null)}
          integration={selectedIntegration}
        />
      )}
      {!!selectedIntegration && !!selectedSchool && (
        <ConfirmSelectionModal
          integration={selectedIntegration}
          school={selectedSchool}
          onComplete={onSelectComplete}
          onCancel={() => setSelectedSchool(null)}
          schoolId={schoolId}
        />
      )}
    </>
  );
};
