import React, { useCallback, useState } from "react";
import { Form, Divider, Icon, Button } from "semantic-ui-react";
import { callUserService } from "../../hooks/useFetch";
import { useSelectedUserContext } from "../../context/SelectedUserContext";

const UserNote = ({ readOnly, setReadOnly, fetchUserInfo }) => {
  const [loading, setLoading] = useState(false);
  const { selectedUserInformation } = useSelectedUserContext();
  const [userNote, setNote] = useState("");

  const saveNote = useCallback(
    async (values) => {
      // setLoading(true);
      // await callUserService(`/user-admin-note`, { method: "POST", body: JSON.stringify(values) });
      // setLoading(false);
      // fetchUserInfo();
    },
    [fetchUserInfo]
  );

  const onNoteChange = (_, { value }) => setNote(value);

  const onSaveNote = () => saveNote({ userId: selectedUserInformation.userId, note: userNote });
  return (
    <>
      <Divider hidden className="massive" />
      <Form>
        <Form.TextArea
          disabled={readOnly}
          onChange={onNoteChange}
          value={userNote}
          placeholder="Add note..."
        ></Form.TextArea>
        <Form.Group>
          <Form.Button disabled={loading || readOnly} loading={loading} onClick={onSaveNote} color="blue" type="submit">
            Save
          </Form.Button>
          <Button icon onClick={() => setReadOnly(!readOnly)}>
            <Icon name={readOnly ? "lock" : "unlock"} />
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default UserNote;
