export const UserTypes = {
  TEACHER: "teacher",
  STUDENT: "student",
  SCHOOL: "school",
};

export const UserTypeIds = {
  teacher: 3,
  student: 4,
  admin: 11,
};
