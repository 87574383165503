import React, { useEffect, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import ManagePackage from "components/ManagePackage";
import PackageAccordion from "components/PackageAccordion";
import { useFetchUserService, callUserService } from "../../hooks/useFetch";
import { useSelectedUserContext } from "context/SelectedUserContext";
import { useMessage } from "../../ne-ui";

const UserPackages = () => {
  const { selectedSchool, selectedUserInformation } = useSelectedUserContext();
  const [showModal, setShowModal] = useState(false);
  const { data, loading, setFetchData: refetchPackages } = useFetchUserService(`/school/${selectedSchool.id}/packages`);
  const {
    data: userAuthorityPackages,
    setFetchData,
    loading: loadingAuthorityPackages,
  } = useFetchUserService(
    `/user-authority-packages?userId=${selectedUserInformation.userId}&schoolId=${selectedSchool.id}`
  );
  const dispatchMessage = useMessage();

  useEffect(() => {
    setFetchData(true);
    refetchPackages(true);
  }, [selectedSchool.id, setFetchData, refetchPackages]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const addSchoolPackageToUser = async (selected) => {
    try {
      await callUserService(`/user-authority-packages/connect-authority-package`, {
        method: "POST",
        body: JSON.stringify({
          schoolUserId: selectedSchool.schoolUserId,
          schoolPackageId: selected,
        }),
      });
      toggleModal();
      setFetchData(true);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
        id: selectedSchool.schoolUserId,
      });
    }
  };

  const handleDisconnect = async (schoolUserPackageId) => {
    try {
      await callUserService(`/user-authority-packages/disconnect-authority-package/${schoolUserPackageId}`, {
        method: "DELETE",
      });
      setFetchData(true);
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
        id: selectedUserInformation.userId,
      });
    }
  };

  return (
    <>
      <Header as="h3" dividing>
        User packages
      </Header>
      <Button content="Add package" onClick={() => setShowModal(true)} loading={loading} />
      {showModal && !loading ? (
        <ManagePackage
          resetModal={toggleModal}
          packages={data?.map((x) => ({
            id: x.id,
            name: x.package.name,
          }))}
          loading={loading}
          connectPackage={addSchoolPackageToUser}
        />
      ) : null}
      {userAuthorityPackages?.userAuthorityPackages?.map(({ authorityPackage, id }) => {
        return (
          <PackageAccordion
            userPackage={true}
            handleDisconnect={handleDisconnect}
            loading={loading || loadingAuthorityPackages}
            authPackage={{ ...authorityPackage, id }}
            key={id}
            //
            userId={selectedUserInformation.userId}
          />
        );
      })}
    </>
  );
};

export default UserPackages;
