import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Loader, Menu } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "components/Layout";
import EditAuthority from "components/Authorities/EditAuthority";
import CreateAuthority from "components/Authorities/CreateAuthority";
import Packages from "components/Packages";
import { callUserService } from "../../hooks/useFetch";
import { filterAuthoritiesArray } from "utils/filterAuthoritiesArray";

const AuthMenu = ({ handleMenuChange, activeItem }) => {
  return (
    <Menu pointing secondary inverted>
      <Menu.Item
        name="Authorities"
        active={activeItem === "#authorities"}
        data-hash="authorities"
        onClick={handleMenuChange}
      />
      <Menu.Item name="Packages" active={activeItem === "#packages"} data-hash="packages" onClick={handleMenuChange} />
    </Menu>
  );
};

const AuthContent = ({ activeItem, authorities, getAuthorities }) => {
  switch (activeItem) {
    case "#authorities":
      return <EditAuthority authorities={authorities} getAuthorities={getAuthorities} />;
    case "#packages":
      return <Packages />;
    default:
      throw new Error("Auth content could not render");
  }
};

const Authorities = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState("#authorities");
  const { hash } = location;

  const getAuthorities = useCallback(async () => {
    try {
      setLoading(true);
      const { response } = await callUserService("/authorities");
      const filteredAuthorities = filterAuthoritiesArray(response);
      setData(filteredAuthorities);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getAuthorities();
  }, [getAuthorities]);

  useEffect(() => {
    if (hash) {
      setActiveItem(hash);
    }
  }, [hash]);

  const toggleModal = () => setShowModal(!showModal);

  const handleMenuChange = (event) => {
    const hash = event.target.getAttribute("data-hash");
    const { pathname } = history.location;
    history.replace(`${pathname}#${hash}`);
  };

  const onClick = () => {
    if (activeItem === "#authorities") {
      setShowModal(true);
    } else {
      history.push("/authorities/create");
    }
  };

  if (loading) {
    return <Loader active>Loading....</Loader>;
  }

  return (
    <Layout
      headerText="Authorities & Packages"
      headerSpacing={true}
      MenuComponent={<AuthMenu handleMenuChange={handleMenuChange} activeItem={activeItem} />}
      callToAction={
        <Button primary onClick={onClick}>
          {activeItem === "#authorities" ? "Create authority " : "Create package"}
        </Button>
      }
    >
      {showModal && activeItem === "#authorities" ? (
        <CreateAuthority getAuthorities={getAuthorities} resetModal={toggleModal} />
      ) : null}
      <Divider hidden className="medium" />
      <AuthContent activeItem={activeItem} authorities={data} getAuthorities={getAuthorities} />
    </Layout>
  );
};

export default Authorities;
