import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getCookieValue } from "utils";
import { v4 as uuidv4 } from "uuid";
import crypto from "crypto";
import { base64URLEncode, sha256 } from "utils/codeChallenge";
import Cookies from "js-cookie";
import { callInternalApiGateway, callAuthServer } from "hooks/useFetch";
import * as jwt from "jsonwebtoken";
import { useLocation } from "react-router-dom";

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [hasPermissionBO, setHasPermissionBO] = useState(false);
  const [user, setUser] = useState({});
  const [hasSystemPermission, setHasSystemPermission] = useState(false);
  const location = useLocation();

  const refreshAccessToken = async (refreshToken) => {
    const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
    const redirectURI = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;

    const { data } = await callAuthServer(`/auth-token`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: `grant_type=refresh_token&client_id=${clientId}&redirect_uri=${redirectURI}&refresh_token=${refreshToken}`,
    });

    if (data.error) {
      setError(data.error);
      throw new Error("invalid token");
    }

    Cookies.set("access_token", data.access_token);
    Cookies.set("refresh_token", data.refresh_token);
    Cookies.set("id_token", data.id_token);
  };

  const getUserPermissions = async (id) => {
    try {
      const { response } = await callInternalApiGateway(`/users/${id}`);

      const hasPermission = response?.authRoles.some((rol) =>
        rol.permissions.some((perm) => perm.slug === "BACKOFFICE")
      );
      setUser(response);

      const systemPermission = response?.authRoles.some((rol) =>
        rol.permissions.some((perm) => perm.slug === "PRODUCT_SYSTEM_MANAGER")
      );
      setHasSystemPermission(systemPermission);
      if (hasPermission) {
        return true;
      }
      return false;
    } catch (e) {}
  };

  const checkForCredentials = useCallback(async () => {
    if (location.pathname.includes("logout")) {
      setLoading(false);
      return;
    }
    const accessToken = getCookieValue("access_token");
    const refreshToken = getCookieValue("refresh_token");
    if (!accessToken && !location.pathname.includes("auth-callback")) {
      await toLogin();
    } else if (accessToken) {
      try {
        await refreshAccessToken(refreshToken);
        const { userid } = jwt.decode(accessToken);
        const hasPermissionBO = await getUserPermissions(userid);
        setHasPermissionBO(hasPermissionBO);
        setIsAuthenticated(true);
        setLoading(false);
      } catch (e) {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        await toLogin();
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [location.pathname]);

  useEffect(() => {
    checkForCredentials();
  }, [checkForCredentials]);

  const hasEdlinkRosteringPermission = (user.authRoles || []).some((rol) =>
    rol.permissions.some((perm) => perm.slug === "EDLINK_ROSTERING")
  );
  const hasWondeRosteringPermission = (user.authRoles || []).some((rol) =>
    rol.permissions.some((perm) => perm.slug === "WONDE_ROSTERING")
  );
  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        error,
        hasPermissionBO,
        user,
        hasSystemPermission,
        hasEdlinkRosteringPermission,
        hasWondeRosteringPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const toLogin = async () => {
  const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
  const redirectURI = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;
  const stateUuid = uuidv4();
  const nonceUuid = uuidv4();
  const codeVerifier = base64URLEncode(crypto.randomBytes(32));
  const codeChallengeValue = base64URLEncode(sha256(codeVerifier));

  Cookies.set("oauth_state", stateUuid);
  Cookies.set("oauth_nonce", nonceUuid);
  Cookies.set("oauth_code_verifier", codeVerifier);

  const clientIdQuery = `client_id=${clientId}`;
  const redirectUri = `redirect_uri=${redirectURI}`;
  const codeChallenge = `code_challenge=${codeChallengeValue}`;
  const codeChallengeMethod = `code_challenge_method=S256`;
  const state = `state=${stateUuid}`;
  const nonce = `nonce=${nonceUuid}`;
  const noRedirect = "no_redirect=true";

  const { data } = await callAuthServer(
    `/auth-url?${clientIdQuery}&${redirectUri}&${codeChallenge}&${codeChallengeMethod}&${noRedirect}&${state}&${nonce}`,
    {
      method: "GET",
    }
  );

  window.location.href = data.url;
};
