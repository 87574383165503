import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { useHistory } from "react-router-dom";
import validateEmail from "utils/vallidateEmail";
import {
  TYPE,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  USERNAME,
  SCHOOL,
  SCHOOL_STATE,
  STANDARD_SELECTION,
} from "./UserConstants";
import { callAuthServer, useFetchUserService } from "../../hooks/useFetch";
import { SchoolAutocomplete } from "../SchoolAutocomplete/SchoolAutocomplete";
import { StandardSelectionComponent } from "../StandardSelection/StandardSelectionComponent";
import { standardSelectionMap } from "../StandardSelection/standardSelectionMap";

const initialState = {
  firstName: "",
  lastName: "",
  type: null,
  email: "",
  username: "",
  schoolId: "",
  stateId: process.env.REACT_APP_REGION === "us" ? "TX" : "UK",
  standardSelection: {
    ngss: true,
    state: true,
    cck12: false,
    txsc682024: false,
  },
};

const createUserReducer = (state, action) => {
  switch (action.type) {
    case TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SCHOOL:
      return {
        ...state,
        schoolId: action.payload,
      };
    case SCHOOL_STATE:
      return {
        ...state,
        stateId: action.payload,
      };
    case STANDARD_SELECTION:
      return {
        ...state,
        standardSelection: action.payload,
      };
    default:
      throw new Error("Something went wrong");
  }
};

const UserCreate = ({ resetModal }) => {
  const [error, setError] = useState(null);
  const dispatchMessage = useMessage();
  const history = useHistory();
  const [state, dispatch] = useReducer(createUserReducer, initialState);
  const [creatingUser, setCreatingUser] = useState(false);
  const { data, loading } = useFetchUserService("/user-types");
  const { data: states, loading: loadingStates } = useFetchUserService("/state");

  // The limit retrieve all school
  const { data: schoolData, schoolLoading } = useFetchUserService("/school?limit=10000");
  const createUser = useCallback(
    async (newUserData) => {
      setCreatingUser(true);
      try {
        const { data } = await callAuthServer("/sign-up", {
          method: "POST",
          body: newUserData,
        });
        if (data.errorMessage) {
          throw new Error(data.errorMessage);
        }
        if (data.error) {
          throw new Error(`${data.error} ${data.message}`);
        }
        history.push(`/user/${data.id}`);
      } catch (error) {
        const id = "_" + Math.random().toString(36).substr(2, 9);
        dispatchMessage({
          title: `${error.message}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
          id,
        });
        resetModal();
      } finally {
        setCreatingUser(false);
      }
    },
    [dispatchMessage, history, resetModal]
  );

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({
      type: field,
      payload: value,
    });
  };

  const handleSubmit = () => {
    if (!validateEmail(state.email)) return setError({ email: true });

    if (!state.type) return setError({ type: true });
    const standardSelectionValue = standardSelectionMap(standardSelection);
    createUser({
      ...state,
      temporaryPassword: true,
      standardSelection: standardSelectionValue,
    });
  };

  useEffect(() => {
    if (state.type && error?.type) {
      setError((prev) => ({ ...prev, type: false }));
    }
  }, [state.type, error?.type]);

  if (loading || schoolLoading || loadingStates) return null;

  const { type, firstName, lastName, email, username, schoolId, stateId, standardSelection } = state;

  return (
    <Modal onClose={() => resetModal()} open={true}>
      <Modal.Header>Create user</Modal.Header>
      <Modal.Content>
        <Form loading={creatingUser} id="create-user" onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Select
              label="Type"
              placeholder="Select user type"
              required
              value={type}
              onChange={handleFieldsUpdate(TYPE)}
              error={error?.type ? { content: " No type selected" } : null}
              options={data
                ?.map((a) => a.name)
                .map((type) => {
                  return {
                    text: type,
                    value: type,
                  };
                })}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              value={firstName}
              onChange={handleFieldsUpdate(FIRST_NAME)}
              label="First name"
              placeholder="First name"
            />
            <Form.Input
              value={lastName}
              onChange={handleFieldsUpdate(LAST_NAME)}
              label="Last name"
              placeholder="Last name"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              value={email}
              required
              onChange={handleFieldsUpdate(EMAIL)}
              label="Email"
              placeholder="Email"
              error={error?.email ? { content: "Incorrect email" } : null}
            />
            <Form.Input
              value={username}
              onChange={handleFieldsUpdate(USERNAME)}
              label="Username"
              placeholder="Username"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <label>School</label>
              <SchoolAutocomplete
                source={schoolData?.content || []}
                setSelectedSchool={(value) => {
                  dispatch({
                    type: SCHOOL,
                    payload: value,
                  });
                }}
              />
            </div>
            {!schoolId && (
              <Form.Select
                label="School State"
                placeholder="Select school state"
                value={stateId}
                onChange={handleFieldsUpdate(SCHOOL_STATE)}
                options={states?.map((state) => {
                  return {
                    text: state.name,
                    value: state.stateId,
                  };
                })}
              />
            )}
          </Form.Group>
          <Form.Group>
            {!schoolId && process.env.REACT_APP_REGION === "us" && (
              <StandardSelectionComponent
                value={standardSelection}
                onChange={(_, data) => {
                  const { label, checked } = data;
                  dispatch({
                    type: STANDARD_SELECTION,
                    payload: {
                      ...standardSelection,
                      [label.toLowerCase()]: checked,
                    },
                  });
                }}
              />
            )}
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetModal();
          }}
          basic
          primary
        >
          Cancel
        </Button>
        <Button primary type="submit" form="create-user">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserCreate;
