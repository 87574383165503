import React, { useState } from "react";

export const SearchContext = React.createContext({});

export function SearchProvider({ children }) {
  const [searchHistory, setSearchHistory] = useState({});

  return (
    <SearchContext.Provider
      value={{
        searchHistory,
        setSearchHistory,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export default SearchProvider;
