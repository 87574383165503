import React, { useState } from "react";
import { Button, Header } from "semantic-ui-react";
import { ActiveIntegration } from "./components/ActiveIntegration";
import { SelectEdlinkSchool } from "./components/SelectEdlinkSchool";
import { useAuthContext } from "ne-ui/components/AuthProvider/AuthProvider";

export const UserEdlink = ({ schoolId }) => {
  const [linkEdlink, setLinkEdlink] = useState(false);

  const { hasEdlinkRosteringPermission } = useAuthContext();

  console.log("hasEdlinkRosteringPermission", hasEdlinkRosteringPermission);

  return (
    <>
      <Header as="h3" dividing>
        Rostering
      </Header>
      {!linkEdlink ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ActiveIntegration schoolId={schoolId} />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button primary onClick={() => setLinkEdlink(true)}>
              Connect to Edlink
            </Button>
          </div>
        </>
      ) : (
        <SelectEdlinkSchool
          schoolId={schoolId}
          onSelectComplete={() => setLinkEdlink(false)}
          onCancel={() => setLinkEdlink(false)}
        />
      )}
    </>
  );
};
