import React, { useCallback, useReducer, useState } from "react";
import { Form } from "semantic-ui-react";
import { useMessage } from "../../ne-ui";
import { callInternalApiGateway } from "../../hooks/useFetch";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  admin: false,
  sendEmail: false,
};

const addTeacherReducer = (state, action) => {
  if (action.type === "reset")
    return {
      ...initialState,
    };

  return {
    ...state,
    [action.type]: action.payload,
  };
};

const AddTeacher = ({ show, ownerId, refetch, toggleShow }) => {
  const dispatchMessage = useMessage();
  const [state, dispatch] = useReducer(addTeacherReducer, initialState);
  const [loading, setLoading] = useState(false);

  const inviteTeacher = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await callInternalApiGateway(`/invitation/invite-teacher`, {
          method: "POST",
          body: JSON.stringify(values),
        });
        dispatchMessage({
          title: "Teacher added successfully",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
        dispatch({
          type: "reset",
        });
        refetch();
        toggleShow(false);
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          level: "error",
          showIcon: "warning circle",
          type: "toast",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatchMessage, refetch, toggleShow]
  );

  if (!show) return null;

  const onInputChange = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const addTeacher = () => {
    inviteTeacher({ ...state, ownerId });
  };
  return (
    <>
      <Form onSubmit={addTeacher} size="mini">
        <Form.Input
          onChange={(_, { value }) => onInputChange("email", value)}
          type="email"
          value={state.email}
          required
          label="Email"
        ></Form.Input>
        <Form.Input
          onChange={(_, { value }) => onInputChange("firstName", value)}
          value={state.firstName}
          label="First name"
        ></Form.Input>
        <Form.Input
          value={state.lastName}
          onChange={(_, { value }) => onInputChange("lastName", value)}
          label="Last name"
        ></Form.Input>
        <Form.Group>
          <Form.Checkbox onChange={(_, { checked }) => onInputChange("admin", checked)} label="Admin"></Form.Checkbox>
          <Form.Checkbox
            onChange={(_, { checked }) => onInputChange("sendEmail", checked)}
            label="Send welcome mail"
          ></Form.Checkbox>
        </Form.Group>
        <Form.Button loading={loading} positive size="mini" type="submit">
          Submit
        </Form.Button>
      </Form>
    </>
  );
};

export default AddTeacher;
