import React from "react";
import { Image, Header, Container } from "semantic-ui-react";

export const EdlinkIntegration = ({ integration }) => (
  <div>
    <div style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}>
      <Image src={`https://ed.link${integration?.provider?.icon_url}`} size="small" centered fluid />
    </div>
    <Container textAlign="center" fluid>
      <Header size="large">{integration?.source?.name}</Header>
    </Container>
  </div>
);
