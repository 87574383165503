import React from "react";
import { About } from "./Tabs/About";
import { EditSchool } from "./Tabs/EditSchool";
import { AddChildren } from "./Tabs/AddChildren";
import { Children } from "./Tabs/Children";
import { EducationGroups } from "./Tabs/EducationGroups";
import { EducationTeachers } from "./Tabs/EducationTeachers";
import { SchoolAuthorities } from "./Tabs/SchoolAuthorities";
import { SchoolRoles } from "./Tabs/SchoolRoles";
import { useParams } from "react-router-dom";
import { SchoolPackages } from "./SchoolPackages";
import { UserEdlink } from "../../../components/User/UserEdlink/UserEdlink";
import { UserWonde } from "../../../components/User/UserWonde/UserWonde";

export const SchoolTabs = ({ activeItem }) => {
  const { schoolId } = useParams();
  switch (activeItem) {
    // User Tab
    case "#about":
      return <About />;

    // Edit Profile Tab
    case "#information":
      return <EditSchool />;

    // Authorities Tab
    case "#authorities":
      return <SchoolAuthorities />;

    // Packages Tab
    case "#packages":
      return <SchoolPackages />;

    // Education Tab
    case "#education-groups":
      return <EducationGroups schoolId={schoolId} />;
    case "#education-teachers":
      return <EducationTeachers schoolId={schoolId} />;

    case "#school-edlink":
      return <UserEdlink schoolId={schoolId} />;

    case "#school-wonde":
      return <UserWonde schoolId={schoolId} />;

    //Groups Tab
    case "#add-children":
      return <AddChildren schoolId={schoolId} />;
    case "#children":
      return <Children schoolId={schoolId} />;

    case "#school-roles":
      return <SchoolRoles />;
    default:
      return null;
  }
};
