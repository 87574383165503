import React from "react";
import TeacherTableRow from "./TeacherTableRow";
import { Table } from "semantic-ui-react";

const TeacherTable = ({ teachers, paging, fetchMembers, ownerId }) => {
  if (!teachers?.length) return null;

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User Id</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Admin</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {teachers.map((teacher, i) => (
          <TeacherTableRow key={i} ownerId={ownerId} teacher={teacher} paging={paging} fetchMembers={fetchMembers} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default TeacherTable;
