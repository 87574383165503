import React, { useState } from "react";
import { Form, Grid, Loader, Label, List } from "semantic-ui-react";

export const AuthorityListItem = ({
  disabled,
  value,
  name,
  onCheckboxToggle,
  checked,
  fromPackage,
  packageName,
  relationId,
}) => {
  const [localLoading, setLocalLoading] = useState(false);

  const handleOnChange = (event, data) => {
    setLocalLoading(true);
    onCheckboxToggle(
      event,
      {
        id: data.value,
        relationId: relationId || null,
      },
      () => setLocalLoading(false)
    );
  };

  return (
    <List.Item>
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="100%">
            <div style={{ paddingLeft: 15 }}>
              <Grid padded>
                <Grid.Row verticalAlign="middle">
                  <div style={{ display: "flex" }}>
                    <Form.Checkbox
                      disabled={disabled || localLoading}
                      value={value}
                      label={name}
                      onChange={handleOnChange}
                      checked={checked}
                    />
                    {fromPackage
                      ? packageName.map((name, index) => {
                          return (
                            <Label key={index} size="small" style={{ marginLeft: "10px" }}>
                              From Package: {name}
                            </Label>
                          );
                        })
                      : null}
                  </div>
                  <Loader size="tiny" active={localLoading} />
                </Grid.Row>
              </Grid>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </List.Item>
  );
};
