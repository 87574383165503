import React, { useState } from "react";
import {
  UserPackages,
  UserChildren,
  UserEditInformation,
  UserSchoolcode,
  UserStatus,
  AdministerEducationGroups,
  AdministerEducationTeachers,
  UserAuthorities,
  UserChildrenAdd,
  UserAbout,
  UserCounterReport,
  UserMeta,
  UserRoles,
} from "components/User";
import { UserPassword } from "components/User/UserAuth";
import { UserEdlink } from "./UserEdlink/UserEdlink";
import { UserWonde } from "./UserWonde/UserWonde";
import { LPSystem } from "./LPSystem/LPSystem";

const UserMainContent = ({ activeItem, user, fetchUserInfo }) => {
  const [groupId, setGroupId] = useState(null);
  const [readOnly, setReadOnly] = useState(() => (user.externalSyncId ? true : false));

  switch (activeItem) {
    case "#about":
      return <UserAbout user={user} readOnly={readOnly} setReadOnly={setReadOnly} fetchUserInfo={fetchUserInfo} />;
    case "#authorities":
      return <UserAuthorities user={user} />;
    case "#packages":
      return <UserPackages user={user} />;
    case "#information":
      return <UserEditInformation />;
    case "#education-groups":
      return <AdministerEducationGroups user={user} />;
    case "#education-teachers":
      return <AdministerEducationTeachers user={user} />;
    case "#password":
      return <UserPassword userId={user?.userId} userEmail={user?.email} />;
    case "#schoolcode":
      return <UserSchoolcode user={user} />;
    case "#status":
      return <UserStatus user={user} fetchUserInfo={fetchUserInfo} />;
    case "#add-children":
      return <UserChildrenAdd user={user} groupId={groupId} setGroupId={setGroupId} fetchUserInfo={fetchUserInfo} />;
    case "#counter-report":
      return <UserCounterReport user={user} />;
    case "#metadata":
      return <UserMeta user={user} fetchUserInfo={fetchUserInfo} />;
    case "#children":
      return <UserChildren user={user} groupId={groupId} setGroupId={setGroupId} readOnly={readOnly} />;
    case "#user-roles":
      return <UserRoles user={user} />;
    case "#user-edlink":
      return <UserEdlink user={user} />;
    case "#user-wonde":
      return <UserWonde user={user} />;
    case "#lp-system":
      return <LPSystem />;
    default:
      return null;
  }
};

export default UserMainContent;
