import { useCallback, useEffect, useRef, useState } from "react";

import { getCookieValue } from "../utils";

const getQueryString = (query) =>
  Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value !== undefined ? value : "")}`)
    .join("&");

function getFullPathWithQuery(url, query = {}, params = {}) {
  for (const [key, value] of Object.entries(params)) {
    if (!value) continue;
    url = url.replace(`:${key}`, value.toString());
  }
  if (!Object.keys(query).length) {
    return url;
  }
  return `${url}?${getQueryString(query)}`;
}

export const useApiCall = (method, path, options = {}) => {
  // Refs
  const pathRef = useRef(path);
  const queryOption = useRef(options.query);
  const paramsOption = useRef(options.params);
  const bodyOption = useRef(options.body);
  const autoCallOption = useRef(options.autoCall !== undefined ? options.autoCall : false);

  // Inner state
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);

  const call = useCallback(
    async (opts = {}) => {
      const { query, params, body: optsBody, ...restOptions } = opts;

      try {
        setLoading(true);

        const queryToUse = query || queryOption.current;
        const paramsToUse = params || paramsOption.current;
        const bodyToUse = optsBody || bodyOption.current;

        let body;
        if (bodyToUse instanceof FormData) {
          body = bodyToUse;
        } else if (bodyToUse) {
          body = JSON.stringify(bodyToUse);
        }

        // this is for our internal api gateway
        const access_token = getCookieValue("access_token");

        const response = await fetch(
          getFullPathWithQuery(
            `${process.env.REACT_APP_INTERNAL_API_GATEWAY}${pathRef.current}`,
            queryToUse,
            paramsToUse
          ),
          {
            headers: { Authorization: `Bearer ${access_token}`, "Content-Type": "application/json" },
            method,
            body,
            ...restOptions,
          }
        );
        const jsonData = await response.json();

        setError(undefined);
        setHeaders(response.headers);
        setData(jsonData);
        setStatusCode(response.statusCode);

        return response.data;
      } catch (e) {
        setHeaders(e.headers);
        setData(undefined);
        setStatusCode(e.statusCode);
        setError(e.error);

        throw e;
      } finally {
        setLoading(false);
      }
    },
    [method]
  );

  const reset = useCallback(() => {
    setData(undefined);
    setStatusCode(undefined);
    setError(undefined);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (autoCallOption.current) {
      call();
    }
  }, [call]);

  return { data, loading, error, call, reset, statusCode, headers };
};

export const useMockApiCall = (method, path, options = {}) => {
  // Refs
  const mockOption = useRef(options.mockData);
  const autoCallOption = useRef(options.autoCall !== undefined ? options.autoCall : false);

  // Inner state
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);

  const call = useCallback(async (opts = {}) => {
    try {
      setLoading(true);

      const data = await new Promise((res) => setTimeout(() => res(mockOption.current), 1000));

      setError(undefined);
      setHeaders({});
      setData(data);
      setStatusCode(200);

      return data;
    } catch (e) {
      setHeaders(e.headers);
      setData(undefined);
      setStatusCode(e.statusCode);
      setError(e.error);

      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  const reset = useCallback(() => {
    setData(undefined);
    setStatusCode(undefined);
    setError(undefined);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (autoCallOption.current) {
      call();
    }
  }, [call]);

  return { data, loading, error, call, reset, statusCode, headers };
};
