import React, { useCallback, useState } from "react";
import GroupAccordionTitle from "./GroupAccordionTitle";
import GroupAccordionContent from "./GroupAccordionContent";
import DisconnectGroupMembers from "components/DisconnectGroupMembers";
import { Accordion, Segment } from "semantic-ui-react";
import { callUserService } from "../../hooks/useFetch";

const GroupAccordion = ({ group, refetch, schoolId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dropdownIcon = group.membersCount ? "dropdown" : "minus";

  const fetchMembers = useCallback(async (classroomId) => {
    setLoading(true);
    const { response } = await callUserService(`/classroom/${classroomId}/members`);
    setData({
      group: {
        content: [{ members: response.map((a) => ({ ...a, info: { id: a.id } })), info: { groupId: classroomId } }],
        pageInfo: {
          totalPages: 1,
          hasNext: false,
          hasPrevious: false,
          totalElements: 1000,
          isLast: true,
        },
      },
    });
    setLoading(false);
  }, []);

  const panels = [
    {
      key: group.info.id,
      title: {
        icon: dropdownIcon,
        content: (
          <GroupAccordionTitle group={group} refetch={refetch} fetchMembers={fetchMembers} schoolId={schoolId} />
        ),
      },
      content: {
        content: (
          <>
            <GroupAccordionContent
              group={group}
              members={data?.group?.content[0]?.members}
              loading={loading}
              refetch={refetch}
              fetchMembers={fetchMembers}
            />
            <DisconnectGroupMembers group={group} members={data?.group?.content[0]?.members} />
          </>
        ),
      },
    },
  ];

  const lazyFetchMembers = () => {
    fetchMembers(group.id);
  };

  return (
    <Segment>
      <Accordion onTitleClick={lazyFetchMembers} panels={panels} exclusive={false} fluid />
    </Segment>
  );
};

export default GroupAccordion;
