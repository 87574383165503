import React, { useEffect, useState } from "react";
import { Grid, Loader, Segment, Message, Divider } from "semantic-ui-react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Layout from "components/Layout";
import { Sidebar } from "./components";
import { SchoolTabs } from "./components/SchoolTabs";
import { SchoolMenu } from "./components/SchoolMenu";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";

export const School = () => {
  const { schoolId } = useParams();
  const location = useLocation();
  const { hash } = location;
  const { schoolInformation, fetchSchoolInformation } = useSelectedSchoolContext();
  const [error, setError] = useState(null);

  const history = useHistory();
  const { pathname } = history.location;
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const makeCall = async () => {
      try {
        setLoading(true);
        await fetchSchoolInformation(schoolId);
        setLoading(false);
      } catch (e) {
        setError(e.toString());
      }
    };
    makeCall();
  }, [fetchSchoolInformation, schoolId]);

  useEffect(() => {
    if (hash) {
      setActiveItem(hash);
    }
  }, [hash]);

  useEffect(() => {
    if (schoolId && !hash) setActiveItem("#about");
  }, [schoolId, hash]);

  if (loading) {
    return <Loader active>Loading</Loader>;
  }

  if (error) {
    return (
      <Segment inverted color="grey">
        <Message compact negative>
          {"Failed to fetch school"}
        </Message>
        <Divider hidden />
        <Link to={"/"}>Go back</Link>
      </Segment>
    );
  }

  const handleMenuChange = (event) => {
    const hash = event.target.getAttribute("data-hash");
    history.replace(`${pathname}#${hash}`);
  };

  return (
    <>
      <Layout
        headerText={`School: ${schoolInformation?.name || ""}`}
        MenuComponent={<SchoolMenu handleMenuChange={handleMenuChange} />}
      >
        <Grid columns="16">
          <Grid.Column width="4">
            <Sidebar />
          </Grid.Column>
          <Grid.Column width={"12"}>
            {activeItem ? <SchoolTabs activeItem={activeItem} /> : <Loader active inline="centered" />}
          </Grid.Column>
        </Grid>
      </Layout>
    </>
  );
};
