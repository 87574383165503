import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "utils";
import { useMessage } from "../../ne-ui";
import { Form, Header, Loader, Segment, List, Message, Button } from "semantic-ui-react";
import { callUserService } from "../../hooks/useFetch";

const MoveUser = ({ selectedUsers, groupId, refetchGroups }) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const findUser = useCallback(async (id) => {
    setLoading(true);
    const { response } = await callUserService(`/users/${id}`);
    if (response.error) {
      setError(response.error.toString());
    }
    setData({ userById: { ...response } });
    setLoading(false);
  }, []);

  const dispatchMessage = useMessage();
  const [moveLoading, setMoveLoading] = useState(false);

  const moveToOwner = useCallback(
    async (values) => {
      setMoveLoading(true);
      try {
        const { response } = await callUserService(`/users/move-user-to-owner`, {
          method: "POST",
          body: JSON.stringify(values),
        });
        if (response.failed.length) {
          dispatchMessage({
            title: "Failed to move user to parent",
            type: "toast",
            level: "error",
            showIcon: "warning circle",
          });
        } else {
          dispatchMessage({
            title: "Users moved sucessfully",
            level: "success",
            showIcon: "check",
            type: "toast",
          });
          refetchGroups();
        }
      } catch (error) {
        dispatchMessage({
          title: "Failed to move user to parent",
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setMoveLoading(false);
      }
    },
    [dispatchMessage, refetchGroups]
  );

  const [newParent, setNewParent] = useState(null);
  const [selectInput, setSelectInput] = useState("");
  const debouncedSearch = useDebounce(selectInput, 300);
  const foundParent = newParent?.userById;

  useEffect(() => {
    if (!data) return;

    setNewParent(data);
  }, [data]);

  useEffect(() => {
    if (!debouncedSearch.length) return null;

    findUser(debouncedSearch);
  }, [debouncedSearch, findUser]);

  const onParentIdInput = (value) => {
    if (newParent) setNewParent(null);

    setSelectInput(value);
  };

  const moveUsersToParent = () => {
    moveToOwner({ userIds: selectedUsers, parentId: foundParent?.userId, groupId: groupId });
  };

  return (
    <>
      <Header>Move selected users to parent </Header>
      <Form>
        <Form.Group widths="equal">
          <Form.Input placeholder="Parent id" type="text" onChange={(e, input) => onParentIdInput(input.value)} />
        </Form.Group>
      </Form>
      {loading && (
        <Segment inverted color="grey">
          <Loader active />
        </Segment>
      )}
      {foundParent && (
        <List>
          {foundParent.userType && <List.Item>User type: {foundParent.userType}</List.Item>}
          {foundParent.username && <List.Item>Username: {foundParent.username}</List.Item>}
          {foundParent.email && <List.Item>Email: {foundParent.email}</List.Item>}
          <List.Item>
            <Button
              loading={moveLoading}
              disabled={!selectedUsers.length || moveLoading}
              onClick={moveUsersToParent}
              positive
            >
              Move to parent
            </Button>
          </List.Item>
        </List>
      )}
      {error && debouncedSearch && <Message negative>{`No user with id ${debouncedSearch} found `}</Message>}
    </>
  );
};

export default MoveUser;
