import React from "react";
import { Segment, List } from "semantic-ui-react";
import { AuthorityListItems } from "./AuthorityListItems";

export const AuthorityList = ({ data, onCheckboxToggle }) => {
  return (
    <Segment className="no-padding">
      <List selection divided relaxed>
        {data.map((node) => {
          return <AuthorityListItems key={node.id} node={node} onCheckboxToggle={onCheckboxToggle} />;
        })}
      </List>
    </Segment>
  );
};
