import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Form, Loader, Message, Modal } from "semantic-ui-react";
import validateEmail from "utils/vallidateEmail";
import SearchBarStudents from "components/SearchBarStudents";
import { useMessage } from "../../ne-ui";
import { callUserService } from "../../hooks/useFetch";

const StudentList = ({ isOpen, closeModal, groupId, groupOwnerId, fetchMembers, refetch, schoolId }) => {
  const dispatchMessage = useMessage();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const [fetchingStudents, setFetchingStudents] = useState(false);

  const fetchStudents = useCallback(async () => {
    if (!isOpen) return;
    setFetchingStudents(true);
    const { response } = await callUserService(`/school/${schoolId}/user/student`);
    setData({ groups: { content: [{ members: response }] } });
    setFetchingStudents(false);
  }, [isOpen, schoolId]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const connectMember = useCallback(
    async (values) => {
      try {
        setFetchingStudents(true);
        await callUserService(`/users/add-classroom/${values.groupId}?email=${values.email}`, {
          body: JSON.stringify(values),
          method: "POST",
        });
        await fetchMembers(groupId);
        closeModal();
        setFetchingStudents(false);
        refetch();
        dispatchMessage({
          id: "connect-member",
          title: "User added to group",
          level: "success",
          showIcon: "check",
          type: "toast",
        });
      } catch (error) {
        dispatchMessage({
          id: "connect-member",
          title: `${error}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
        setFetchingStudents(false);
      }
    },
    [fetchMembers, dispatchMessage, closeModal, groupId, refetch]
  );

  const handleSubmit = async () => {
    if (!validateEmail(selectedStudent?.email)) {
      setErrorMessage("Invalid email");
      return;
    }

    connectMember({ email: selectedStudent.email, groupId: groupId })
      .then(() => {
        setErrorMessage(null);
      })
      .catch((_err) => {
        setErrorMessage("Something went wrong");
      });
  };

  return (
    <Modal open={isOpen}>
      <Modal.Header>Add student</Modal.Header>
      <Modal.Content>
        {fetchingStudents ? (
          <Dimmer active inverted>
            <Loader active inline="centered">
              Loading
            </Loader>
          </Dimmer>
        ) : (
          data?.groups?.content[0]?.members && (
            <Form data-testid="add-student" error={errorMessage !== null} id="add-student" onSubmit={handleSubmit}>
              <SearchBarStudents
                setErrorMessage={setErrorMessage}
                setSelectedStudent={setSelectedStudent}
                members={data?.groups?.content[0]?.members}
              />
              {errorMessage !== null && <Message error header="Error" content={errorMessage} />}
            </Form>
          )
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => {
            setErrorMessage(null);
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button disabled={!selectedStudent} data-testid="submit" positive form={"add-student"} type="submit">
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

StudentList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  groupOwnerId: PropTypes.number.isRequired,
};

export default StudentList;
