import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useMessage } from "../../ne-ui";
import { Form, Header, Segment, Loader } from "semantic-ui-react";
import { callUserService, useFetchUserService } from "../../hooks/useFetch";

const SCHOOL_CODE = "SCHOOL_CODE";
const NAME = "NAME";
const EXTERNAL_SYNC = "EXTERNAL_SYNC";
const POPULATE_STATE = "POPULATE_STATE";
const RESET_STATE = "RESET_STATE";

const initialState = (user) => {
  return {
    name: "",
    schoolCode: user?.schoolCode || "",
    externalSync: false,
    userId: user?.info?.id || "",
  };
};

const schoolCodeReducer = (state, action) => {
  switch (action.type) {
    case SCHOOL_CODE:
      return {
        ...state,
        schoolCode: action.payload,
      };
    case NAME:
      return {
        ...state,
        name: action.payload,
      };
    case EXTERNAL_SYNC:
      return {
        ...state,
        // ugly workaround because semantic ui checkbox is screaming when sending boolean
        externalSync: action.payload === "false",
      };
    case POPULATE_STATE:
      const { externalSync, name, schoolCode } = action.payload;
      return {
        ...state,
        externalSync,
        name,
        schoolCode,
      };
    case RESET_STATE:
      // keep userId
      return {
        ...state,
        name: "",
        schoolCode: "",
        externalSync: false,
      };
    default:
      throw new Error("Something went wrong when editing school code");
  }
};

const UserSchoolcode = ({ user }) => {
  const dispatchMessage = useMessage();
  const { data, loading, setFetchData: refetch } = useFetchUserService(`/school-code/${user?.info?.id}`);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  const createSchoolCode = useCallback(
    async (values) => {
      setCreatingUser(true);
      try {
        await callUserService(`/school-code`, { method: "POST", body: JSON.stringify(values) });
        dispatchMessage({
          title: "School code created",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: user.info.id,
        });
        refetch(true);
      } catch (error) {
      } finally {
        setCreatingUser(false);
      }
    },
    [dispatchMessage, user.info.id, refetch]
  );

  const updateSchoolCode = useCallback(
    async (id, values) => {
      setUpdatingUser(true);
      try {
        await callUserService(`/school-code/${id}`, { method: "PUT", body: JSON.stringify(values) });
        dispatchMessage({
          title: "School code updated",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: user.info.id,
        });
        refetch(true);
      } catch (error) {
      } finally {
        setUpdatingUser(false);
      }
    },
    [dispatchMessage, user.info.id, refetch]
  );
  const deleteSchoolCode = useCallback(
    async (id) => {
      setDeletingUser(true);
      try {
        await callUserService(`/school-code/${id}`, { method: "DELETE" });
        dispatchMessage({
          title: "School code deleted",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: user.info.id,
        });
        refetch(true);
        dispatch({ type: RESET_STATE });
      } catch (error) {
      } finally {
        setDeletingUser(false);
      }
    },
    [dispatchMessage, user.info.id, refetch]
  );
  const [state, dispatch] = useReducer(schoolCodeReducer, initialState(user));

  useEffect(() => {
    if (data?.schoolCodeByUser) {
      dispatch({
        type: POPULATE_STATE,
        payload: data.schoolCodeByUser,
      });
    }
  }, [data]);

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({ type: field, payload: value });
  };

  const onFormSubmit = () => {
    const { schoolCode, name, externalSync, userId } = state;
    if (data?.schoolCodeByUser?.info?.id) {
      const {
        info: { id },
      } = data.schoolCodeByUser;
      return updateSchoolCode(id, { ...state });
    }

    return createSchoolCode({ schoolCode, name, externalSync, userId });
  };

  const onDeleteSchoolCode = () => {
    const id = data?.schoolCodeByUser?.info?.id;
    deleteSchoolCode(id);
  };

  const { schoolCode, name, externalSync } = state;

  return (
    <>
      <Header as="h3" dividing>
        School code
      </Header>
      {loading || deletingUser ? (
        <>
          <p>Loading school code</p>
          <Loader active />
        </>
      ) : (
        <Segment color="grey" inverted>
          <Form onSubmit={onFormSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="School code"
                placeholder="School code"
                onChange={handleFieldsUpdate(SCHOOL_CODE)}
                value={schoolCode}
              />
              <Form.Input fluid label="Name" placeholder="Name" onChange={handleFieldsUpdate(NAME)} value={name} />
            </Form.Group>
            <Form.Field>
              <Form.Checkbox
                toggle
                label="Sync enabled"
                onChange={handleFieldsUpdate(EXTERNAL_SYNC)}
                value={externalSync.toString()}
                checked={externalSync}
              />
            </Form.Field>
            <Form.Group>
              <Form.Field style={{ order: 1 }}>
                <Form.Button content="Submit" color="blue" type="submit" disabled={creatingUser || updatingUser} />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  content="Delete"
                  color="red"
                  name="delete"
                  type="button"
                  disabled={deletingUser}
                  onClick={onDeleteSchoolCode}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      )}
    </>
  );
};

export default UserSchoolcode;
