export const getPathTitle = (path) => {
  switch (path) {
    case "":
      return "Home";
    case "user":
      return "User";
    case "search":
      return "Search Users";
    case "schoolcodes":
      return "School Codes";
    case "authorities":
      return "Authorities";
    case "rolepermissions":
      return "Roles & Permissions";
    case "auth-callback":
      return "Auth Callback";
    case "logout":
      return "Logout";
    default:
      return path.charAt(0).toUpperCase() + path.slice(1);
  }
};

export const defaultBreadcrumb = {
  path: `/`,
  url: `/`,
  name: "Home",
  overrideLink: true,
  exact: true,
};
