import React, { useCallback, useState } from "react";
import { Table, Checkbox, Button, Confirm, Form, Loader, Dimmer, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useMessage } from "../../ne-ui";
import { callUserService } from "../../hooks/useFetch";

const TeacherTableRow = ({ teacher, ownerId, groupId, fetchMembers }) => {
  const [isAdmin, setIsAdmin] = useState(teacher.isAdmin);
  const dispatchMessage = useMessage();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const toggleAdmin = useCallback(
    async (values) => {
      const { admin, ownerId, userId } = values;
      const url = admin ? `/users/${userId}/add-school-role` : `/users/${userId}/remove-school-role`;
      const method = "POST";
      await callUserService(url, {
        method,
        body: JSON.stringify({ schoolId: ownerId, schoolRole: "SCHOOL_ADMIN" }),
      });
      setLoading(true);
      setLoading(false);
      setIsAdmin(!isAdmin);
    },
    [isAdmin]
  );

  const onToggleAdmin = useCallback(
    async (checked, userId) => {
      await toggleAdmin({ admin: checked, userId, ownerId });
    },
    [ownerId, toggleAdmin]
  );

  const removeTeacher = useCallback(
    async (values) => {
      setDeleteLoading(true);
      try {
        await callUserService(`/users/${teacher.id}/remove-from-school/${ownerId}`, {
          method: "DELETE",
        });
        await fetchMembers();
        dispatchMessage({
          title: "Teacher removed",
          level: "success",
          showIcon: "check",
          type: "toast",
          id: "teacher-removed-success",
        });
        close();
      } catch (error) {
        dispatchMessage({
          title: `${error}`,
          id: "teacher-removed-error",
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatchMessage, fetchMembers, groupId, teacher.id, onToggleAdmin]
  );

  const [isOpen, setOpen] = useState(false);

  const onRemoveTeacher = () => {
    removeTeacher({ userIds: [teacher.id], groupIds: [groupId] });
  };

  const close = () => setOpen(false);

  return (
    <>
      <Confirm
        open={isOpen}
        onCancel={close}
        onClose={close}
        onConfirm={onRemoveTeacher}
        title="Delete teacher"
        content={
          <>
            <Dimmer active={deleteLoading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>{`This action will remove ${teacher.email} as teacher`}</Segment>
          </>
        }
      />

      <Table.Row key={teacher.id}>
        <Table.Cell>
          <Link to={`/user/${teacher.id}`}>{teacher.id}</Link>
        </Table.Cell>
        <Table.Cell>{teacher.username}</Table.Cell>
        <Table.Cell>{teacher.email}</Table.Cell>
        <Table.Cell width="2">
          <Form>
            <Form.Group inline>
              <Form.Field
                control={Checkbox}
                key={teacher.id}
                toggle
                onChange={(_, { checked }) => onToggleAdmin(checked, teacher.id)}
                checked={isAdmin}
              />
              <Loader active={loading} />
            </Form.Group>
          </Form>
        </Table.Cell>
        <Table.Cell>
          <Button
            circular
            size="mini"
            basic
            color="red"
            content="Remove"
            data-userid={teacher.id}
            onClick={() => setOpen(true)}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default TeacherTableRow;
