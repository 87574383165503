import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SubNav as SubNavNEUI } from "../../ne-ui";
import { getPathTitle, defaultBreadcrumb } from "utils/breadcrumbUtils";

const SubNav = () => {
  const location = useLocation();
  const breadcrumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

  const breadcrumbToDisplay = breadcrumbs.length
    ? {
        path: `/${breadcrumbs[0]}`,
        url: `/${breadcrumbs[0]}`,
        name: getPathTitle(breadcrumbs[0] || ""),
        exact: true,
        active: true,
      }
    : {};

  return (
    <SubNavNEUI
      breadcrumbsList={breadcrumbs.length ? [defaultBreadcrumb, breadcrumbToDisplay] : []}
      Link={Link}
      columnWidth={12}
    />
  );
};

export default SubNav;
