import React from "react";
import { Form } from "semantic-ui-react";
import { StandardSelection } from "../../constants/standardSelection";

export const StandardSelectionComponent = ({ value, onChange }) => {
  return (
    <div className="field">
      <label>Standards</label>
      <div style={{ display: "flex", justifyContent: "space-between", width: "150px", marginTop: "-32px" }}>
        {[StandardSelection.STATE, StandardSelection.NGSS, StandardSelection.CCK12, StandardSelection.TXSC682024].map(
          (standard) => (
            <Form.Checkbox
              key={standard}
              label={standard === "STATE" ? "State" : standard}
              value={standard}
              onChange={onChange}
              checked={value?.[standard.toLowerCase()]}
              style={{ marginRight: "10px" }}
            />
          )
        )}
      </div>
    </div>
  );
};
