import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Header, Segment, Divider } from "semantic-ui-react";
import { useMessage } from "../../../../ne-ui";
import { callUserService, useFetchUserService } from "../../../../hooks/useFetch";
import { useSelectedSchoolContext } from "context/SelectedSchoolContext";
import { StandardSelectionComponent } from "../../../../components/StandardSelection/StandardSelectionComponent";
import { standardSelectionMap } from "../../../../components/StandardSelection/standardSelectionMap";
import { STANDARD_SELECTION } from "../../../../components/User/UserConstants";
import { StandardSelection } from "constants/standardSelection";

const setInitialState = (school) => {
  return {
    schoolCode: school.schoolCode || "",
    name: school.name || "",
    schoolTypeId: school.schoolTypeId || null,
    stateId: school.stateId,
    standardSelection: (() => {
      if (!school.standardSelection) {
        return { ngss: false, state: false, cck12: false, txsc682024: false };
      } else if (school.standardSelection === "ALL") {
        return { ngss: true, state: true, cck12: true, txsc682024: true };
      } else {
        const selectedOptions = school.standardSelection.split(",").map((option) => option.trim());
        let selection = {};
        for (const option of Object.values(StandardSelection)) {
          const lowerCaseOption = option;
          if (selectedOptions.includes(lowerCaseOption)) {
            selection = { ...selection, [option.toLowerCase()]: true };
          } else {
            selection = { ...selection, [option.toLowerCase()]: false };
          }
        }
        return selection;
      }
    })(),
  };
};

const editSchoolInformation = (state, action) => {
  switch (action.type) {
    case "NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SCHOOL_CODE":
      return {
        ...state,
        schoolCode: action.payload,
      };
    case "SCHOOL_TYPE":
      return {
        ...state,
        schoolTypeId: action.payload,
      };
    case "SCHOOL_STATE":
      return {
        ...state,
        stateId: action.payload,
      };
    case "STANDARD_SELECTION":
      return {
        ...state,
        standardSelection: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export const EditSchool = () => {
  const { schoolInformation, fetchSchoolInformation } = useSelectedSchoolContext();
  const { data: states, loading: loadingStates } = useFetchUserService("/state");
  const dispatchMessage = useMessage();
  const [loading, setLoading] = useState(false);
  const updateSchool = async (values) => {
    setLoading(true);
    try {
      const { response } = await callUserService(`/school/${schoolInformation.id}`, {
        method: "PUT",
        body: JSON.stringify(values),
      });
      if (response?.message) {
        throw new Error(response.message);
      }
      await fetchSchoolInformation(schoolInformation.id);
      dispatchMessage({
        title: "School updated",
        level: "success",
        showIcon: "check",
        type: "toast",
        id: schoolInformation.id,
      });
    } catch (error) {
      dispatchMessage({
        title: `${error}`,
        type: "toast",
        level: "error",
        showIcon: "warning circle",
        id: schoolInformation.id,
      });
    } finally {
      setLoading(false);
    }
  };

  const [schoolTypes, setSchoolTypes] = useState([]);
  const [state, dispatch] = useReducer(editSchoolInformation, setInitialState(schoolInformation));

  const handleFieldsUpdate = (field) => (event, data) => {
    const { value } = data;
    dispatch({ type: field, payload: value });
  };

  const handleSubmit = () => {
    updateSchool({
      schoolId: schoolInformation.id,
      ...state,
      stateId: states.find(({ internalId }) => internalId === state.stateId).stateId,
      standardSelection: standardSelectionMap(state.standardSelection),
    });
  };

  const { name, schoolCode, schoolTypeId, stateId, standardSelection } = state || {};

  const [typesLoading, setTypesLoading] = useState(false);
  useEffect(() => {
    const getSchoolTypes = async () => {
      setTypesLoading(true);
      try {
        const { response } = await callUserService("/school/types");
        setSchoolTypes(response);
      } catch (e) {
        dispatchMessage({
          title: `${e}`,
          type: "toast",
          level: "error",
          showIcon: "warning circle",
        });
      } finally {
        setTypesLoading(false);
      }
    };
    getSchoolTypes();
  }, []);

  return (
    <>
      <Header as="h3" dividing>
        Edit School information
      </Header>
      <Segment color="grey" inverted>
        <Form loading={loading} onSubmit={handleSubmit}>
          <Form.Input placeholder="Name" label="Name" value={name} onChange={handleFieldsUpdate("NAME")} />

          <Form.Input
            placeholder="School Code"
            label="School Code"
            value={schoolCode}
            onChange={handleFieldsUpdate("SCHOOL_CODE")}
          />

          <Form.Select
            label="School Type"
            placeholder=""
            loading={loading || typesLoading}
            value={schoolTypeId}
            disabled={!schoolTypes.length || loading || typesLoading}
            options={schoolTypes.map((x) => ({
              value: x.id,
              text: x.name,
            }))}
            onChange={handleFieldsUpdate("SCHOOL_TYPE")}
          />

          <Form.Select
            label="School State"
            placeholder="Select"
            options={states?.map((x) => ({
              key: x.internalId,
              value: x.internalId,
              text: x.name,
            }))}
            value={stateId}
            onChange={handleFieldsUpdate("SCHOOL_STATE")}
          />

          {process.env.REACT_APP_REGION === "us" && (
            <Form.Group>
              <StandardSelectionComponent
                value={standardSelection}
                onChange={(_, data) => {
                  const { label, checked } = data;
                  dispatch({
                    type: STANDARD_SELECTION,
                    payload: {
                      ...standardSelection,
                      [label.toLowerCase()]: checked,
                    },
                  });
                }}
              />
            </Form.Group>
          )}

          <Button as={Link} type="button" to="#">
            Cancel
          </Button>
          <Button primary type="submit">
            Submit
          </Button>
        </Form>
        <Divider />
      </Segment>
    </>
  );
};
