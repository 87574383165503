import React, { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Button, Header, Grid, Pagination, Icon } from "semantic-ui-react";
import { Loader } from "semantic-ui-react";
import TeacherTable from "components/TeacherTable";
import ImportUsers from "./Import/ImportUsers";
import AddTeacher from "./AddTeacher";
import { callUserService } from "../../hooks/useFetch";

const AdministerEducationTeachers = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [add, toggleAdd] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(null);
  const [loadingGroupMembers, setLoadingGroupMembers] = useState(false);
  const [groupMembers, setGroupMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userAdmins, setUserAdmins] = useState([]);
  const groupIdRef = useRef();

  const fetchTeacherGroups = useCallback(async (ownerId) => {
    setLoading(true);
    const { response } = await callUserService(
      `/groups/teachers/${ownerId}?clientId=backoffice-app&page=${1}&limit=25`
    );
    const { response: groupsExcludedResponse } = await callUserService(`/groups/${ownerId}/exclude-student-groups`);
    const administratorsGroupId = groupsExcludedResponse.find((group) => group.groupType)?.info?.id;
    if (administratorsGroupId) {
      const { response: userAdminsResponse } = await callUserService(
        `/groups/${administratorsGroupId}/members?clientId=backoffice-app&page=${1}&limit=1000000`
      );
      setUserAdmins(userAdminsResponse?.content[0]?.members);
    }
    setLoading(false);
    setData({ groups: response });
  }, []);

  const fetchMembers = useCallback(
    async (groupId) => {
      setLoadingGroupMembers(true);
      const { response } = await callUserService(`/groups/${groupId}/members?clientId=backoffice-app&page=${page}`);
      setGroupMembers({ group: response });
      setLoadingGroupMembers(false);
    },
    [page]
  );

  useEffect(() => {
    fetchTeacherGroups(user.userId);
  }, [fetchTeacherGroups, user.userId]);

  useEffect(() => {
    if (data?.groups.content.length > 0) {
      groupIdRef.current = data?.groups?.content[0]?.groupId;
      fetchMembers(data?.groups?.content[0]?.groupId);
    }
  }, [data, fetchMembers, page]);

  if (loading || loadingGroupMembers) return <Loader active></Loader>;

  const changePage = (event, data) => {
    const { activePage } = data;
    setPage(activePage);
  };

  const hasNext = groupMembers?.group?.pageInfo?.hasNext;
  const hasPrev = groupMembers?.group?.pageInfo?.hasPrevious;
  const totalPages = groupMembers?.group?.pageInfo?.totalPages;

  const refetch = async () => {
    await fetchTeacherGroups(user.userId);
    await fetchMembers(data?.groups?.content[0]?.groupId || groupIdRef.current);
  };

  return (
    <>
      <Header as="h3" dividing>
        Manage teachers
      </Header>
      <div>
        <Button active={open} onClick={() => setOpen(true)}>
          Import
        </Button>
        <Button active={add} onClick={() => toggleAdd(!add)}>
          Add
        </Button>
      </div>
      {add && (
        <>
          <Divider className="small" />
          <AddTeacher refetch={refetch} toggleShow={toggleAdd} ownerId={user.userId} show={add} />
        </>
      )}
      <ImportUsers open={open} setOpen={setOpen} refetch={refetch} type="teachers" userId={user.userId} />
      <Divider hidden className="small" />

      {groupMembers?.group?.content.length > 0 && (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Pagination
                floated="right"
                defaultActivePage={page}
                totalPages={totalPages}
                onPageChange={changePage}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name="angle left" />,
                  icon: true,
                  disabled: !hasPrev,
                }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: !hasNext,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <TeacherTable
        ownerId={user.userId}
        teachers={groupMembers?.group?.content[0]}
        paging={{ page, limit: 25 }}
        fetchMembers={fetchMembers}
        userAdmins={userAdmins}
      />
    </>
  );
};

export default AdministerEducationTeachers;
