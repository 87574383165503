import React, { createContext, useReducer } from "react";
import { messageReducer, INITIAL_STATE } from "./reducer";
import { CLEAR_NOTIFICATION } from "./actions";

export const MessageContext = createContext();

export const MessageProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(messageReducer, initialState || INITIAL_STATE);
  const clearNotification = (props) => dispatch({ type: CLEAR_NOTIFICATION, ...props });

  return <MessageContext.Provider value={{ dispatch, state, clearNotification }}>{children}</MessageContext.Provider>;
};
