export const flatToTree = (arr) => {
  if (!arr?.length) return null;

  const arrMap = new Map(arr.map((item) => [item.info.id, item]));
  const tree = arr.reduce((acc, curr) => {
    const item = curr;
    item.value = item.info.id;
    item.label = item.name;

    if (item.parentId) {
      const parentItem = arrMap.get(item.parentId);
      if (!parentItem) {
        return acc;
      }
      parentItem.children ? parentItem.children.push(item) : (parentItem.children = [item]);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  return tree;
};
