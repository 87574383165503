import React from "react";
import { Divider, List } from "semantic-ui-react";

const PackageAccordionContent = ({ authPackage }) => {
  return (
    <>
      {authPackage.packageContent.length > 0 ? (
        <React.Fragment>
          <Divider />
          <List relaxed className="striped">
            {authPackage.packageContent.map((auth, index) => {
              return (
                <List.Item key={index} style={{ paddingRight: "1em" }}>
                  <List.Content floated="left" verticalAlign="middle">
                    <List.Header style={null} content={auth.name} />
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </React.Fragment>
      ) : null}
    </>
  );
};

export default PackageAccordionContent;
