import React, { useCallback, useContext, useState } from "react";
import { callAuthServer, callUserService } from "../../hooks/useFetch";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const SelectedUserContext = React.createContext({});

export function SelectedUserContextProvider({ children }) {
  const { search } = useLocation();
  const values = queryString.parse(search);
  // User Information Simplified endpoint information.
  const [selectedUserInformation, setSelectedUserInformation] = useState({});
  // School User endpoint information.
  const [selectedUserSchools, setSelectedUserSchools] = useState([]);
  // User Types endpoint information.
  const [userTypes, setUserTypes] = useState([]);
  // User Login Methods endpoint information.
  const [selectedUserLoginMethods, setSelectedUserLoginMethods] = useState([]);

  // New Selected School:
  const [selectedSchool, setSelectedSchool] = useState({});

  const fetchUserSelected = useCallback(async (userId) => {
    const { response } = await callUserService(`/users/${userId}?simplified=true`);
    setSelectedUserInformation(response);
  }, []);

  const fetchSelectedUserTypes = useCallback(async (userId) => {
    const { response } = await callUserService(`/user-types?userId=${userId}`);
    setUserTypes(response);
  }, []);

  const fetchUserSchools = useCallback(async (userId) => {
    const { response } = await callUserService(`/users/${userId}/parents`, { method: "GET" });
    setSelectedUserSchools(response);
    if (response.length && response[0]?.name && response[0]?.id) {
      const _selectedSchool = values.selectedSchool
        ? response.find((school) => school.id === values.selectedSchool)
        : response[0];
      setSelectedSchool({
        id: _selectedSchool.id,
        name: _selectedSchool.name,
        schoolUserId: _selectedSchool.schoolUserId,
        schoolUserType: _selectedSchool.schoolUserType,
      });
    }
  }, []);

  const fetchUserLoginMethods = useCallback(async (userId) => {
    const { data: loginMethods } = await callAuthServer(`/users/login-methods?userId=${userId}`);
    setSelectedUserLoginMethods(loginMethods);
  }, []);

  return (
    <SelectedUserContext.Provider
      value={{
        selectedUserInformation,
        selectedUserSchools,
        selectedUserLoginMethods,
        userTypes,
        //
        selectedSchool,
        setSelectedSchool,
        //
        fetchUserSelected,
        fetchSelectedUserTypes,
        fetchUserSchools,
        fetchUserLoginMethods,
      }}
    >
      {children}
    </SelectedUserContext.Provider>
  );
}
export const useSelectedUserContext = () => useContext(SelectedUserContext);
