import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Loader, Table, Checkbox, Grid, Header, Button, Confirm, Dimmer, Segment } from "semantic-ui-react";

import MoveUser from "components/MoveUser";
import { callUserService } from "../../hooks/useFetch";

const ChildrenTable = ({
  loading,
  members,
  readOnly,
  schoolRoleId,
  refetchGroups = () => {},
  schoolId,
  schoolRoles,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  const disconnectMember = useCallback(
    async (values) => {
      try {
        setDisconnectLoading(true);
        await callUserService(`/school/${schoolId}/remove-role-to-users`, {
          method: "POST",
          body: JSON.stringify(values),
        });

        const { schoolRoleId } = values;
        const schoolRole = schoolRoles.find((role) => role.id === schoolRoleId);
        if (schoolRole.name === "TEACHER") {
          await callUserService(`/school/${schoolId}/remove-role-to-users`, {
            method: "POST",
            body: JSON.stringify({
              ...values,
              schoolRoleId: schoolRoles.find((role) => role.name === "SCHOOL_ADMIN")?.id,
            }),
          });
        }

        setDisconnectLoading(false);
        closeDelete();
        refetchGroups();
      } catch (e) {
        setDisconnectLoading(false);
        console.log(e);
      }
    },
    [refetchGroups, schoolId]
  );

  useEffect(() => {
    setSelectedUsers([]);
  }, [schoolRoleId]);

  if (loading) {
    return (
      <div style={{ minHeight: "500px" }}>
        <Loader active />
      </div>
    );
  }

  const closeDelete = () => setConfirmOpen(false);

  const confirmDelete = () => {
    disconnectMember({ userIds: selectedUsers, schoolRoleId });
  };

  if (!members?.length) return null;

  return (
    <>
      <Confirm
        open={confirmOpen}
        onCancel={closeDelete}
        close={closeDelete}
        onConfirm={confirmDelete}
        title="Delete use"
        content={
          <>
            <Dimmer active={disconnectLoading} inverted>
              <Loader active inline="centered" />
            </Dimmer>
            <Segment basic>Are you sure?</Segment>
          </>
        }
      />
      <Table fixed columns="5" compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2">Id</Table.HeaderCell>
            <Table.HeaderCell width="7">Email</Table.HeaderCell>
            <Table.HeaderCell width="2">Selected</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {members.map((member, index) => {
            return (
              <Table.Row active={selectedUsers?.indexOf(member.id) > -1} key={index}>
                <Table.Cell>
                  <Link to={`/user/${member.id}`}>{member.id}</Link>
                </Table.Cell>
                <Table.Cell collapsing>{member.email}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Checkbox
                    disabled={readOnly}
                    checked={selectedUsers.includes(member.id)}
                    onChange={(_, event) =>
                      event.checked
                        ? setSelectedUsers([...selectedUsers, member.id])
                        : setSelectedUsers(selectedUsers.filter((f) => f !== member.id))
                    }
                  ></Checkbox>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Grid verticalAlign="top">
        <Grid.Column width="8">
          {/*<MoveUser groupId={groupId} selectedUsers={selectedUsers} refetchGroups={refetchGroups} />*/}
        </Grid.Column>
        <Grid.Column textAlign="right" width="8">
          <Grid.Row>
            <Header>Remove selected users</Header>
            <Button disabled={!selectedUsers.length} negative onClick={() => setConfirmOpen(true)}>
              Remove
            </Button>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ChildrenTable;
