import React from "react";
import { useApiCall } from "../../../../../../hooks/useApiCall";
import { Button, Card, Dimmer, Header, Loader } from "semantic-ui-react";
import { EdlinkSchool } from "../../EdlinkSchool";

export const FindEdlinkSchool = ({ onSelect, onBack, integration }) => {
  const { data, loading } = useApiCall("get", `/edlink/integrations/${integration.id}/schools`, { autoCall: true });

  return (
    <>
      <Button onClick={onBack}>Back</Button>
      <Header as="h4" dividing>
        Select School
      </Header>
      <Card.Group centered>
        {!!loading && (
          <Dimmer active={loading} inverted>
            <Loader active inline="centered" />
          </Dimmer>
        )}
        {!!data &&
          data.map((item) => (
            <Card key={item.id}>
              <Card.Content>
                <EdlinkSchool school={item} />
              </Card.Content>
              <Card.Content extra textAlign="center">
                <Button primary onClick={() => onSelect(item)}>
                  Select
                </Button>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
    </>
  );
};
