import React, { useState } from "react";
import { Form, Header, Segment, Button } from "semantic-ui-react";
const UserCounterReport = ({ user }) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  return (
    <>
      <Segment inverted color="grey" clearing>
        <Header as="h3" dividing>
          Create counter report
        </Header>
        <Form method="post" target="_blank" action={`/report/${user.tenant}`}>
          <Form.Input label="User id" value={user.userId} name="userid"></Form.Input>
          <Form.Input label="User name" value={user.username} name="customerName"></Form.Input>
          <Form.Input name="from" value={from} onChange={(_, { value }) => setFrom(value)} label="From" type="date" />
          <Form.Input
            name="to"
            min={from}
            value={to}
            onChange={(_, { value }) => setTo(value)}
            label="To"
            type="date"
          />
          <Button color="blue" type="submit">
            Submit
          </Button>
        </Form>
      </Segment>
    </>
  );
};

export default UserCounterReport;
