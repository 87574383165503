import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "components/Layout";
import { Card, Divider, Icon } from "semantic-ui-react";
import { UserCreate } from "components/User";

const Home = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <Layout headerSpacing={true} headerText="Dashboard">
      {showModal ? <UserCreate resetModal={toggleModal} /> : null}
      <Card.Group itemsPerRow={2}>
        <Card as={Link} to="/search">
          <Card.Content textAlign="center">
            <Icon name="search" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Search User</Card.Header>
          </Card.Content>
        </Card>
        <Card onClick={() => setShowModal(true)} to="/search">
          <Card.Content textAlign="center">
            <Icon name="user" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Create</Card.Header>
          </Card.Content>
        </Card>
        <Card as={Link} to="/schools">
          <Card.Content textAlign="center">
            <Icon name="university" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Schools</Card.Header>
          </Card.Content>
        </Card>
        <Card as={Link} to="/authorities">
          <Card.Content textAlign="center">
            <Icon name="file" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Authorities</Card.Header>
          </Card.Content>
        </Card>
        <Card as={Link} to="rolepermissions">
          <Card.Content textAlign="center">
            <Icon name="shield" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Propello Roles</Card.Header>
          </Card.Content>
        </Card>

        <Card as={Link} to="news">
          <Card.Content textAlign="center">
            <Icon name="newspaper outline" size="huge" />
            <Divider hidden className="small" />
            <Card.Header>Propello News</Card.Header>
          </Card.Content>
        </Card>
      </Card.Group>
    </Layout>
  );
};

export default Home;
