import { useEffect, useState } from "react";
import { getCookieValue } from "../utils";

export const callAuthServer = async (url, body, sendAsJson) => {
  const access_token = getCookieValue("access_token");

  try {
    const bodyFormatted = body?.body ? { body: new URLSearchParams(body?.body) } : {};

    const response = await fetch(`${process.env.REACT_APP_AUTH_SERVER_URL}${url}`, {
      ...body,
      headers: {
        ...(access_token ? { Authorization: `Bearer ${access_token}` } : {}),
        "Content-Type": "application/x-www-form-urlencoded",
        ...body?.headers,
      },
      ...(sendAsJson ? { body: JSON.stringify(body?.body) } : bodyFormatted),
    });
    const parsedBody = await response.json();

    return { data: parsedBody, errors: parsedBody?.errors || null };
  } catch (err) {
    console.error("err", err);
    return { data: null, errors: err };
  }
};

export const callInternalApiGateway = async (url, body) => {
  const access_token = getCookieValue("access_token");
  try {
    const response = await fetch(`${process.env.REACT_APP_INTERNAL_API_GATEWAY}${url}`, {
      ...body,
      headers: {
        Authorization: `Bearer ${access_token}`,
        ...body?.headers,
        ...(body?.["method"]
          ? {
              "Content-Type": "application/json",
            }
          : {}),
      },
      ...body?.body,
    });
    const parsedBody = await response.json();

    return {
      data: parsedBody.data,
      response: parsedBody,
      errors: parsedBody?.errors || null,
    };
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err);

    return { data: null, errors: err };
  }
};

export const callUserService = async (url, body) => {
  const access_token = getCookieValue("access_token");
  try {
    const response = await fetch(`${process.env.REACT_APP_INTERNAL_API_GATEWAY}${url}`, {
      ...body,
      headers: {
        Authorization: `Bearer ${access_token}`,
        ...body?.headers,
        ...(body?.["method"]
          ? {
              "Content-Type": "application/json",
            }
          : {}),
      },
      ...body?.body,
    });
    const parsedBody = await response.json();

    return { data: parsedBody.data, response: parsedBody, errors: parsedBody?.errors || null };
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err);

    return { data: null, errors: err };
  }
};

export const useFetchUserService = (url, body) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = getCookieValue("access_token");
  const [fetchData, setFetchData] = useState(true);

  useEffect(() => {
    const fetchDataFunction = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_INTERNAL_API_GATEWAY}${url}`, {
          headers: { Authorization: `Bearer ${access_token}` },
          ...body,
        });
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
        setFetchData(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setFetchData(false);
      }
    };
    if (fetchData) {
      fetchDataFunction();
    }
  }, [body, access_token, url, fetchData]);
  return { data, error, loading, setFetchData };
};

export const useFetchInternalApiGateway = (url, body) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = getCookieValue("access_token");
  const [fetchData, setFetchData] = useState(true);

  useEffect(() => {
    const fetchDataFunction = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_INTERNAL_API_GATEWAY}${url}`, {
          headers: { Authorization: `Bearer ${access_token}` },
          ...body,
        });
        const jsonData = await response.json();
        if (jsonData.data) {
          setData(jsonData.data);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    if (fetchData) {
      fetchDataFunction();
      setFetchData(false);
    }
  }, [body, access_token, url, fetchData]);
  return { data, error, loading, setFetchData };
};
